@charset "UTF-8";

/***
 *    ███    ██  ██████  ██████  ███████ ███████ ██    ██ ███████ ████████ ███████ ███    ███ ███████ ██    ██ ██    ██ 
 *    ████   ██ ██    ██ ██   ██ ██      ██       ██  ██  ██         ██    ██      ████  ████ ██      ██    ██  ██  ██  
 *    ██ ██  ██ ██    ██ ██   ██ █████   ███████   ████   ███████    ██    █████   ██ ████ ██ ███████ ██    ██   ████   
 *    ██  ██ ██ ██    ██ ██   ██ ██           ██    ██         ██    ██    ██      ██  ██  ██      ██ ██    ██    ██    
 *    ██   ████  ██████  ██████  ███████ ███████    ██    ███████    ██    ███████ ██      ██ ███████  ██████     ██    
 *                                                                                                                      
 *    SEED | Components | Es un producto de NodeSystemsUy | Todos los derechos propiedad de Nickolás Machado - Uruguay | Está prohibida su cópia, clonación, comercialización, distribución o cualquier simil.                                                                                                
 */

 
/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ▄▄▀██ ▄▄▄ ██ ▄▀▄ ██ ▄▄ ██ ▄▄▄ ██ ▀██ ██ ▄▄▄██ ▀██ █▄▄ ▄▄██ ▄▄▄ ██
██ █████ ███ ██ █ █ ██ ▀▀ ██ ███ ██ █ █ ██ ▄▄▄██ █ █ ███ ████▄▄▄▀▀██
██ ▀▀▄██ ▀▀▀ ██ ███ ██ █████ ▀▀▀ ██ ██▄ ██ ▀▀▀██ ██▄ ███ ████ ▀▀▀ ██
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
 */


/* ---------------------- Counter Component */

.CounterContainer {
    width: 100px;
    height: 100px;
}

.CounterContainer.nano {
  width: 50px;
  height: 50px;
}

.CounterContainer.small {
  width: 100px;
  height: 100px;
}
.CounterContainer.normal {
  width: 150px;
  height: 150px;
}
.CounterContainer.medium {
  width: 200px;
  height: 200px;
}
.CounterContainer.big {
  width: 250px;
  height: 250px;
}
.CounterContainer.giant {
  width: 350px;
  height: 350px;
}





/* ---------------------- NODE VIEW */
.NodeViewControls {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.NodeViewContainer:hover .NodeViewControls {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}



.NodeViewContainer:not(:hover) .NodeViewControls {
  opacity: 1;
  transition-delay: 1s;
}

.NodeViewContainer:not(:hover) .NodeViewControls {
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}
@keyframes NodeViewControls {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.NodeViewContainer:hover .NodeViewControls {
  animation-duration: 5s;
}

/* RANGE input */
/* Estilos para el contenedor principal */

.input-range-test {
  position: relative;
  width: 100%;
  height: 5px; /* Aumenta la altura para dar espacio al popup */
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);

}

/* Estilos para la barra de progreso */
.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 5px;
  transition: width 0.3s;
  z-index: 1; /* Coloca la barra de progreso por debajo del popup */
  opacity: 1; 
  background-color: var(--color-brand-1);/* Aumenta la opacidad para hacerla más visible */
}

/* Estilos para la barra de búfer */
.buffer-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 5px;
  background-color: blue;
  transition: width 0.3s, opacity 0.3s;
  z-index: 1; /* Coloca la barra de búfer por debajo del popup */
  opacity: 1; /* Aumenta la opacidad para hacerla más visible */
}

/* Estilos para el control deslizante (input range) */
input[type="range"] {
  width: 100%;
  height: 5px;
  background-color: red;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
  -webkit-appearance: none;
}

/* Estilos para el mensaje emergente de tiempo actual */
.time-popup {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 3;
  font-size: var(--font-size-small);
  user-select: none;
}

/*
input[type=range]::range-track {
  width: 100%;
  height: 20px;
  border-radius: 10px;
  background-color: #eee;
  border: 2px solid #ccc;
}

input[type=range]::range-thumb {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: white;
  border: 2px solid var(--color-brand-1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
}

input[type=range]::range-progress {
  height: 20px;
  border-radius: 10px 0 0 10px;
  background-color: #2196F3;
  border: 2px solid #1976D2;
}
*/
/* ------------------------------------ timeline */
/*
.timeline input[type=range] {
	height: 15.5px;
	-webkit-appearance: none;
	width: 100%;
  background: none;
  z-index: 2;
  opacity: 60%;
}
.timeline input[type=range]:hover {
  opacity: 100%;
}

.timeline input[type=range]::-webkit-slider-runnable-track {
	width: 100%;
	height: 8px;
	cursor: pointer;
	transition: 0.2s;
	border-radius: 25px;
  background: var(--background-secundario);
  border: 1px solid var(--background-primario);
  
}

.timeline input[type=range]::-webkit-slider-thumb { 
	height: 10px;
	width: 10px; 
	border-radius: 25px; 
  background: var(--color-brand-1) ;
	cursor: pointer;
	-webkit-appearance: none; 
	margin-top: -2px; 
  transition: .15s ease-in-out;
  
} 

.timeline input[type=range]::-webkit-slider-thumb:hover { 
  height: 15px;
	width: 15px; 
  margin-top: -5px; 
  
} 

.timeline input[type=range]:focus::-webkit-slider-runnable-track {
  background: var(--background-secundario);

}

.timeline input[type=range]::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  transition: 0.2s;
  border-radius: 25px;
  background: var(--background-secundario);
  border: 1px solid var(--background-primario);
}

.timeline input[type="range"]::-moz-range-progress {
  background-color: var(--color-brand-1);
  z-index: 244;
  height: 8px;
  border: none;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.timeline input[type=range]::-moz-range-thumb {
  border: 0px solid #212529;
  height: 15px;
  width: 15px;
  border-radius: 25px;
  background: red;
  cursor: pointer;
}

.timeline input[type=range]::-ms-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  transition: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
  z-index: 2;
  
}

.timeline input[type=range]::-ms-fill-lower {
  background: var(--background-secundario);
  border: 1px solid var(--background-primario);
  border-radius: 50px;

}

.timeline input[type=range]::-ms-fill-upper {
  background: var(--background-secundario);
  border: 1px solid var(--background-primario);
  border-radius: 50px;

}

.timeline input[type=range]::-ms-thumb {
  margin-top: 1px;
  border: 0px solid #212529;
  height: 15px;
  width: 15px;
  border-radius: 25px;
  background: var(--background-brand-1);
  cursor: pointer;
}

.timeline input[type=range]:focus::-ms-fill-lower {
  background: var(--background-secundario);

}

.timeline input[type=range]:focus::-ms-fill-upper {
  background: var(--background-secundario);
  filter: brightness(120%);
}

@media (-ms-high-contrast:none),(-ms-high-contrast:active) {
	.timeline input[type=range] {
	height: 51px;
	}
}
*/
/*
.timeline input {
  background: linear-gradient(to right, #008000 0%, #008000 50%, #ffffff 50%, #ffffff 100%);
  border: solid 1px #00b300;

  height: 7px;
  width: 356px;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
}
.timeline input::-webkit-slider-thumb {
  background-color: orange;

  border-radius: 0;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  -webkit-appearance: none;
}
*/

/* Radar */
.RadarLine {
  z-index: 1;
  width: 95%;
  height: 95%;
  background: conic-gradient(from 0.25turn at 50% 50%,var(--color-brand-1), 20deg, rgba(255, 255, 255, 0), 360deg, var(--color-brand-1));
  border-radius: 300px;
  animation: RadarLine 4s linear infinite;
  transform-origin: center;
}
.RadarScale {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 300px;
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.07);
}

.RadarScale:nth-child(2) {
  width: 25%;
  height: 25%;
}

.RadarScale:nth-child(3) {
  width: 50%;
  height: 50%;
}

.RadarScale:nth-child(4) {
  width: 75%;
  height: 75%;
}

.RadarScale::before{
  content: '';
  transform: scale(0.5);
  transform-origin: center;

}
@keyframes RadarLine {
  0% {
    transform: rotate(360deg);
    transform-origin: center;
  }
  100% {
    transform: rotate(0deg);
    transform-origin: center;
  }
}
/* Scrollbar */
div::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}

div::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

div::-webkit-scrollbar-thumb {
  background: var(--background-secundario);
  border: 0;
  border-radius: 100px;
}

div::-webkit-scrollbar-thumb:hover {
  background: var(--background-secundario);
}

div::-webkit-scrollbar-thumb:active {
  background: var(--color-brand-1);
}

div::-webkit-scrollbar-track {
  background: var(--background-primario);
  border: 0;
  border-radius: 70px;
}

div::-webkit-scrollbar-track:hover {
  background: var(--background-primario);
}

div::-webkit-scrollbar-track:active {
  background: #2020201f;
}

div::-webkit-scrollbar-corner {
  background: transparent;
}

/* ------------- */





 a.btn {
    text-decoration: none !important;
    text-underline-offset: none !important;
  }
  
  .btn {
    height: auto;
    font-size: inherit;
    cursor: pointer;
    border-radius: var(--border-size-1);
   
  
  }
  /* btn sizes */
  .btn:hover {
    filter: brightness(var(--hover-effect));
  }
  
  .btn.min-width {
    width: var(--width-size-min);
  
  }
  
  .btn.min-height {
  
    height: var(--height-size-min);
  
  }
  
  .btn.nano {
    font-size: var(--font-size-nano);
  
  }
  
  .btn.small {
    font-size: var(--font-size-small);
  
  }
  
  .btn.normal {
    font-size: var(--font-size-normal);
  
  }
  
  .btn.medium {
    font-size: var(--font-size-medium);
  
  }
  
  .btn.big {
    font-size: var(--font-size-big);
  
  }
  
  .btn.giant {
    font-size: var(--font-size-giant);
  
  }
  
  .btn.max-width {
    width: var(--width-size-max) !important;
  
  }
  
  .btn.max-height {
  
    height: var(--height-size-max) !important;
  
  }
  
  
  /* button colors */
  
  .button.default{
    background-color: var(--color-default);
  }
  
  .button.info{
    background-color: var(--color-info);
    color: white;
  }
  .button.warning{
    background-color: var(--color-warning);
  }
  .button.danger{
    background-color: var(--color-danger);
  }
  .button.success{
    background-color: var(--color-success);
  }
  
  
  /* hover text btn */
  .HoverTextBtnContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .HoverTextBtn {
    transform: scale(1);
    transition: 0.3s ease-in-out;
    background: var(--background-secundario);
    flex-grow: 0;
  }
  
  .HoverTextBtn:hover {
    flex-grow: 5;
    transform: scale(1);
    background-color: var(--color-brand-1);
  }


  .HoverTextBtn:hover .HoverTextBtnText {
    display: block;
    opacity: 1;
    color: white;
  }
  
  .HoverTextBtn:hover .HoverTextBtnFill {
    fill: white;
  }

  .HoverTextBtnFill {
    fill: var(--font-color-default);
  }
  
  .HoverTextBtnText {
    color: var(--font-color-default);
    display: none;
    opacity: 0;
  }


  /* --------------------------------------------- */
  .accordion {
    background-color: var(--color-default);
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
  }
  
  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  
  /* 
  

/* UserComponent */
.UserComponentContainer {
  overflow-x: hidden;
  overflow-y: auto;
  justify-content: space-evenly;
  column-count: 4; 
  align-items: center;

}

.UserComponentContainer>.UserComponent {
   /* Este nos ordena las filas */
  break-inside: avoid;   /* Evita que las columnas se rompan */
  margin-bottom: 20px;

}

.UserComponent {
  width: calc(25% - 10px);
  width: 500px;
  height: auto;


}
.UserComponent.nano {
  width: 200px;
  height: auto;
}
.UserComponent.small {
  width: 300px;
  height: auto;
}

.UserComponent.normal {
  width: 400px;
  height: auto;
}

.UserComponent.medium {
  width: 500px;
  height: auto;
}

.UserComponent.big {
  width: 600px;
  height: auto;
}

.UserComponentImage {
  object-fit: cover;
}

/* post */
.PostContainer {
  overflow-x: hidden;
  overflow-y: auto;
  justify-content: space-evenly;
  column-count: 4; 
  align-items: center;

}

.PostContainer>.Post {
   /* Este nos ordena las filas */
  break-inside: avoid;   /* Evita que las columnas se rompan */
  margin-bottom: 20px;

}

.Post {
  width: calc(25% - 10px);
  width: 500px;
  height: auto;


}
.Post.nano {
  width: 200px;
  height: auto;
}
.Post.small {
  width: 300px;
  height: auto;
}

.Post.normal {
  width: 400px;
  height: auto;
}

.Post.medium {
  width: 500px;
  height: auto;
}

.Post.big {
  width: 600px;
  height: auto;
}

.PostImage {
  object-fit: cover;
}
  /* green  ----------------------                 INP                 ---------------------- */

  .inp-label {
    height: 50px;
    width: 100% !important;
    position: relative;
    margin: auto;
    float: left;
    box-sizing: border-box;
    background: auto;
    padding: 10px;
    overflow: hidden;

  }
  
  .inp-input {
    width: 100%;
    height: 100%;
    position: relative;
    margin: auto;
    float: left;
    background: none;
    color: inherit;
    padding-top: 3px;
    color: var(--font-color-default);
   
  }
  
  .inp-input::placeholder {
    color: transparent;

  }
  
  .inp-placeholder{
    position: absolute;
    width: 100%;
    height: fit-content;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 5px;
    color: var(--font-color-default);
    padding-left: 10px;
  }
  
  .inp-placeholder.active {
  top: 0;
  left: 0;
  padding-left: 10px;
  bottom: auto;
  right: auto;
  font-size: calc(100% - 7px);
  
  }
  .inp-icon-pass{
    fill: var(--font-color-default)
  }
  /* input Inp password eye */
  .inp-pass-toggle {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    
  }
  
  .inp-pass-toggle.visible {
    opacity: 0.7;
  }
  
  .inp-pass-toggle img {
    width: 20px;
    height: 20px;
    
  }

  /*input inp SIZES */
  .inp-label.nano {
    height: calc(var(--height-size-nano) + 15px);
    font-size: var(--font-size-nano);
  }
  .inp-label.nano .inp-placeholder.active {
    font-size: 6px;
  }
  .inp-label.small {
    height: calc(var(--height-size-small) + 15px);
    font-size: var(--font-size-small);
  }
  .inp-label.small .inp-placeholder.active {
    font-size: 8px;
  }
  .inp-label.normal {
    height: calc(var(--height-size-normal) + 15px);
    font-size: var(--font-size-normal);
  }
  .inp-label.normal .inp-placeholder.active {
    font-size: 10px;
  }
  .inp-label.medium{
    height: calc(var(--height-size-medium) + 15px);
    font-size: var(--font-size-medium);
  }
  .inp-label.medium .inp-placeholder.active {
    font-size: 10px;
  }
  
  .inp-label.big{
    height: calc(var(--height-size-big) + 20px);
    font-size: var(--font-size-big);
  }
  .inp-label.big .inp-placeholder.active {
    font-size: 12px;
  }
  .inp-label.giant{
    height: calc(var(--height-size-giant) + 20px);
    font-size: var(--font-size-giant);
  }
  .inp-label.giant .inp-placeholder.active {
    font-size: 12px;
  }
  
  
  /* Input Inp Styles */
  .inp-label.default{
    background: var(--background-default);
    color: var(--font-color-default);
  }
  
  .inp-label.default input{
    color: var(--font-color-default);
  }
  
  .inp-label.info{
    background-color: var(--color-info);
    color: white;
  }
  .inp-label.info .inp-placeholder{
    background-color: var(--color-info);
    color: white;
  }
  .inp-label.warning{
    background-color: var(--color-warning);
    color: white;
  }
  .inp-label.danger{
    background-color: var(--color-danger);
    color: white;
  }
  .inp-label.success{
    background-color: var(--color-success);
    color: white;
  }

  /* input square */

/* Estilos para ocultar el checkbox */
.checkbox {
  font-family: arial;
  display: inline-flex;
  position: relative;
  float: left;
  margin: auto;
  cursor: pointer;
  font-size: 16px;
  line-height: 1.6em;
  background-color: var(--background-primario);
  border-radius: var(--border-size-3);

}
.checkbox:hover{
  filter: brightness(110%);
}
  .checkbox input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      display: none;
    
  }

  /* SIZES */
.checkbox-text{
  user-select: none;
  color: inherit;
}

.checkbox_indicator {
  position: relative;
  float: left;
  margin: auto;
  height: 15px;
  width: 15px;
  border: 0px solid #000000;
  border-radius: var(--border-size-3);
}

/* SIZES ADJUSTS */
.checkbox_indicator.nano {
  height: 10px !important;
  width: 10px !important;
}

.checkbox_indicator.small {
  height: 13px !important;
  width: 13px !important;
}

.checkbox_indicator.normal {
  height: 20px !important;
  width: 20px !important;

}

.checkbox_indicator.medium {
  height: 18px !important;
  width: 18px !important;
}

.checkbox_indicator.big {
  height: 22px !important;
  width: 22px !important;

}

.checkbox_indicator.giant {
  height: 25px !important;
  width: 25px !important;
}
/*      ----------------------       */
.checkbox:hover input ~ .checkbox_indicator,
.checkbox input:focus ~ .checkbox_indicator {

  border-color: var(--background-secundario);
  
}

.checkbox input:checked ~ .checkbox_indicator {
  background: var(--color-brand-1);
}
.checkbox:hover input:not([disabled]):checked ~ .checkbox_indicator,
.checkbox input:checked:focus ~ .checkbox_indicator {

  filter: brightness(110%);

}
.checkbox input:disabled ~ .checkbox_indicator {
  background: var(--background-default);
  opacity: 0.6;
  pointer-events: none;
  
}
.checkbox_indicator:after {
  box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
  
}
.checkbox input:checked ~ .checkbox_indicator:after {
  display: block;

}
.checkbox-checkbox .checkbox_indicator:after {
  width: 20px;
  height: 20px;
  margin: auto;
  position: absolute;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  
}
/* FOR NORMAL DEFAULT CHECK */
.checkbox-checkbox .checkbox_indicator.default:after{
  border-color: var(--background-default) !important;
}
.checkbox-checkbox .checkbox_indicator.white:after{
  border-color: #c2c2c2 !important;
}
.checkbox-checkbox .checkbox_indicator.black:after{
  border-color: #303030 !important;
}
/* FOR GHOST CHECKS */
.checkbox-checkbox .checkbox_indicator.default.ghost:after{
  border-color: #7e7e7e !important;
}
.checkbox-checkbox .checkbox_indicator.alert.ghost:after{
  border-color: var(--current-color-red) !important;
}
.checkbox-checkbox .checkbox_indicator.caution.ghost:after{
  border-color: var(--current-color-yellow) !important;
}
.checkbox-checkbox .checkbox_indicator.info.ghost:after{
  border-color: var(--current-color-skyblue) !important;
}
.checkbox-checkbox .checkbox_indicator.success.ghost:after{
  border-color: var(--current-color-green) !important;
}
.checkbox-checkbox .checkbox_indicator.black.ghost:after{
  border-color: var(--current-color-black) !important;
}
.checkbox-checkbox .checkbox_indicator.white.ghost:after{
  border-color: var(--current-color-white) !important;
}
/*  ------ */
.checkbox-checkbox .checkbox_indicator.alert:after{
  border-color: white !important;

}

.checkbox-checkbox .checkbox_indicator.nano:after {
  left: 3px !important;
  top: 0px !important;
  width: 2px;
  height: 5px;
}
.checkbox-checkbox .checkbox_indicator.small:after {
  left: 4px !important;
  top: 1px !important;
  width: 3px;
  height: 7px;
}
.checkbox-checkbox .checkbox_indicator.normal:after {
  left: 7px !important;
  top: 3px !important;
  width: 4px;
  height: 8px;
}

.checkbox-checkbox .checkbox_indicator.medium:after {
  left: 6px !important;
  top: 2px !important;
  width: 3px;
  height: 8px;
}

.checkbox-checkbox .checkbox_indicator.big:after {
  left: 7px !important;
  top: 2px !important;
  width: 5px;
  height: 10px;
  border-width: 0 3px 3px 0;
}
.checkbox-checkbox .checkbox_indicator.giant:after {
  left: 8px !important;
  top: 3px !important;
  width: 6px;
  height: 12px;
  border-width: 0 3px 3px 0;
}

.checkbox-checkbox input:disabled ~ .checkbox_indicator:after {
  border-color: var(--background-primario);
  
}
.checkbox-checkbox .checkbox_indicator::before {
  content: '';
  display: block;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 4.536rem;
  height: 4.401rem;
  margin-left: -2em;
  margin-top: -2em;
  background: var(--color-brand-1);
  border-radius: 3rem;
  opacity: 0.6;
  z-index: 99999;
  transform: scale(0);

}