@charset "UTF-8";

/***
 *    ███    ██  ██████  ██████  ███████ ███████ ██    ██ ███████ ████████ ███████ ███    ███ ███████ ██    ██ ██    ██ 
 *    ████   ██ ██    ██ ██   ██ ██      ██       ██  ██  ██         ██    ██      ████  ████ ██      ██    ██  ██  ██  
 *    ██ ██  ██ ██    ██ ██   ██ █████   ███████   ████   ███████    ██    █████   ██ ████ ██ ███████ ██    ██   ████   
 *    ██  ██ ██ ██    ██ ██   ██ ██           ██    ██         ██    ██    ██      ██  ██  ██      ██ ██    ██    ██    
 *    ██   ████  ██████  ██████  ███████ ███████    ██    ███████    ██    ███████ ██      ██ ███████  ██████     ██    
 *                                                                                                                      
 *    SEED | Tablet | Es un producto de NodeSystemsUy | Todos los derechos propiedad de Nickolás Machado - Uruguay | Está prohibida su cópia, clonación, comercialización, distribución o cualquier simil.tablet-mobile-                                                                                                
 */
 @media only screen and (min-width: 768px) and (max-width: 1023px) {


 /*  POR CATEGORIZAR */
 .tablet-noselect{
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
 }



/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ▄▄ ██ █████ ▄▄▄ ██ ▄▄▀█ ▄▄▀██ █████ ▄▄▄ ██
██ █▀▀██ █████ ███ ██ ▄▄▀█ ▀▀ ██ █████▄▄▄▀▀██
██ ▀▀▄██ ▀▀ ██ ▀▀▀ ██ ▀▀ █ ██ ██ ▀▀ ██ ▀▀▀ ██
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀

*/
/* Background */
.tablet-ns-bg-1 {
  background: var(--background-primario);
}

.tablet-ns-bg-2 {
  background: var(--background-secundario);
}

.tablet-ns-bg-3 {
  background: var(--background-terciario);
}

/*  Border  Color */
.tablet-ns-br-1 {
  border-radius: var(--border-size-1);
}

.tablet-ns-br-2 {
  border-radius: var(--border-size-2);
}

.tablet-ns-br-3 {
  border-radius: var(--border-size-3);
}

/* Border Radius */
.tablet-ns-b-c {
  border-color: brightness(5%);
}




/*

▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ███ █▄ ▄██ ▄▄▀█▄▄ ▄▄██ ██ ██
██ █ █ ██ ███ ██ ███ ████ ▄▄ ██
██▄▀▄▀▄█▀ ▀██ ▀▀ ███ ████ ██ ██
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀

*/
/*

█░█░█ █ █▀▄ ▀█▀ █░█   █▀ █▀█ █▀▀ █▀▀ █ ▄▀█ █░░ █▀
▀▄▀▄▀ █ █▄▀ ░█░ █▀█   ▄█ █▀▀ ██▄ █▄▄ █ █▀█ █▄▄ ▄█

*/
.tablet-w-inh .tablet-w-i .tablet-width-inherit {
  width: inherit;
}

.tablet-w-auto .tablet-w-a .tablet-width-auto {
  width: auto;
}



/*

█░█░█ █ █▀▄ █░█ ▀█▀   ▀░▄▀
▀▄▀▄▀ █ █▄▀ █▀█ ░█░   ▄▀░▄
*/
.tablet-w-0 {
  width: 0%;
}

.tablet-w-5 {
  width: 5%;
}

.tablet-w-10 {
  width: 10%;
}

.tablet-w-15 {
  width: 15%;
}

.tablet-w-20 {
  width: 20%;
}

.tablet-w-25 {
  width: 25%;
}

.tablet-w-30 {
  width: 30%;
}

.tablet-w-33 {
  width: 33.333%;
}

.tablet-w-35 {
  width: 35%;
}

.tablet-w-40 {
  width: 40%;
}

.tablet-w-45 {
  width: 45%;
}

.tablet-w-50 {
  width: 50%;
}

.tablet-w-55 {
  width: 55%;
}

.tablet-w-60 {
  width: 60%;
}

.tablet-w-65 {
  width: 65%;
}

.tablet-w-70 {
  width: 70%;
}

.tablet-w-75 {
  width: 75%;
}

.tablet-w-80 {
  width: 80%;
}

.tablet-w-85 {
  width: 85%;
}

.tablet-w-90 {
  width: 90%;
}

.tablet-w-95 {
  width: 95%;
}

.tablet-w-100 {
  width: 100%;
}

/*
 
█░█░█ █ █▀▄ █░█ ▀█▀   █▀█ ▀▄▀
▀▄▀▄▀ █ █▄▀ █▀█ ░█░   █▀▀ █░█
 
 */

.tablet-w-0px {
  width: 0px;
}

.tablet-w-5px {
  width: 5px;
}

.tablet-w-10px {
  width: 10px;
}

.tablet-w-15px {
  width: 15px;
}

.tablet-w-20px {
  width: 20px;
}

.tablet-w-25px {
  width: 25px;
}

.tablet-w-30px {
  width: 30px;
}

.tablet-w-35px {
  width: 35px;
}

.tablet-w-40px {
  width: 40px;
}

.tablet-w-45px {
  width: 45px;
}

.tablet-w-50px {
  width: 50px;
}

.tablet-w-55px {
  width: 55px;
}

.tablet-w-60px {
  width: 60px;
}

.tablet-w-65px {
  width: 65px;
}

.tablet-w-70px {
  width: 70px;
}

.tablet-w-75px {
  width: 75px;
}

.tablet-w-80px {
  width: 80px;
}

.tablet-w-85px {
  width: 85px;
}

.tablet-w-90px {
  width: 90px;
}

.tablet-w-95px {
  width: 95px;
}

.tablet-w-100px {
  width: 100px;
}

.tablet-w-125px {
  width: 125px;
}

.tablet-w-150px {
  width: 150px;
}

.tablet-w-175px {
  width: 175px;
}

.tablet-w-200px {
  width: 200px;
}

.tablet-w-225px {
  width: 225px;
}

.tablet-w-250px {
  width: 250px;
}

.tablet-w-275px {
  width: 275px;
}

.tablet-w-300px {
  width: 300px;
}

.tablet-w-325px {
  width: 325px;
}

.tablet-w-350px {
  width: 350px;
}

.tablet-w-375px {
  width: 375px;
}

.tablet-w-400px {
  width: 400px;
}

.tablet-w-425px {
  width: 425px;
}

.tablet-w-450px {
  width: 450px;
}

.tablet-w-475px {
  width: 475px;
}

.tablet-w-500px {
  width: 500px;
}

.tablet-w-1000px {
  width: 1000px;
}

.tablet-w-1080px {
  width: 1080px;
}

.tablet-w-1350px {
  width: 1350px;
}

.tablet-w-1920px {
  width: 1920px;
}


/*

█░█░█ █ █▀▄ █░█ ▀█▀   █░█ █░█░█
▀▄▀▄▀ █ █▄▀ █▀█ ░█░   ▀▄▀ ▀▄▀▄▀

*/

.tablet-w-0vw {
  width: 0vw;
}

.tablet-w-5vw {
  width: 5vw;
}

.tablet-w-10vw {
  width: 10vw;
}

.tablet-w-15vw {
  width: 15vw;
}

.tablet-w-20vw {
  width: 20vw;
}

.tablet-w-25vw {
  width: 25vw;
}

.tablet-w-30vw {
  width: 30vw;
}

.tablet-w-35vw {
  width: 35vw;
}

.tablet-w-40vw {
  width: 40vw;
}

.tablet-w-45vw {
  width: 45vw;
}

.tablet-w-50vw {
  width: 50vw;
}

.tablet-w-55vw {
  width: 55vw;
}

.tablet-w-60vw {
  width: 60vw;
}

.tablet-w-65vw {
  width: 65vw;
}

.tablet-w-70vw {
  width: 70vw;
}

.tablet-w-75vw {
  width: 75vw;
}

.tablet-w-80vw {
  width: 80vw;
}

.tablet-w-85vw {
  width: 85vw;
}

.tablet-w-90vw {
  width: 90vw;
}

.tablet-w-95vw {
  width: 95vw;
}

.tablet-w-100vw {
  width: 100vw;
}

/*

█░█░█ █ █▀▄ █░█ ▀█▀   █▀█ █▀▀ █▀▄▀█
▀▄▀▄▀ █ █▄▀ █▀█ ░█░   █▀▄ ██▄ █░▀░█

*/
.tablet-w-0rem {
  width: 0rem;
}

.tablet-w-5rem {
  width: 5rem;
}

.tablet-w-10rem {
  width: 10rem;
}

.tablet-w-15rem {
  width: 15rem;
}

.tablet-w-20rem {
  width: 20rem;
}

.tablet-w-25rem {
  width: 25rem;
}

.tablet-w-30rem {
  width: 30rem;
}

.tablet-w-35rem {
  width: 35rem;
}

.tablet-w-40rem {
  width: 40rem;
}

.tablet-w-45rem {
  width: 45rem;
}

.tablet-w-50rem {
  width: 50rem;
}

.tablet-w-55rem {
  width: 55rem;
}

.tablet-w-60rem {
  width: 60rem;
}

.tablet-w-65rem {
  width: 65rem;
}

.tablet-w-70rem {
  width: 70rem;
}

.tablet-w-75rem {
  width: 75rem;
}

.tablet-w-80rem {
  width: 80rem;
}

.tablet-w-85rem {
  width: 85rem;
}

.tablet-w-90rem {
  width: 90rem;
}

.tablet-w-95rem {
  width: 95rem;
}

.tablet-w-100rem {
  width: 100rem;
}

/* 
  
█░█░█ █ █▀▄ █░█ ▀█▀   █▀▀ █▀▄▀█
▀▄▀▄▀ █ █▄▀ █▀█ ░█░   ██▄ █░▀░█
  
  */
.tablet-w-0em {
  width: 0em;
}

.tablet-w-5em {
  width: 5em;
}

.tablet-w-10em {
  width: 10em;
}

.tablet-w-15em {
  width: 15em;
}

.tablet-w-20em {
  width: 20em;
}

.tablet-w-25em {
  width: 25em;
}

.tablet-w-30em {
  width: 30em;
}

.tablet-w-35em {
  width: 35em;
}

.tablet-w-40em {
  width: 40em;
}

.tablet-w-45em {
  width: 45em;
}

.tablet-w-50em {
  width: 50em;
}

.tablet-w-55em {
  width: 55em;
}

.tablet-w-60em {
  width: 60em;
}

.tablet-w-65em {
  width: 65em;
}

.tablet-w-70em {
  width: 70em;
}

.tablet-w-75em {
  width: 75em;
}

.tablet-w-80em {
  width: 80em;
}

.tablet-w-85em {
  width: 85em;
}

.tablet-w-90em {
  width: 90em;
}

.tablet-w-95em {
  width: 95em;
}

.tablet-w-100em {
  width: 100em;
}

/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ▄▀▄ █▄ ▄██ ▀██ ████ ███ █▄ ▄██ ▄▄▀█▄▄ ▄▄██ ██ ██
██ █ █ ██ ███ █ █ ████ █ █ ██ ███ ██ ███ ████ ▄▄ ██
██ ███ █▀ ▀██ ██▄ ████▄▀▄▀▄█▀ ▀██ ▀▀ ███ ████ ██ ██
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀

*/
.tablet-min-w-0 {
  min-width: 0%;
}

.tablet-min-w-5 {
  min-width: 5%;
}

.tablet-min-w-10 {
  min-width: 10%;
}

.tablet-min-w-15 {
  min-width: 15%;
}

.tablet-min-w-20 {
  min-width: 20%;
}

.tablet-min-w-25 {
  min-width: 25%;
}

.tablet-min-w-30 {
  min-width: 30%;
}

.tablet-min-w-35 {
  min-width: 35%;
}

.tablet-min-w-40 {
  min-width: 40%;
}

.tablet-min-w-45 {
  min-width: 45%;
}

.tablet-min-w-50 {
  min-width: 50%;
}

.tablet-min-w-55 {
  min-width: 55%;
}

.tablet-min-w-60 {
  min-width: 60%;
}

.tablet-min-w-65 {
  min-width: 65%;
}

.tablet-min-w-70 {
  min-width: 70%;
}

.tablet-min-w-75 {
  min-width: 75%;
}

.tablet-min-w-80 {
  min-width: 80%;
}

.tablet-min-w-85 {
  min-width: 85%;
}

.tablet-min-w-90 {
  min-width: 90%;
}

.tablet-min-w-95 {
  min-width: 95%;
}

.tablet-min-w-100 {
  min-width: 100%;
}

.tablet-min-w-0px {
  min-width: 0px;
}

.tablet-min-w-5px {
  min-width: 5px;
}

.tablet-min-w-10px {
  min-width: 10px;
}

.tablet-min-w-15px {
  min-width: 15px;
}

.tablet-min-w-20px {
  min-width: 20px;
}

.tablet-min-w-25px {
  min-width: 25px;
}

.tablet-min-w-30px {
  min-width: 30px;
}

.tablet-min-w-35px {
  min-width: 35px;
}

.tablet-min-w-40px {
  min-width: 40px;
}

.tablet-min-w-45px {
  min-width: 45px;
}

.tablet-min-w-50px {
  min-width: 50px;
}

.tablet-min-w-55px {
  min-width: 55px;
}

.tablet-min-w-60px {
  min-width: 60px;
}

.tablet-min-w-65px {
  min-width: 65px;
}

.tablet-min-w-70px {
  min-width: 70px;
}

.tablet-min-w-75px {
  min-width: 75px;
}

.tablet-min-w-80px {
  min-width: 80px;
}

.tablet-min-w-85px {
  min-width: 85px;
}

.tablet-min-w-90px {
  min-width: 90px;
}

.tablet-min-w-95px {
  min-width: 95px;
}

.tablet-min-w-100px {
  min-width: 100px;
}

.tablet-min-w-0vw {
  min-width: 0vw;
}

.tablet-min-w-5vw {
  min-width: 5vw;
}

.tablet-min-w-10vw {
  min-width: 10vw;
}

.tablet-min-w-15vw {
  min-width: 15vw;
}

.tablet-min-w-20vw {
  min-width: 20vw;
}

.tablet-min-w-25vw {
  min-width: 25vw;
}

.tablet-min-w-30vw {
  min-width: 30vw;
}

.tablet-min-w-35vw {
  min-width: 35vw;
}

.tablet-min-w-40vw {
  min-width: 40vw;
}

.tablet-min-w-45vw {
  min-width: 45vw;
}

.tablet-min-w-50vw {
  min-width: 50vw;
}

.tablet-min-w-55vw {
  min-width: 55vw;
}

.tablet-min-w-60vw {
  min-width: 60vw;
}

.tablet-min-w-65vw {
  min-width: 65vw;
}

.tablet-min-w-70vw {
  min-width: 70vw;
}

.tablet-min-w-75vw {
  min-width: 75vw;
}

.tablet-min-w-80vw {
  min-width: 80vw;
}

.tablet-min-w-85vw {
  min-width: 85vw;
}

.tablet-min-w-90vw {
  min-width: 90vw;
}

.tablet-min-w-95vw {
  min-width: 95vw;
}

.tablet-min-w-100vw {
  min-width: 100vw;
}

.tablet-min-w-0rem {
  min-width: 0rem;
}

.tablet-min-w-5rem {
  min-width: 5rem;
}

.tablet-min-w-10rem {
  min-width: 10rem;
}

.tablet-min-w-15rem {
  min-width: 15rem;
}

.tablet-min-w-20rem {
  min-width: 20rem;
}

.tablet-min-w-25rem {
  min-width: 25rem;
}

.tablet-min-w-30rem {
  min-width: 30rem;
}

.tablet-min-w-35rem {
  min-width: 35rem;
}

.tablet-min-w-40rem {
  min-width: 40rem;
}

.tablet-min-w-45rem {
  min-width: 45rem;
}

.tablet-min-w-50rem {
  min-width: 50rem;
}

.tablet-min-w-55rem {
  min-width: 55rem;
}

.tablet-min-w-60rem {
  min-width: 60rem;
}

.tablet-min-w-65rem {
  min-width: 65rem;
}

.tablet-min-w-70rem {
  min-width: 70rem;
}

.tablet-min-w-75rem {
  min-width: 75rem;
}

.tablet-min-w-80rem {
  min-width: 80rem;
}

.tablet-min-w-85rem {
  min-width: 85rem;
}

.tablet-min-w-90rem {
  min-width: 90rem;
}

.tablet-min-w-95rem {
  min-width: 95rem;
}

.tablet-min-w-100rem {
  min-width: 100rem;
}

.tablet-min-w-0em {
  min-width: 0em;
}

.tablet-min-w-5em {
  min-width: 5em;
}

.tablet-min-w-10em {
  min-width: 10em;
}

.tablet-min-w-15em {
  min-width: 15em;
}

.tablet-min-w-20em {
  min-width: 20em;
}

.tablet-min-w-25em {
  min-width: 25em;
}

.tablet-min-w-30em {
  min-width: 30em;
}

.tablet-min-w-35em {
  min-width: 35em;
}

.tablet-min-w-40em {
  min-width: 40em;
}

.tablet-min-w-45em {
  min-width: 45em;
}

.tablet-min-w-50em {
  min-width: 50em;
}

.tablet-min-w-55em {
  min-width: 55em;
}

.tablet-min-w-60em {
  min-width: 60em;
}

.tablet-min-w-65em {
  min-width: 65em;
}

.tablet-min-w-70em {
  min-width: 70em;
}

.tablet-min-w-75em {
  min-width: 75em;
}

.tablet-min-w-80em {
  min-width: 80em;
}

.tablet-min-w-85em {
  min-width: 85em;
}

.tablet-min-w-90em {
  min-width: 90em;
}

.tablet-min-w-95em {
  min-width: 95em;
}

.tablet-min-w-100em {
  min-width: 100em;
}

/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ▄▀▄ █ ▄▄▀█▄▀█▀▄████ ███ █▄ ▄██ ▄▄▀█▄▄ ▄▄██ ██ ██
██ █ █ █ ▀▀ ███ ██████ █ █ ██ ███ ██ ███ ████ ▄▄ ██
██ ███ █ ██ █▀▄█▄▀████▄▀▄▀▄█▀ ▀██ ▀▀ ███ ████ ██ ██
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
*/
.tablet-max-w-0 {
  max-width: 0%;
}

.tablet-max-w-5 {
  max-width: 5%;
}

.tablet-max-w-10 {
  max-width: 10%;
}

.tablet-max-w-15 {
  max-width: 15%;
}

.tablet-max-w-20 {
  max-width: 20%;
}

.tablet-max-w-25 {
  max-width: 25%;
}

.tablet-max-w-30 {
  max-width: 30%;
}

.tablet-max-w-35 {
  max-width: 35%;
}

.tablet-max-w-40 {
  max-width: 40%;
}

.tablet-max-w-45 {
  max-width: 45%;
}

.tablet-max-w-50 {
  max-width: 50%;
}

.tablet-max-w-55 {
  max-width: 55%;
}

.tablet-max-w-60 {
  max-width: 60%;
}

.tablet-max-w-65 {
  max-width: 65%;
}

.tablet-max-w-70 {
  max-width: 70%;
}

.tablet-max-w-75 {
  max-width: 75%;
}

.tablet-max-w-80 {
  max-width: 80%;
}

.tablet-max-w-85 {
  max-width: 85%;
}

.tablet-max-w-90 {
  max-width: 90%;
}

.tablet-max-w-95 {
  max-width: 95%;
}

.tablet-max-w-100 {
  max-width: 100%;
}

.tablet-max-w-0px {
  max-width: 0px;
}

.tablet-max-w-5px {
  max-width: 5px;
}

.tablet-max-w-10px {
  max-width: 10px;
}

.tablet-max-w-15px {
  max-width: 15px;
}

.tablet-max-w-20px {
  max-width: 20px;
}

.tablet-max-w-25px {
  max-width: 25px;
}

.tablet-max-w-30px {
  max-width: 30px;
}

.tablet-max-w-35px {
  max-width: 35px;
}

.tablet-max-w-40px {
  max-width: 40px;
}

.tablet-max-w-45px {
  max-width: 45px;
}

.tablet-max-w-50px {
  max-width: 50px;
}

.tablet-max-w-55px {
  max-width: 55px;
}

.tablet-max-w-60px {
  max-width: 60px;
}

.tablet-max-w-65px {
  max-width: 65px;
}

.tablet-max-w-70px {
  max-width: 70px;
}

.tablet-max-w-75px {
  max-width: 75px;
}

.tablet-max-w-80px {
  max-width: 80px;
}

.tablet-max-w-85px {
  max-width: 85px;
}

.tablet-max-w-90px {
  max-width: 90px;
}

.tablet-max-w-95px {
  max-width: 95px;
}

.tablet-max-w-100px {
  max-width: 100px;
}

.tablet-max-w-0vw {
  max-width: 0vw;
}

.tablet-max-w-5vw {
  max-width: 5vw;
}

.tablet-max-w-10vw {
  max-width: 10vw;
}

.tablet-max-w-15vw {
  max-width: 15vw;
}

.tablet-max-w-20vw {
  max-width: 20vw;
}

.tablet-max-w-25vw {
  max-width: 25vw;
}

.tablet-max-w-30vw {
  max-width: 30vw;
}

.tablet-max-w-35vw {
  max-width: 35vw;
}

.tablet-max-w-40vw {
  max-width: 40vw;
}

.tablet-max-w-45vw {
  max-width: 45vw;
}

.tablet-max-w-50vw {
  max-width: 50vw;
}

.tablet-max-w-55vw {
  max-width: 55vw;
}

.tablet-max-w-60vw {
  max-width: 60vw;
}

.tablet-max-w-65vw {
  max-width: 65vw;
}

.tablet-max-w-70vw {
  max-width: 70vw;
}

.tablet-max-w-75vw {
  max-width: 75vw;
}

.tablet-max-w-80vw {
  max-width: 80vw;
}

.tablet-max-w-85vw {
  max-width: 85vw;
}

.tablet-max-w-90vw {
  max-width: 90vw;
}

.tablet-max-w-95vw {
  max-width: 95vw;
}

.tablet-max-w-100vw {
  max-width: 100vw;
}

.tablet-max-w-0rem {
  max-width: 0rem;
}

.tablet-max-w-5rem {
  max-width: 5rem;
}

.tablet-max-w-10rem {
  max-width: 10rem;
}

.tablet-max-w-15rem {
  max-width: 15rem;
}

.tablet-max-w-20rem {
  max-width: 20rem;
}

.tablet-max-w-25rem {
  max-width: 25rem;
}

.tablet-max-w-30rem {
  max-width: 30rem;
}

.tablet-max-w-35rem {
  max-width: 35rem;
}

.tablet-max-w-40rem {
  max-width: 40rem;
}

.tablet-max-w-45rem {
  max-width: 45rem;
}

.tablet-max-w-50rem {
  max-width: 50rem;
}

.tablet-max-w-55rem {
  max-width: 55rem;
}

.tablet-max-w-60rem {
  max-width: 60rem;
}

.tablet-max-w-65rem {
  max-width: 65rem;
}

.tablet-max-w-70rem {
  max-width: 70rem;
}

.tablet-max-w-75rem {
  max-width: 75rem;
}

.tablet-max-w-80rem {
  max-width: 80rem;
}

.tablet-max-w-85rem {
  max-width: 85rem;
}

.tablet-max-w-90rem {
  max-width: 90rem;
}

.tablet-max-w-95rem {
  max-width: 95rem;
}

.tablet-max-w-100rem {
  max-width: 100rem;
}

.tablet-max-w-0em {
  max-width: 0em;
}

.tablet-max-w-5em {
  max-width: 5em;
}

.tablet-max-w-10em {
  max-width: 10em;
}

.tablet-max-w-15em {
  max-width: 15em;
}

.tablet-max-w-20em {
  max-width: 20em;
}

.tablet-max-w-25em {
  max-width: 25em;
}

.tablet-max-w-30em {
  max-width: 30em;
}

.tablet-max-w-35em {
  max-width: 35em;
}

.tablet-max-w-40em {
  max-width: 40em;
}

.tablet-max-w-45em {
  max-width: 45em;
}

.tablet-max-w-50em {
  max-width: 50em;
}

.tablet-max-w-55em {
  max-width: 55em;
}

.tablet-max-w-60em {
  max-width: 60em;
}

.tablet-max-w-65em {
  max-width: 65em;
}

.tablet-max-w-70em {
  max-width: 70em;
}

.tablet-max-w-75em {
  max-width: 75em;
}

.tablet-max-w-80em {
  max-width: 80em;
}

.tablet-max-w-85em {
  max-width: 85em;
}

.tablet-max-w-90em {
  max-width: 90em;
}

.tablet-max-w-95em {
  max-width: 95em;
}

.tablet-max-w-100em {
  max-width: 100em;
}

/*
  
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ██ ██ ▄▄▄█▄ ▄██ ▄▄ ██ ██ █▄▄ ▄▄██
██ ▄▄ ██ ▄▄▄██ ███ █▀▀██ ▄▄ ███ ████
██ ██ ██ ▀▀▀█▀ ▀██ ▀▀▄██ ██ ███ ████
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀

  
  */
/*
█░█ █▀▀ █ █▀▀ █░█ ▀█▀   █▀ █▀█ █▀▀ █▀▀ █ ▄▀█ █░░ █▀
█▀█ ██▄ █ █▄█ █▀█ ░█░   ▄█ █▀▀ ██▄ █▄▄ █ █▀█ █▄▄ ▄█
*/
.tablet-h-inh .tablet-h-i .tablet-height-inherit {
  height: inherit;
}

.tablet-h-auto .tablet-h-a .tablet-height-auto {
  height: auto;
}

/*
  
█░█ █▀▀ █ █▀▀ █░█ ▀█▀   ▀░▄▀
█▀█ ██▄ █ █▄█ █▀█ ░█░   ▄▀░▄
  
  */
.tablet-h-0 {
  height: 0%;
}

.tablet-h-5 {
  height: 5%;
}

.tablet-h-10 {
  height: 10%;
}

.tablet-h-15 {
  height: 15%;
}

.tablet-h-20 {
  height: 20%;
}

.tablet-h-25 {
  height: 25%;
}

.tablet-h-30 {
  height: 30%;
}

.tablet-h-33 {
  height: 33.333%;
}

.tablet-h-35 {
  height: 35%;
}

.tablet-h-40 {
  height: 40%;
}

.tablet-h-45 {
  height: 45%;
}

.tablet-h-50 {
  height: 50%;
}

.tablet-h-55 {
  height: 55%;
}

.tablet-h-60 {
  height: 60%;
}

.tablet-h-65 {
  height: 65%;
}

.tablet-h-70 {
  height: 70%;
}

.tablet-h-75 {
  height: 75%;
}

.tablet-h-80 {
  height: 80%;
}

.tablet-h-85 {
  height: 85%;
}

.tablet-h-90 {
  height: 90%;
}

.tablet-h-95 {
  height: 95%;
}

.tablet-h-100 {
  height: 100%;
}

/*

█░█ █▀▀ █ █▀▀ █░█ ▀█▀   █▀█ ▀▄▀
█▀█ ██▄ █ █▄█ █▀█ ░█░   █▀▀ █░█

*/

.tablet-h-0px {
  height: 0px;
}

.tablet-h-5px {
  height: 5px;
}

.tablet-h-10px {
  height: 10px;
}

.tablet-h-15px {
  height: 15px;
}

.tablet-h-20px {
  height: 20px;
}

.tablet-h-25px {
  height: 25px;
}

.tablet-h-30px {
  height: 30px;
}

.tablet-h-35px {
  height: 35px;
}

.tablet-h-40px {
  height: 40px;
}

.tablet-h-45px {
  height: 45px;
}

.tablet-h-50px {
  height: 50px;
}

.tablet-h-55px {
  height: 55px;
}

.tablet-h-60px {
  height: 60px;
}

.tablet-h-65px {
  height: 65px;
}

.tablet-h-70px {
  height: 70px;
}

.tablet-h-75px {
  height: 75px;
}

.tablet-h-80px {
  height: 80px;
}

.tablet-h-85px {
  height: 85px;
}

.tablet-h-90px {
  height: 90px;
}

.tablet-h-95px {
  height: 95px;
}

.tablet-h-100px {
  height: 100px;
}

.tablet-h-125px {
  height: 125px;
}

.tablet-h-150px {
  height: 150px;
}

.tablet-h-175px {
  height: 175px;
}

.tablet-h-200px {
  height: 200px;
}

.tablet-h-225px {
  height: 225px;
}

.tablet-h-250px {
  height: 250px;
}

.tablet-h-275px {
  height: 275px;
}

.tablet-h-300px {
  height: 300px;
}

.tablet-h-325px {
  height: 325px;
}

.tablet-h-350px {
  height: 350px;
}

.tablet-h-375px {
  height: 375px;
}

.tablet-h-400px {
  height: 400px;
}

.tablet-h-425px {
  height: 425px;
}

.tablet-h-450px {
  height: 450px;
}

.tablet-h-475px {
  height: 475px;
}

.tablet-h-500px {
  height: 500px;
}

.tablet-h-1000px {
  height: 1000px;
}

.tablet-h-1080px {
  height: 1080px;
}

.tablet-h-1350px {
  height: 1350px;
}

.tablet-h-1920px {
  height: 1920px;
}

/*
  
█░█ █▀▀ █ █▀▀ █░█ ▀█▀   █░█ █░█
█▀█ ██▄ █ █▄█ █▀█ ░█░   ▀▄▀ █▀█
  
  */

.tablet-h-0vh {
  height: 0vh;
}

.tablet-h-5vh {
  height: 5vh;
}

.tablet-h-10vh {
  height: 10vh;
}

.tablet-h-15vh {
  height: 15vh;
}

.tablet-h-20vh {
  height: 20vh;
}

.tablet-h-25vh {
  height: 25vh;
}

.tablet-h-30vh {
  height: 30vh;
}

.tablet-h-35vh {
  height: 35vh;
}

.tablet-h-40vh {
  height: 40vh;
}

.tablet-h-45vh {
  height: 45vh;
}

.tablet-h-50vh {
  height: 50vh;
}

.tablet-h-55vh {
  height: 55vh;
}

.tablet-h-60vh {
  height: 60vh;
}

.tablet-h-65vh {
  height: 65vh;
}

.tablet-h-70vh {
  height: 70vh;
}

.tablet-h-75vh {
  height: 75vh;
}

.tablet-h-80vh {
  height: 80vh;
}

.tablet-h-85vh {
  height: 85vh;
}

.tablet-h-90vh {
  height: 90vh;
}

.tablet-h-95vh {
  height: 95vh;
}

.tablet-h-100vh {
  height: 100vh;
}

/*
  
█░█ █▀▀ █ █▀▀ █░█ ▀█▀   █▀█ █▀▀ █▀▄▀█
█▀█ ██▄ █ █▄█ █▀█ ░█░   █▀▄ ██▄ █░▀░█

  */

.tablet-h-0rem {
  height: 0rem;
}

.tablet-h-5rem {
  height: 5rem;
}

.tablet-h-10rem {
  height: 10rem;
}

.tablet-h-15rem {
  height: 15rem;
}

.tablet-h-20rem {
  height: 20rem;
}

.tablet-h-25rem {
  height: 25rem;
}

.tablet-h-30rem {
  height: 30rem;
}

.tablet-h-35rem {
  height: 35rem;
}

.tablet-h-40rem {
  height: 40rem;
}

.tablet-h-45rem {
  height: 45rem;
}

.tablet-h-50rem {
  height: 50rem;
}

.tablet-h-55rem {
  height: 55rem;
}

.tablet-h-60rem {
  height: 60rem;
}

.tablet-h-65rem {
  height: 65rem;
}

.tablet-h-70rem {
  height: 70rem;
}

.tablet-h-75rem {
  height: 75rem;
}

.tablet-h-80rem {
  height: 80rem;
}

.tablet-h-85rem {
  height: 85rem;
}

.tablet-h-90rem {
  height: 90rem;
}

.tablet-h-95rem {
  height: 95rem;
}

.tablet-h-100rem {
  height: 100rem;
}

/*
  
  
█░█ █▀▀ █ █▀▀ █░█ ▀█▀   █▀▀ █▀▄▀█
█▀█ ██▄ █ █▄█ █▀█ ░█░   ██▄ █░▀░█

  
  */

.tablet-h-0em {
  height: 0em;
}

.tablet-h-5em {
  height: 5em;
}

.tablet-h-10em {
  height: 10em;
}

.tablet-h-15em {
  height: 15em;
}

.tablet-h-20em {
  height: 20em;
}

.tablet-h-25em {
  height: 25em;
}

.tablet-h-30em {
  height: 30em;
}

.tablet-h-35em {
  height: 35em;
}

.tablet-h-40em {
  height: 40em;
}

.tablet-h-45em {
  height: 45em;
}

.tablet-h-50em {
  height: 50em;
}

.tablet-h-55em {
  height: 55em;
}

.tablet-h-60em {
  height: 60em;
}

.tablet-h-65em {
  height: 65em;
}

.tablet-h-70em {
  height: 70em;
}

.tablet-h-75em {
  height: 75em;
}

.tablet-h-80em {
  height: 80em;
}

.tablet-h-85em {
  height: 85em;
}

.tablet-h-90em {
  height: 90em;
}

.tablet-h-95em {
  height: 95em;
}

.tablet-h-100em {
  height: 100em;
}

/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ▄▀▄ █▄ ▄██ ▀██ ████ ██ ██ ▄▄▄█▄ ▄██ ▄▄ ██ ██ █▄▄ ▄▄██
██ █ █ ██ ███ █ █ ████ ▄▄ ██ ▄▄▄██ ███ █▀▀██ ▄▄ ███ ████
██ ███ █▀ ▀██ ██▄ ████ ██ ██ ▀▀▀█▀ ▀██ ▀▀▄██ ██ ███ ████
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
*/
.tablet-min-h-0 {
  min-height: 0%;
}

.tablet-min-h-5 {
  min-height: 5%;
}

.tablet-min-h-10 {
  min-height: 10%;
}

.tablet-min-h-15 {
  min-height: 15%;
}

.tablet-min-h-20 {
  min-height: 20%;
}

.tablet-min-h-25 {
  min-height: 25%;
}

.tablet-min-h-30 {
  min-height: 30%;
}

.tablet-min-h-35 {
  min-height: 35%;
}

.tablet-min-h-40 {
  min-height: 40%;
}

.tablet-min-h-45 {
  min-height: 45%;
}

.tablet-min-h-50 {
  min-height: 50%;
}

.tablet-min-h-55 {
  min-height: 55%;
}

.tablet-min-h-60 {
  min-height: 60%;
}

.tablet-min-h-65 {
  min-height: 65%;
}

.tablet-min-h-70 {
  min-height: 70%;
}

.tablet-min-h-75 {
  min-height: 75%;
}

.tablet-min-h-80 {
  min-height: 80%;
}

.tablet-min-h-85 {
  min-height: 85%;
}

.tablet-min-h-90 {
  min-height: 90%;
}

.tablet-min-h-95 {
  min-height: 95%;
}

.tablet-min-h-100 {
  min-height: 100%;
}

.tablet-min-h-0px {
  min-height: 0px;
}

.tablet-min-h-5px {
  min-height: 5px;
}

.tablet-min-h-10px {
  min-height: 10px;
}

.tablet-min-h-15px {
  min-height: 15px;
}

.tablet-min-h-20px {
  min-height: 20px;
}

.tablet-min-h-25px {
  min-height: 25px;
}

.tablet-min-h-30px {
  min-height: 30px;
}

.tablet-min-h-35px {
  min-height: 35px;
}

.tablet-min-h-40px {
  min-height: 40px;
}

.tablet-min-h-45px {
  min-height: 45px;
}

.tablet-min-h-50px {
  min-height: 50px;
}

.tablet-min-h-55px {
  min-height: 55px;
}

.tablet-min-h-60px {
  min-height: 60px;
}

.tablet-min-h-65px {
  min-height: 65px;
}

.tablet-min-h-70px {
  min-height: 70px;
}

.tablet-min-h-75px {
  min-height: 75px;
}

.tablet-min-h-80px {
  min-height: 80px;
}

.tablet-min-h-85px {
  min-height: 85px;
}

.tablet-min-h-90px {
  min-height: 90px;
}

.tablet-min-h-95px {
  min-height: 95px;
}

.tablet-min-h-100px {
  min-height: 100px;
}

.tablet-min-h-0vh {
  min-height: 0vh;
}

.tablet-min-h-5vh {
  min-height: 5vh;
}

.tablet-min-h-10vh {
  min-height: 10vh;
}

.tablet-min-h-15vh {
  min-height: 15vh;
}

.tablet-min-h-20vh {
  min-height: 20vh;
}

.tablet-min-h-25vh {
  min-height: 25vh;
}

.tablet-min-h-30vh {
  min-height: 30vh;
}

.tablet-min-h-35vh {
  min-height: 35vh;
}

.tablet-min-h-40vh {
  min-height: 40vh;
}

.tablet-min-h-45vh {
  min-height: 45vh;
}

.tablet-min-h-50vh {
  min-height: 50vh;
}

.tablet-min-h-55vh {
  min-height: 55vh;
}

.tablet-min-h-60vh {
  min-height: 60vh;
}

.tablet-min-h-65vh {
  min-height: 65vh;
}

.tablet-min-h-70vh {
  min-height: 70vh;
}

.tablet-min-h-75vh {
  min-height: 75vh;
}

.tablet-min-h-80vh {
  min-height: 80vh;
}

.tablet-min-h-85vh {
  min-height: 85vh;
}

.tablet-min-h-90vh {
  min-height: 90vh;
}

.tablet-min-h-95vh {
  min-height: 95vh;
}

.tablet-min-h-100vh {
  min-height: 100vh;
}

.tablet-min-h-0rem {
  min-height: 0rem;
}

.tablet-min-h-5rem {
  min-height: 5rem;
}

.tablet-min-h-10rem {
  min-height: 10rem;
}

.tablet-min-h-15rem {
  min-height: 15rem;
}

.tablet-min-h-20rem {
  min-height: 20rem;
}

.tablet-min-h-25rem {
  min-height: 25rem;
}

.tablet-min-h-30rem {
  min-height: 30rem;
}

.tablet-min-h-35rem {
  min-height: 35rem;
}

.tablet-min-h-40rem {
  min-height: 40rem;
}

.tablet-min-h-45rem {
  min-height: 45rem;
}

.tablet-min-h-50rem {
  min-height: 50rem;
}

.tablet-min-h-55rem {
  min-height: 55rem;
}

.tablet-min-h-60rem {
  min-height: 60rem;
}

.tablet-min-h-65rem {
  min-height: 65rem;
}

.tablet-min-h-70rem {
  min-height: 70rem;
}

.tablet-min-h-75rem {
  min-height: 75rem;
}

.tablet-min-h-80rem {
  min-height: 80rem;
}

.tablet-min-h-85rem {
  min-height: 85rem;
}

.tablet-min-h-90rem {
  min-height: 90rem;
}

.tablet-min-h-95rem {
  min-height: 95rem;
}

.tablet-min-h-100rem {
  min-height: 100rem;
}

.tablet-min-h-0em {
  min-height: 0em;
}

.tablet-min-h-5em {
  min-height: 5em;
}

.tablet-min-h-10em {
  min-height: 10em;
}

.tablet-min-h-15em {
  min-height: 15em;
}

.tablet-min-h-20em {
  min-height: 20em;
}

.tablet-min-h-25em {
  min-height: 25em;
}

.tablet-min-h-30em {
  min-height: 30em;
}

.tablet-min-h-35em {
  min-height: 35em;
}

.tablet-min-h-40em {
  min-height: 40em;
}

.tablet-min-h-45em {
  min-height: 45em;
}

.tablet-min-h-50em {
  min-height: 50em;
}

.tablet-min-h-55em {
  min-height: 55em;
}

.tablet-min-h-60em {
  min-height: 60em;
}

.tablet-min-h-65em {
  min-height: 65em;
}

.tablet-min-h-70em {
  min-height: 70em;
}

.tablet-min-h-75em {
  min-height: 75em;
}

.tablet-min-h-80em {
  min-height: 80em;
}

.tablet-min-h-85em {
  min-height: 85em;
}

.tablet-min-h-90em {
  min-height: 90em;
}

.tablet-min-h-95em {
  min-height: 95em;
}

.tablet-min-h-100em {
  min-height: 100em;
}


/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ▄▀▄ █ ▄▄▀█▄▀█▀▄████ ██ ██ ▄▄▄█▄ ▄██ ▄▄ ██ ██ █▄▄ ▄▄██
██ █ █ █ ▀▀ ███ ██████ ▄▄ ██ ▄▄▄██ ███ █▀▀██ ▄▄ ███ ████
██ ███ █ ██ █▀▄█▄▀████ ██ ██ ▀▀▀█▀ ▀██ ▀▀▄██ ██ ███ ████
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀

*/
.tablet-max-h-0 {
  max-height: 0%;
}

.tablet-max-h-5 {
  max-height: 5%;
}

.tablet-max-h-10 {
  max-height: 10%;
}

.tablet-max-h-15 {
  max-height: 15%;
}

.tablet-max-h-20 {
  max-height: 20%;
}

.tablet-max-h-25 {
  max-height: 25%;
}

.tablet-max-h-30 {
  max-height: 30%;
}

.tablet-max-h-35 {
  max-height: 35%;
}

.tablet-max-h-40 {
  max-height: 40%;
}

.tablet-max-h-45 {
  max-height: 45%;
}

.tablet-max-h-50 {
  max-height: 50%;
}

.tablet-max-h-55 {
  max-height: 55%;
}

.tablet-max-h-60 {
  max-height: 60%;
}

.tablet-max-h-65 {
  max-height: 65%;
}

.tablet-max-h-70 {
  max-height: 70%;
}

.tablet-max-h-75 {
  max-height: 75%;
}

.tablet-max-h-80 {
  max-height: 80%;
}

.tablet-max-h-85 {
  max-height: 85%;
}

.tablet-max-h-90 {
  max-height: 90%;
}

.tablet-max-h-95 {
  max-height: 95%;
}

.tablet-max-h-100 {
  max-height: 100%;
}

.tablet-max-h-0px {
  max-height: 0px;
}

.tablet-max-h-5px {
  max-height: 5px;
}

.tablet-max-h-10px {
  max-height: 10px;
}

.tablet-max-h-15px {
  max-height: 15px;
}

.tablet-max-h-20px {
  max-height: 20px;
}

.tablet-max-h-25px {
  max-height: 25px;
}

.tablet-max-h-30px {
  max-height: 30px;
}

.tablet-max-h-35px {
  max-height: 35px;
}

.tablet-max-h-40px {
  max-height: 40px;
}

.tablet-max-h-45px {
  max-height: 45px;
}

.tablet-max-h-50px {
  max-height: 50px;
}

.tablet-max-h-55px {
  max-height: 55px;
}

.tablet-max-h-60px {
  max-height: 60px;
}

.tablet-max-h-65px {
  max-height: 65px;
}

.tablet-max-h-70px {
  max-height: 70px;
}

.tablet-max-h-75px {
  max-height: 75px;
}

.tablet-max-h-80px {
  max-height: 80px;
}

.tablet-max-h-85px {
  max-height: 85px;
}

.tablet-max-h-90px {
  max-height: 90px;
}

.tablet-max-h-95px {
  max-height: 95px;
}

.tablet-max-h-100px {
  max-height: 100px;
}

.tablet-max-h-0vh {
  max-height: 0vh;
}

.tablet-max-h-5vh {
  max-height: 5vh;
}

.tablet-max-h-10vh {
  max-height: 10vh;
}

.tablet-max-h-15vh {
  max-height: 15vh;
}

.tablet-max-h-20vh {
  max-height: 20vh;
}

.tablet-max-h-25vh {
  max-height: 25vh;
}

.tablet-max-h-30vh {
  max-height: 30vh;
}

.tablet-max-h-35vh {
  max-height: 35vh;
}

.tablet-max-h-40vh {
  max-height: 40vh;
}

.tablet-max-h-45vh {
  max-height: 45vh;
}

.tablet-max-h-50vh {
  max-height: 50vh;
}

.tablet-max-h-55vh {
  max-height: 55vh;
}

.tablet-max-h-60vh {
  max-height: 60vh;
}

.tablet-max-h-65vh {
  max-height: 65vh;
}

.tablet-max-h-70vh {
  max-height: 70vh;
}

.tablet-max-h-75vh {
  max-height: 75vh;
}

.tablet-max-h-80vh {
  max-height: 80vh;
}

.tablet-max-h-85vh {
  max-height: 85vh;
}

.tablet-max-h-90vh {
  max-height: 90vh;
}

.tablet-max-h-95vh {
  max-height: 95vh;
}

.tablet-max-h-100vh {
  max-height: 100vh;
}

.tablet-max-h-0rem {
  max-height: 0rem;
}

.tablet-max-h-5rem {
  max-height: 5rem;
}

.tablet-max-h-10rem {
  max-height: 10rem;
}

.tablet-max-h-15rem {
  max-height: 15rem;
}

.tablet-max-h-20rem {
  max-height: 20rem;
}

.tablet-max-h-25rem {
  max-height: 25rem;
}

.tablet-max-h-30rem {
  max-height: 30rem;
}

.tablet-max-h-35rem {
  max-height: 35rem;
}

.tablet-max-h-40rem {
  max-height: 40rem;
}

.tablet-max-h-45rem {
  max-height: 45rem;
}

.tablet-max-h-50rem {
  max-height: 50rem;
}

.tablet-max-h-55rem {
  max-height: 55rem;
}

.tablet-max-h-60rem {
  max-height: 60rem;
}

.tablet-max-h-65rem {
  max-height: 65rem;
}

.tablet-max-h-70rem {
  max-height: 70rem;
}

.tablet-max-h-75rem {
  max-height: 75rem;
}

.tablet-max-h-80rem {
  max-height: 80rem;
}

.tablet-max-h-85rem {
  max-height: 85rem;
}

.tablet-max-h-90rem {
  max-height: 90rem;
}

.tablet-max-h-95rem {
  max-height: 95rem;
}

.tablet-max-h-100rem {
  max-height: 100rem;
}

.tablet-max-h-0em {
  max-height: 0em;
}

.tablet-max-h-5em {
  max-height: 5em;
}

.tablet-max-h-10em {
  max-height: 10em;
}

.tablet-max-h-15em {
  max-height: 15em;
}

.tablet-max-h-20em {
  max-height: 20em;
}

.tablet-max-h-25em {
  max-height: 25em;
}

.tablet-max-h-30em {
  max-height: 30em;
}

.tablet-max-h-35em {
  max-height: 35em;
}

.tablet-max-h-40em {
  max-height: 40em;
}

.tablet-max-h-45em {
  max-height: 45em;
}

.tablet-max-h-50em {
  max-height: 50em;
}

.tablet-max-h-55em {
  max-height: 55em;
}

.tablet-max-h-60em {
  max-height: 60em;
}

.tablet-max-h-65em {
  max-height: 65em;
}

.tablet-max-h-70em {
  max-height: 70em;
}

.tablet-max-h-75em {
  max-height: 75em;
}

.tablet-max-h-80em {
  max-height: 80em;
}

.tablet-max-h-85em {
  max-height: 85em;
}

.tablet-max-h-90em {
  max-height: 90em;
}

.tablet-max-h-95em {
  max-height: 95em;
}

.tablet-max-h-100em {
  max-height: 100em;
}

/*
  
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ███ ████████ ▄▄▀██ ▀██ ██ ▄▄▀█████████ ██ ██
██ █ █ ███▄▄███ ▀▀ ██ █ █ ██ ██ ███▄▄████ ▄▄ ██
██▄▀▄▀▄████████ ██ ██ ██▄ ██ ▀▀ █████████ ██ ██
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀

  
  */
/*
█░█░█   ▄▀█ █▄░█ █▀▄   █░█   █▀ █▀█ █▀▀ █▀▀ █ ▄▀█ █░░ █▀
▀▄▀▄▀   █▀█ █░▀█ █▄▀   █▀█   ▄█ █▀▀ ██▄ █▄▄ █ █▀█ █▄▄ ▄█
*/
.tablet-w-and-h-inh,
.tablet-w-and-h-inherit {
  width: inherit;
  height: inherit;
}

.tablet-w-and-h-a,
.tablet-w-and-h-auto {
  width: auto;
  height: auto;
}



/*
  
█░█░█ ▄▄ ▄▀█ █▄░█ █▀▄ ▄▄ █░█   ▀░▄▀
▀▄▀▄▀ ░░ █▀█ █░▀█ █▄▀ ░░ █▀█   ▄▀░▄
  
  */

.tablet-w-and-h-0 {
  width: 0%;
  height: 0%;
}

.tablet-w-and-h-5 {
  width: 5%;
  height: 5%;
}

.tablet-w-and-h-10 {
  width: 10%;
  height: 10%;
}

.tablet-w-and-h-15 {
  width: 15%;
  height: 15%;
}

.tablet-w-and-h-20 {
  width: 20%;
  height: 20%;
}

.tablet-w-and-h-25 {
  width: 25%;
  height: 25%;
}

.tablet-w-and-h-30 {
  width: 30%;
  height: 30%;
}

.tablet-w-and-h-33 {
  width: 33%;
  height: 33%;
}

.tablet-w-and-h-35 {
  width: 35%;
  height: 35%;
}

.tablet-w-and-h-40 {
  width: 40%;
  height: 40%;
}

.tablet-w-and-h-45 {
  width: 45%;
  height: 45%;
}

.tablet-w-and-h-50 {
  width: 50%;
  height: 50%;
}

.tablet-w-and-h-55 {
  width: 55%;
  height: 55%;
}

.tablet-w-and-h-60 {
  width: 60%;
  height: 60%;
}

.tablet-w-and-h-65 {
  width: 65%;
  height: 65%;
}

.tablet-w-and-h-70 {
  width: 70%;
  height: 70%;
}

.tablet-w-and-h-75 {
  width: 75%;
  height: 75%;
}

.tablet-w-and-h-80 {
  width: 80%;
  height: 80%;
}

.tablet-w-and-h-85 {
  width: 85%;
  height: 85%;
}

.tablet-w-and-h-90 {
  width: 90%;
  height: 90%;
}

.tablet-w-and-h-95 {
  width: 95%;
  height: 95%;
}

.tablet-w-and-h-100 {
  width: 100%;
  height: 100%;
}

/*

█░█░█ ▄▄ ▄▀█ █▄░█ █▀▄ ▄▄ █░█   █▀█ ▀▄▀
▀▄▀▄▀ ░░ █▀█ █░▀█ █▄▀ ░░ █▀█   █▀▀ █░█

*/


.tablet-w-and-h-0px {
  width: 0px;
  height: 0px;
}

.tablet-w-and-h-5px {
  width: 5px;
  height: 5px;
}

.tablet-w-and-h-10px {
  width: 10px;
  height: 10px;
}

.tablet-w-and-h-15px {
  width: 15px;
  height: 15px;
}

.tablet-w-and-h-20px {
  width: 20px;
  height: 20px;
}

.tablet-w-and-h-25px {
  width: 25px;
  height: 25px;
}

.tablet-w-and-h-30px {
  width: 30px;
  height: 30px;
}

.tablet-w-and-h-35px {
  width: 35px;
  height: 35px;
}

.tablet-w-and-h-40px {
  width: 40px;
  height: 40px;
}

.tablet-w-and-h-45px {
  width: 45px;
  height: 45px;
}

.tablet-w-and-h-50px {
  width: 50px;
  height: 50px;
}

.tablet-w-and-h-55px {
  width: 55px;
  height: 55px;
}

.tablet-w-and-h-60px {
  width: 60px;
  height: 60px;
}

.tablet-w-and-h-65px {
  width: 65px;
  height: 65px;
}

.tablet-w-and-h-70px {
  width: 70px;
  height: 70px;
}

.tablet-w-and-h-75px {
  width: 75px;
  height: 75px;
}

.tablet-w-and-h-80px {
  width: 80px;
  height: 80px;
}

.tablet-w-and-h-85px {
  width: 85px;
  height: 85px;
}

.tablet-w-and-h-90px {
  width: 90px;
  height: 90px;
}

.tablet-w-and-h-95px {
  width: 95px;
  height: 95px;
}

.tablet-w-and-h-100px {
  width: 100px;
  height: 100px;
}

/*
  
█░█░█ ▄▄ ▄▀█ █▄░█ █▀▄ ▄▄ █░█   █░█ █▀█
▀▄▀▄▀ ░░ █▀█ █░▀█ █▄▀ ░░ █▀█   ▀▄▀ █▀▀
  
  */

.tablet-w-and-h-0vp {
  width: 0vw;
  height: 0vh;
}

.tablet-w-and-h-5vp {
  width: 5vw;
  height: 5vh;
}

.tablet-w-and-h-10vp {
  width: 10vw;
  height: 10vh;
}

.tablet-w-and-h-15vp {
  width: 15vw;
  height: 15vh;
}

.tablet-w-and-h-20vp {
  width: 20vw;
  height: 20vh;
}

.tablet-w-and-h-25vp {
  width: 25vw;
  height: 25vh;
}

.tablet-w-and-h-30vp {
  width: 30vw;
  height: 30vh;
}

.tablet-w-and-h-35vp {
  width: 35vw;
  height: 35vh;
}

.tablet-w-and-h-40vp {
  width: 40vw;
  height: 40vh;
}

.tablet-w-and-h-45vp {
  width: 45vw;
  height: 45vh;
}

.tablet-w-and-h-50vp {
  width: 50vw;
  height: 50vh;
}

.tablet-w-and-h-55vp {
  width: 55vw;
  height: 55vh;
}

.tablet-w-and-h-60vp {
  width: 60vw;
  height: 60vh;
}

.tablet-w-and-h-65vp {
  width: 65vw;
  height: 65vh;
}

.tablet-w-and-h-70vp {
  width: 70vw;
  height: 70vh;
}

.tablet-w-and-h-75vp {
  width: 75vw;
  height: 75vh;
}

.tablet-w-and-h-80vp {
  width: 80vw;
  height: 80vh;
}

.tablet-w-and-h-85vp {
  width: 85vw;
  height: 85vh;
}

.tablet-w-and-h-90vp {
  width: 90vw;
  height: 90vh;
}

.tablet-w-and-h-95vp {
  width: 95vw;
  height: 95vh;
}

.tablet-w-and-h-100vp {
  width: 100vw;
  height: 100vh;
}

/*
  
█░█░█ ▄▄ ▄▀█ █▄░█ █▀▄ ▄▄ █░█   █▀█ █▀▀ █▀▄▀█
▀▄▀▄▀ ░░ █▀█ █░▀█ █▄▀ ░░ █▀█   █▀▄ ██▄ █░▀░█
  
  */

.tablet-w-and-h-0rem {
  width: 0rem;
  height: 0rem;
}

.tablet-w-and-h-5rem {
  width: 5rem;
  height: 5rem;
}

.tablet-w-and-h-10rem {
  width: 10rem;
  height: 10rem;
}

.tablet-w-and-h-15rem {
  width: 15rem;
  height: 15rem;
}

.tablet-w-and-h-20rem {
  width: 20rem;
  height: 20rem;
}

.tablet-w-and-h-25rem {
  width: 25rem;
  height: 25rem;
}

.tablet-w-and-h-30rem {
  width: 30rem;
  height: 30rem;
}

.tablet-w-and-h-35rem {
  width: 35rem;
  height: 35rem;
}

.tablet-w-and-h-40rem {
  width: 40rem;
  height: 40rem;
}

.tablet-w-and-h-45rem {
  width: 45rem;
  height: 45rem;
}

.tablet-w-and-h-50rem {
  width: 50rem;
  height: 50rem;
}

.tablet-w-and-h-55rem {
  width: 55rem;
  height: 55rem;
}

.tablet-w-and-h-60rem {
  width: 60rem;
  height: 60rem;
}

.tablet-w-and-h-65rem {
  width: 65rem;
  height: 65rem;
}

.tablet-w-and-h-70rem {
  width: 70rem;
  height: 70rem;
}

.tablet-w-and-h-75rem {
  width: 75rem;
  height: 75rem;
}

.tablet-w-and-h-80rem {
  width: 80rem;
  height: 80rem;
}

.tablet-w-and-h-85rem {
  width: 85rem;
  height: 85rem;
}

.tablet-w-and-h-90rem {
  width: 90rem;
  height: 90rem;
}

.tablet-w-and-h-95rem {
  width: 95rem;
  height: 95rem;
}

.tablet-w-and-h-100rem {
  width: 100rem;
  height: 100rem;
}

/*
  
█░█░█ ▄▄ ▄▀█ █▄░█ █▀▄ ▄▄ █░█   █▀▀ █▀▄▀█
▀▄▀▄▀ ░░ █▀█ █░▀█ █▄▀ ░░ █▀█   ██▄ █░▀░█
  
  */


.tablet-w-and-h-0em {
  width: 0em;
  height: 0em;
}

.tablet-w-and-h-5em {
  width: 5em;
  height: 5em;
}

.tablet-w-and-h-10em {
  width: 10em;
  height: 10em;
}

.tablet-w-and-h-15em {
  width: 15em;
  height: 15em;
}

.tablet-w-and-h-20em {
  width: 20em;
  height: 20em;
}

.tablet-w-and-h-25em {
  width: 25em;
  height: 25em;
}

.tablet-w-and-h-30em {
  width: 30em;
  height: 30em;
}

.tablet-w-and-h-35em {
  width: 35em;
  height: 35em;
}

.tablet-w-and-h-40em {
  width: 40em;
  height: 40em;
}

.tablet-w-and-h-45em {
  width: 45em;
  height: 45em;
}

.tablet-w-and-h-50em {
  width: 50em;
  height: 50em;
}

.tablet-w-and-h-55em {
  width: 55em;
  height: 55em;
}

.tablet-w-and-h-60em {
  width: 60em;
  height: 60em;
}

.tablet-w-and-h-65em {
  width: 65em;
  height: 65em;
}

.tablet-w-and-h-70em {
  width: 70em;
  height: 70em;
}

.tablet-w-and-h-75em {
  width: 75em;
  height: 75em;
}

.tablet-w-and-h-80em {
  width: 80em;
  height: 80em;
}

.tablet-w-and-h-85em {
  width: 85em;
  height: 85em;
}

.tablet-w-and-h-90em {
  width: 90em;
  height: 90em;
}

.tablet-w-and-h-95em {
  width: 95em;
  height: 95em;
}

.tablet-w-and-h-100em {
  width: 100em;
  height: 100em;
}

/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ▄▀▄ █▄ ▄██ ▀██ ████ ███ ███ ▄▄▀██ ▀██ ██ ▄▄▀████ ██ ██
██ █ █ ██ ███ █ █ ████ █ █ ███ ▀▀ ██ █ █ ██ ██ ████ ▄▄ ██
██ ███ █▀ ▀██ ██▄ ████▄▀▄▀▄███ ██ ██ ██▄ ██ ▀▀ ████ ██ ██
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀


*/
/*
█▀▄▀█ █ █▄░█ ▄▄ █░█░█ ▄▄ ▄▀█ █▄░█ █▀▄ ▄▄ █░█ ▄▄ █▀ █▀█ █▀▀ █▀▀ █ ▄▀█ █░░ █▀
█░▀░█ █ █░▀█ ░░ ▀▄▀▄▀ ░░ █▀█ █░▀█ █▄▀ ░░ █▀█ ░░ ▄█ █▀▀ ██▄ █▄▄ █ █▀█ █▄▄ ▄█
*/
.tablet-min-w-and-h-inh,
.tablet-min-w-and-h-inherit {
  width: inherit;
  height: inherit;
}

.tablet-min-w-and-h-a,
.tablet-min-w-and-h-auto {
  width: auto;
  height: auto;
}

/*

█▀▄▀█ █ █▄░█ ▄▄ █░█░█ ▄▄ ▄▀█ █▄░█ █▀▄ ▄▄ █░█
█░▀░█ █ █░▀█ ░░ ▀▄▀▄▀ ░░ █▀█ █░▀█ █▄▀ ░░ █▀█
*/

.tablet-min-w-and-h-0 {
  min-width: 0%;
  min-height: 0%;
}

.tablet-min-w-and-h-5 {
  min-width: 5%;
  min-height: 5%;
}

.tablet-min-w-and-h-10 {
  min-width: 10%;
  min-height: 10%;
}

.tablet-min-w-and-h-15 {
  min-width: 15%;
  min-height: 15%;
}

.tablet-min-w-and-h-20 {
  min-width: 20%;
  min-height: 20%;
}

.tablet-min-w-and-h-25 {
  min-width: 25%;
  min-height: 25%;
}

.tablet-min-w-and-h-30 {
  min-width: 30%;
  min-height: 30%;
}

.tablet-min-w-and-h-35 {
  min-width: 35%;
  min-height: 35%;
}

.tablet-min-w-and-h-40 {
  min-width: 40%;
  min-height: 40%;
}

.tablet-min-w-and-h-45 {
  min-width: 45%;
  min-height: 45%;
}

.tablet-min-w-and-h-50 {
  min-width: 50%;
  min-height: 50%;
}

.tablet-min-w-and-h-55 {
  min-width: 55%;
  min-height: 55%;
}

.tablet-min-w-and-h-60 {
  min-width: 60%;
  min-height: 60%;
}

.tablet-min-w-and-h-65 {
  min-width: 65%;
  min-height: 65%;
}

.tablet-min-w-and-h-70 {
  min-width: 70%;
  min-height: 70%;
}

.tablet-min-w-and-h-75 {
  min-width: 75%;
  min-height: 75%;
}

.tablet-min-w-and-h-80 {
  min-width: 80%;
  min-height: 80%;
}

.tablet-min-w-and-h-85 {
  min-width: 85%;
  min-height: 85%;
}

.tablet-min-w-and-h-90 {
  min-width: 90%;
  min-height: 90%;
}

.tablet-min-w-and-h-95 {
  min-width: 95%;
  min-height: 95%;
}

.tablet-min-w-and-h-100 {
  min-width: 100%;
  min-height: 100%;
}

.tablet-min-w-and-h-0px {
  min-width: 0px;
  min-height: 0px;
}

.tablet-min-w-and-h-5px {
  min-width: 5px;
  min-height: 5px;
}

.tablet-min-w-and-h-10px {
  min-width: 10px;
  min-height: 10px;
}

.tablet-min-w-and-h-15px {
  min-width: 15px;
  min-height: 15px;
}

.tablet-min-w-and-h-20px {
  min-width: 20px;
  min-height: 20px;
}

.tablet-min-w-and-h-25px {
  min-width: 25px;
  min-height: 25px;
}

.tablet-min-w-and-h-30px {
  min-width: 30px;
  min-height: 30px;
}

.tablet-min-w-and-h-35px {
  min-width: 35px;
  min-height: 35px;
}

.tablet-min-w-and-h-40px {
  min-width: 40px;
  min-height: 40px;
}

.tablet-min-w-and-h-45px {
  min-width: 45px;
  min-height: 45px;
}

.tablet-min-w-and-h-50px {
  min-width: 50px;
  min-height: 50px;
}

.tablet-min-w-and-h-55px {
  min-width: 55px;
  min-height: 55px;
}

.tablet-min-w-and-h-60px {
  min-width: 60px;
  min-height: 60px;
}

.tablet-min-w-and-h-65px {
  min-width: 65px;
  min-height: 65px;
}

.tablet-min-w-and-h-70px {
  min-width: 70px;
  min-height: 70px;
}

.tablet-min-w-and-h-75px {
  min-width: 75px;
  min-height: 75px;
}

.tablet-min-w-and-h-80px {
  min-width: 80px;
  min-height: 80px;
}

.tablet-min-w-and-h-85px {
  min-width: 85px;
  min-height: 85px;
}

.tablet-min-w-and-h-90px {
  min-width: 90px;
  min-height: 90px;
}

.tablet-min-w-and-h-95px {
  min-width: 95px;
  min-height: 95px;
}

.tablet-min-w-and-h-100px {
  min-width: 100px;
  min-height: 100px;
}

.tablet-min-w-and-h-0vp {
  min-width: 0vw;
  min-height: 0vh;
}

.tablet-min-w-and-h-5vp {
  min-width: 5vw;
  min-height: 5vh;
}

.tablet-min-w-and-h-10vp {
  min-width: 10vw;
  min-height: 10vh;
}

.tablet-min-w-and-h-15vp {
  min-width: 15vw;
  min-height: 15vh;
}

.tablet-min-w-and-h-20vp {
  min-width: 20vw;
  min-height: 20vh;
}

.tablet-min-w-and-h-25vp {
  min-width: 25vw;
  min-height: 25vh;
}

.tablet-min-w-and-h-30vp {
  min-width: 30vw;
  min-height: 30vh;
}

.tablet-min-w-and-h-35vp {
  min-width: 35vw;
  min-height: 35vh;
}

.tablet-min-w-and-h-40vp {
  min-width: 40vw;
  min-height: 40vh;
}

.tablet-min-w-and-h-45vp {
  min-width: 45vw;
  min-height: 45vh;
}

.tablet-min-w-and-h-50vp {
  min-width: 50vw;
  min-height: 50vh;
}

.tablet-min-w-and-h-55vp {
  min-width: 55vw;
  min-height: 55vh;
}

.tablet-min-w-and-h-60vp {
  min-width: 60vw;
  min-height: 60vh;
}

.tablet-min-w-and-h-65vp {
  min-width: 65vw;
  min-height: 65vh;
}

.tablet-min-w-and-h-70vp {
  min-width: 70vw;
  min-height: 70vh;
}

.tablet-min-w-and-h-75vp {
  min-width: 75vw;
  min-height: 75vh;
}

.tablet-min-w-and-h-80vp {
  min-width: 80vw;
  min-height: 80vh;
}

.tablet-min-w-and-h-85vp {
  min-width: 85vw;
  min-height: 85vh;
}

.tablet-min-w-and-h-90vp {
  min-width: 90vw;
  min-height: 90vh;
}

.tablet-min-w-and-h-95vp {
  min-width: 95vw;
  min-height: 95vh;
}

.tablet-min-w-and-h-100vp {
  min-width: 100vw;
  min-height: 100vh;
}

.tablet-min-w-and-h-0rem {
  min-width: 0rem;
  min-height: 0rem;
}

.tablet-min-w-and-h-5rem {
  min-width: 5rem;
  min-height: 5rem;
}

.tablet-min-w-and-h-10rem {
  min-width: 10rem;
  min-height: 10rem;
}

.tablet-min-w-and-h-15rem {
  min-width: 15rem;
  min-height: 15rem;
}

.tablet-min-w-and-h-20rem {
  min-width: 20rem;
  min-height: 20rem;
}

.tablet-min-w-and-h-25rem {
  min-width: 25rem;
  min-height: 25rem;
}

.tablet-min-w-and-h-30rem {
  min-width: 30rem;
  min-height: 30rem;
}

.tablet-min-w-and-h-35rem {
  min-width: 35rem;
  min-height: 35rem;
}

.tablet-min-w-and-h-40rem {
  min-width: 40rem;
  min-height: 40rem;
}

.tablet-min-w-and-h-45rem {
  min-width: 45rem;
  min-height: 45rem;
}

.tablet-min-w-and-h-50rem {
  min-width: 50rem;
  min-height: 50rem;
}

.tablet-min-w-and-h-55rem {
  min-width: 55rem;
  min-height: 55rem;
}

.tablet-min-w-and-h-60rem {
  min-width: 60rem;
  min-height: 60rem;
}

.tablet-min-w-and-h-65rem {
  min-width: 65rem;
  min-height: 65rem;
}

.tablet-min-w-and-h-70rem {
  min-width: 70rem;
  min-height: 70rem;
}

.tablet-min-w-and-h-75rem {
  min-width: 75rem;
  min-height: 75rem;
}

.tablet-min-w-and-h-80rem {
  min-width: 80rem;
  min-height: 80rem;
}

.tablet-min-w-and-h-85rem {
  min-width: 85rem;
  min-height: 85rem;
}

.tablet-min-w-and-h-90rem {
  min-width: 90rem;
  min-height: 90rem;
}

.tablet-min-w-and-h-95rem {
  min-width: 95rem;
  min-height: 95rem;
}

.tablet-min-w-and-h-100rem {
  min-width: 100rem;
  min-height: 100rem;
}

.tablet-min-w-and-h-0em {
  min-width: 0em;
  min-height: 0em;
}

.tablet-min-w-and-h-5em {
  min-width: 5em;
  min-height: 5em;
}

.tablet-min-w-and-h-10em {
  min-width: 10em;
  min-height: 10em;
}

.tablet-min-w-and-h-15em {
  min-width: 15em;
  min-height: 15em;
}

.tablet-min-w-and-h-20em {
  min-width: 20em;
  min-height: 20em;
}

.tablet-min-w-and-h-25em {
  min-width: 25em;
  min-height: 25em;
}

.tablet-min-w-and-h-30em {
  min-width: 30em;
  min-height: 30em;
}

.tablet-min-w-and-h-35em {
  min-width: 35em;
  min-height: 35em;
}

.tablet-min-w-and-h-40em {
  min-width: 40em;
  min-height: 40em;
}

.tablet-min-w-and-h-45em {
  min-width: 45em;
  min-height: 45em;
}

.tablet-min-w-and-h-50em {
  min-width: 50em;
  min-height: 50em;
}

.tablet-min-w-and-h-55em {
  min-width: 55em;
  min-height: 55em;
}

.tablet-min-w-and-h-60em {
  min-width: 60em;
  min-height: 60em;
}

.tablet-min-w-and-h-65em {
  min-width: 65em;
  min-height: 65em;
}

.tablet-min-w-and-h-70em {
  min-width: 70em;
  min-height: 70em;
}

.tablet-min-w-and-h-75em {
  min-width: 75em;
  min-height: 75em;
}

.tablet-min-w-and-h-80em {
  min-width: 80em;
  min-height: 80em;
}

.tablet-min-w-and-h-85em {
  min-width: 85em;
  min-height: 85em;
}

.tablet-min-w-and-h-90em {
  min-width: 90em;
  min-height: 90em;
}

.tablet-min-w-and-h-95em {
  min-width: 95em;
  min-height: 95em;
}

.tablet-min-w-and-h-100em {
  min-width: 100em;
  min-height: 100em;
}


/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ▄▀▄ █ ▄▄▀█▄▀█▀▄████ ███ ███ ▄▄▀██ ▀██ ██ ▄▄▀████ ██ ██
██ █ █ █ ▀▀ ███ ██████ █ █ ███ ▀▀ ██ █ █ ██ ██ ████ ▄▄ ██
██ ███ █ ██ █▀▄█▄▀████▄▀▄▀▄███ ██ ██ ██▄ ██ ▀▀ ████ ██ ██
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀

*/
/*
█▀▄▀█ ▄▀█ ▀▄▀ ▄▄ █░█░█ ▄▄ ▄▀█ █▄░█ █▀▄ ▄▄ █░█ ▄▄ █▀ █▀█ █▀▀ █▀▀ █ ▄▀█ █░░ █▀
█░▀░█ █▀█ █░█ ░░ ▀▄▀▄▀ ░░ █▀█ █░▀█ █▄▀ ░░ █▀█ ░░ ▄█ █▀▀ ██▄ █▄▄ █ █▀█ █▄▄ ▄█
*/

.tablet-max-w-and-h-inh,
.tablet-max-w-and-h-inherit {
  width: inherit;
  height: inherit;
}

.tablet-max-w-and-h-a,
.tablet-max-w-and-h-auto {
  width: auto;
  height: auto;
}

/*
█▀▄▀█ ▄▀█ ▀▄▀ ▄▄ █░█░█ ▄▄ ▄▀█ █▄░█ █▀▄ ▄▄ █░█
█░▀░█ █▀█ █░█ ░░ ▀▄▀▄▀ ░░ █▀█ █░▀█ █▄▀ ░░ █▀█
*/

.tablet-max-w-and-h-0 {
  max-width: 0%;
  max-height: 0%;
}

.tablet-max-w-and-h-5 {
  max-width: 5%;
  max-height: 5%;
}

.tablet-max-w-and-h-10 {
  max-width: 10%;
  max-height: 10%;
}

.tablet-max-w-and-h-15 {
  max-width: 15%;
  max-height: 15%;
}

.tablet-max-w-and-h-20 {
  max-width: 20%;
  max-height: 20%;
}

.tablet-max-w-and-h-25 {
  max-width: 25%;
  max-height: 25%;
}

.tablet-max-w-and-h-30 {
  max-width: 30%;
  max-height: 30%;
}

.tablet-max-w-and-h-35 {
  max-width: 35%;
  max-height: 35%;
}

.tablet-max-w-and-h-40 {
  max-width: 40%;
  max-height: 40%;
}

.tablet-max-w-and-h-45 {
  max-width: 45%;
  max-height: 45%;
}

.tablet-max-w-and-h-50 {
  max-width: 50%;
  max-height: 50%;
}

.tablet-max-w-and-h-55 {
  max-width: 55%;
  max-height: 55%;
}

.tablet-max-w-and-h-60 {
  max-width: 60%;
  max-height: 60%;
}

.tablet-max-w-and-h-65 {
  max-width: 65%;
  max-height: 65%;
}

.tablet-max-w-and-h-70 {
  max-width: 70%;
  max-height: 70%;
}

.tablet-max-w-and-h-75 {
  max-width: 75%;
  max-height: 75%;
}

.tablet-max-w-and-h-80 {
  max-width: 80%;
  max-height: 80%;
}

.tablet-max-w-and-h-85 {
  max-width: 85%;
  max-height: 85%;
}

.tablet-max-w-and-h-90 {
  max-width: 90%;
  max-height: 90%;
}

.tablet-max-w-and-h-95 {
  max-width: 95%;
  max-height: 95%;
}

.tablet-max-w-and-h-100 {
  max-width: 100%;
  max-height: 100%;
}

.tablet-max-w-and-h-0px {
  max-width: 0px;
  max-height: 0px;
}

.tablet-max-w-and-h-5px {
  max-width: 5px;
  max-height: 5px;
}

.tablet-max-w-and-h-10px {
  max-width: 10px;
  max-height: 10px;
}

.tablet-max-w-and-h-15px {
  max-width: 15px;
  max-height: 15px;
}

.tablet-max-w-and-h-20px {
  max-width: 20px;
  max-height: 20px;
}

.tablet-max-w-and-h-25px {
  max-width: 25px;
  max-height: 25px;
}

.tablet-max-w-and-h-30px {
  max-width: 30px;
  max-height: 30px;
}

.tablet-max-w-and-h-35px {
  max-width: 35px;
  max-height: 35px;
}

.tablet-max-w-and-h-40px {
  max-width: 40px;
  max-height: 40px;
}

.tablet-max-w-and-h-45px {
  max-width: 45px;
  max-height: 45px;
}

.tablet-max-w-and-h-50px {
  max-width: 50px;
  max-height: 50px;
}

.tablet-max-w-and-h-55px {
  max-width: 55px;
  max-height: 55px;
}

.tablet-max-w-and-h-60px {
  max-width: 60px;
  max-height: 60px;
}

.tablet-max-w-and-h-65px {
  max-width: 65px;
  max-height: 65px;
}

.tablet-max-w-and-h-70px {
  max-width: 70px;
  max-height: 70px;
}

.tablet-max-w-and-h-75px {
  max-width: 75px;
  max-height: 75px;
}

.tablet-max-w-and-h-80px {
  max-width: 80px;
  max-height: 80px;
}

.tablet-max-w-and-h-85px {
  max-width: 85px;
  max-height: 85px;
}

.tablet-max-w-and-h-90px {
  max-width: 90px;
  max-height: 90px;
}

.tablet-max-w-and-h-95px {
  max-width: 95px;
  max-height: 95px;
}

.tablet-max-w-and-h-100px {
  max-width: 100px;
  max-height: 100px;
}

.tablet-max-w-and-h-0vp {
  max-width: 0vw;
  max-height: 0vh;
}

.tablet-max-w-and-h-5vp {
  max-width: 5vw;
  max-height: 5vh;
}

.tablet-max-w-and-h-10vp {
  max-width: 10vw;
  max-height: 10vh;
}

.tablet-max-w-and-h-15vp {
  max-width: 15vw;
  max-height: 15vh;
}

.tablet-max-w-and-h-20vp {
  max-width: 20vw;
  max-height: 20vh;
}

.tablet-max-w-and-h-25vp {
  max-width: 25vw;
  max-height: 25vh;
}

.tablet-max-w-and-h-30vp {
  max-width: 30vw;
  max-height: 30vh;
}

.tablet-max-w-and-h-35vp {
  max-width: 35vw;
  max-height: 35vh;
}

.tablet-max-w-and-h-40vp {
  max-width: 40vw;
  max-height: 40vh;
}

.tablet-max-w-and-h-45vp {
  max-width: 45vw;
  max-height: 45vh;
}

.tablet-max-w-and-h-50vp {
  max-width: 50vw;
  max-height: 50vh;
}

.tablet-max-w-and-h-55vp {
  max-width: 55vw;
  max-height: 55vh;
}

.tablet-max-w-and-h-60vp {
  max-width: 60vw;
  max-height: 60vh;
}

.tablet-max-w-and-h-65vp {
  max-width: 65vw;
  max-height: 65vh;
}

.tablet-max-w-and-h-70vp {
  max-width: 70vw;
  max-height: 70vh;
}

.tablet-max-w-and-h-75vp {
  max-width: 75vw;
  max-height: 75vh;
}

.tablet-max-w-and-h-80vp {
  max-width: 80vw;
  max-height: 80vh;
}

.tablet-max-w-and-h-85vp {
  max-width: 85vw;
  max-height: 85vh;
}

.tablet-max-w-and-h-90vp {
  max-width: 90vw;
  max-height: 90vh;
}

.tablet-max-w-and-h-95vp {
  max-width: 95vw;
  max-height: 95vh;
}

.tablet-max-w-and-h-100vp {
  max-width: 100vw;
  max-height: 100vh;
}

.tablet-max-w-and-h-0rem {
  max-width: 0rem;
  max-height: 0rem;
}

.tablet-max-w-and-h-5rem {
  max-width: 5rem;
  max-height: 5rem;
}

.tablet-max-w-and-h-10rem {
  max-width: 10rem;
  max-height: 10rem;
}

.tablet-max-w-and-h-15rem {
  max-width: 15rem;
  max-height: 15rem;
}

.tablet-max-w-and-h-20rem {
  max-width: 20rem;
  max-height: 20rem;
}

.tablet-max-w-and-h-25rem {
  max-width: 25rem;
  max-height: 25rem;
}

.tablet-max-w-and-h-30rem {
  max-width: 30rem;
  max-height: 30rem;
}

.tablet-max-w-and-h-35rem {
  max-width: 35rem;
  max-height: 35rem;
}

.tablet-max-w-and-h-40rem {
  max-width: 40rem;
  max-height: 40rem;
}

.tablet-max-w-and-h-45rem {
  max-width: 45rem;
  max-height: 45rem;
}

.tablet-max-w-and-h-50rem {
  max-width: 50rem;
  max-height: 50rem;
}

.tablet-max-w-and-h-55rem {
  max-width: 55rem;
  max-height: 55rem;
}

.tablet-max-w-and-h-60rem {
  max-width: 60rem;
  max-height: 60rem;
}

.tablet-max-w-and-h-65rem {
  max-width: 65rem;
  max-height: 65rem;
}

.tablet-max-w-and-h-70rem {
  max-width: 70rem;
  max-height: 70rem;
}

.tablet-max-w-and-h-75rem {
  max-width: 75rem;
  max-height: 75rem;
}

.tablet-max-w-and-h-80rem {
  max-width: 80rem;
  max-height: 80rem;
}

.tablet-max-w-and-h-85rem {
  max-width: 85rem;
  max-height: 85rem;
}

.tablet-max-w-and-h-90rem {
  max-width: 90rem;
  max-height: 90rem;
}

.tablet-max-w-and-h-95rem {
  max-width: 95rem;
  max-height: 95rem;
}

.tablet-max-w-and-h-100rem {
  max-width: 100rem;
  max-height: 100rem;
}

.tablet-max-w-and-h-0em {
  max-width: 0em;
  max-height: 0em;
}

.tablet-max-w-and-h-5em {
  max-width: 5em;
  max-height: 5em;
}

.tablet-max-w-and-h-10em {
  max-width: 10em;
  max-height: 10em;
}

.tablet-max-w-and-h-15em {
  max-width: 15em;
  max-height: 15em;
}

.tablet-max-w-and-h-20em {
  max-width: 20em;
  max-height: 20em;
}

.tablet-max-w-and-h-25em {
  max-width: 25em;
  max-height: 25em;
}

.tablet-max-w-and-h-30em {
  max-width: 30em;
  max-height: 30em;
}

.tablet-max-w-and-h-35em {
  max-width: 35em;
  max-height: 35em;
}

.tablet-max-w-and-h-40em {
  max-width: 40em;
  max-height: 40em;
}

.tablet-max-w-and-h-45em {
  max-width: 45em;
  max-height: 45em;
}

.tablet-max-w-and-h-50em {
  max-width: 50em;
  max-height: 50em;
}

.tablet-max-w-and-h-55em {
  max-width: 55em;
  max-height: 55em;
}

.tablet-max-w-and-h-60em {
  max-width: 60em;
  max-height: 60em;
}

.tablet-max-w-and-h-65em {
  max-width: 65em;
  max-height: 65em;
}

.tablet-max-w-and-h-70em {
  max-width: 70em;
  max-height: 70em;
}

.tablet-max-w-and-h-75em {
  max-width: 75em;
  max-height: 75em;
}

.tablet-max-w-and-h-80em {
  max-width: 80em;
  max-height: 80em;
}

.tablet-max-w-and-h-85em {
  max-width: 85em;
  max-height: 85em;
}

.tablet-max-w-and-h-90em {
  max-width: 90em;
  max-height: 90em;
}

.tablet-max-w-and-h-95em {
  max-width: 95em;
  max-height: 95em;
}

.tablet-max-w-and-h-100em {
  max-width: 100em;
  max-height: 100em;
}


/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ▄▄▄██ █████ ▄▄▄█▄▀█▀▄██ ▄▄▀██ ▄▄▄ █▄▀█▀▄██
██ ▄▄███ █████ ▄▄▄███ ████ ▄▄▀██ ███ ███ ████
██ █████ ▀▀ ██ ▀▀▀█▀▄█▄▀██ ▀▀ ██ ▀▀▀ █▀▄█▄▀██
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
*/

.tablet-flexbox,
.tablet-flex {
  display: flex;
}

/* 
  
█▀▀ █░░ █▀▀ ▀▄▀   █▀▄ █ █▀█ █▀▀ █▀▀ ▀█▀ █ █▀█ █▄░█
█▀░ █▄▄ ██▄ █░█   █▄▀ █ █▀▄ ██▄ █▄▄ ░█░ █ █▄█ █░▀█
  */
.tablet-f-d-c {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.tablet-f-d-c-r {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.tablet-f-d-r {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.tablet-f-d-r-r {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.tablet-f-d-inh,
.tablet-f-d-ini {
  -webkit-box-orient: inherit;
  -webkit-box-direction: inherit;
  -ms-flex-direction: inherit;
  flex-direction: inherit;
}

/* 
  
█▀▀ █░░ █▀▀ ▀▄▀   █░█░█ █▀█ ▄▀█ █▀█
█▀░ █▄▄ ██▄ █░█   ▀▄▀▄▀ █▀▄ █▀█ █▀▀
  */
.tablet-f-w-w {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.tablet-f-w-n-w {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.tablet-f-w-w-r {
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.tablet-f-w-inh {
   -ms-flex-wrap: inherit;
   flex-wrap: inherit;
}

/* 
  
█▀▀ █░░ █▀▀ ▀▄▀   █▄▄ ▄▀█ █▀ █ █▀
█▀░ █▄▄ ██▄ █░█   █▄█ █▀█ ▄█ █ ▄█
  */
.tablet-f-b-f-a,
.tablet-f-b-inh,
.tablet-f-b-ini,
.tablet-f-b-uns {
  flex-basis: inherit;
  -ms-flex-preferred-size: inherit;
}

.tablet-f-b-max-c {
  -webkit-flex-basis: max-content;
  -moz-flex-basis: max-content;
  -ms-flex-preferred-size: max-content;
  flex-basis: max-content;
}

.tablet-f-b-min-c {
  -webkit-flex-basis: min-content;
  -moz-flex-basis: min-content;
  -ms-flex-preferred-size: min-content;
  flex-basis: min-content;
}

.tablet-f-b-fill {
  -webkit-flex-basis: fill-available;
  -moz-flex-basis: fill-available;
  -ms-flex-preferred-size: fill-available;
  flex-basis: fill-available;
}

.tablet-f-b-f-c {
  -webkit-flex-basis: fit-content;
  -moz-flex-basis: fit-content;
  -ms-flex-preferred-size: fit-content;
  flex-basis: fit-content;
}

.tablet-f-b-c {
  flex-basis: content;
  -ms-flex-preferred-size: content;
}

/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
█████ ██ ██ ██ ▄▄▄ █▄▄ ▄▄█▄ ▄██ ▄▄▄██ ███ ██
█████ ██ ██ ██▄▄▄▀▀███ ████ ███ ▄▄███▄▀▀▀▄██
██ ▀▀ ██▄▀▀▄██ ▀▀▀ ███ ███▀ ▀██ ███████ ████
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀

*/
/*

░░█ █░█ █▀ ▀█▀ █ █▀▀ █▄█   █▀▀ █▀█ █▄░█ ▀█▀ █▀▀ █▄░█ ▀█▀
█▄█ █▄█ ▄█ ░█░ █ █▀░ ░█░   █▄▄ █▄█ █░▀█ ░█░ ██▄ █░▀█ ░█░
*/

.tablet-j-c-s-b {
  justify-content: space-between;
}

.tablet-j-c-s-a {
  justify-content: space-around;
}

.tablet-j-c-f-s {
  justify-content: flex-start;
}

.tablet-j-c-f-e {
  justify-content: flex-end;
}

.tablet-j-c-c {
  justify-content: center;
}

.tablet-j-c-s {
  justify-content: start;
}

.tablet-j-c-e {
  justify-content: end;
}

/* 
  
░░█ █░█ █▀ ▀█▀ █ █▀▀ █▄█   █ ▀█▀ █▀▀ █▀▄▀█ █▀
█▄█ █▄█ ▄█ ░█░ █ █▀░ ░█░   █ ░█░ ██▄ █░▀░█ ▄█
  */
.tablet-j-i-f-e {
  justify-items: flex-end;
}

/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
█ ▄▄▀██ ████▄ ▄██ ▄▄ ██ ▀██ ██
█ ▀▀ ██ █████ ███ █▀▀██ █ █ ██
█ ██ ██ ▀▀ █▀ ▀██ ▀▀▄██ ██▄ ██
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
*/

/*
▄▀█ █░░ █ █▀▀ █▄░█   █▀▀ █░░ █▀█ █▄▄ ▄▀█ █░░
█▀█ █▄▄ █ █▄█ █░▀█   █▄█ █▄▄ █▄█ █▄█ █▀█ █▄▄
*/

.tablet-centered,
.tablet-center {
  position: relative;
  margin: auto;
  float: left;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.tablet-f-l {
  float: left;
}

.tablet-f-r {
  float: right;
}


/*
▄▀█ █░░ █ █▀▀ █▄░█   █▀█ █▀▀ █░░ ▄▀█ ▀█▀ █ █░█ █▀▀
█▀█ █▄▄ █ █▄█ █░▀█   █▀▄ ██▄ █▄▄ █▀█ ░█░ █ ▀▄▀ ██▄
*/

.tablet-r-h-c {
  position: relative;
  margin: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.tablet-r-v-c {
  position: relative;
  float: left;
  margin: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.tablet-r-v-b {
  position: relative;
  float: left;
  margin: auto;
  top: 100%;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

/*
▄▀█ █░░ █ █▀▀ █▄░█   ▄▀█ █▄▄ █▀ █▀█ █░░ █░█ ▀█▀ █▀▀
█▀█ █▄▄ █ █▄█ █░▀█   █▀█ █▄█ ▄█ █▄█ █▄▄ █▄█ ░█░ ██▄
*/
.tablet-a-h-c {
  position: absolute;
  right: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tablet-a-v-c {
  position: absolute;
  margin: auto;
  top: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}

/*
  
▄▀█ █░░ █ █▀▀ █▄░█   █ ▀█▀ █▀▀ █▀▄▀█ █▀
█▀█ █▄▄ █ █▄█ █░▀█   █ ░█░ ██▄ █░▀░█ ▄█
  */
.tablet-a-i-inh {
  -webkit-box-align: inherit;
  -ms-flex-align: inherit;
  align-items: inherit;
}

.tablet-a-i-ini {
  -webkit-box-align: initial;
  -ms-flex-align: initial;
  align-items: initial;
}

.tablet-a-i-b {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.tablet-a-i-c {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.tablet-a-i-f-s {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.tablet-a-i-f-e {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.tablet-a-i-s {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.tablet-a-i-u {
  -webkit-box-align: unset;
  -ms-flex-align: unset;
  align-items: unset;
}

/*
▄▀█ █░░ █ █▀▀ █▄░█   █▀ █▀▀ █░░ █▀▀
█▀█ █▄▄ █ █▄█ █░▀█   ▄█ ██▄ █▄▄ █▀░
  */

.tablet-a-s-a {
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

.tablet-a-s-inh {
  -ms-flex-item-align: inherit;
  -ms-grid-row-align: inherit;
  align-self: inherit;
}

.tablet-a-s-ini {
  -ms-flex-item-align: initial;
  -ms-grid-row-align: initial;
  align-self: initial;
}

.tablet-a-s-b {
  -ms-flex-item-align: baseline;
  align-self: baseline;
}

.tablet-a-s-c {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.tablet-a-s-f-s {
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.tablet-a-s-f-e {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.tablet-a-s-s {
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.tablet-a-s-u {
  -ms-flex-item-align: unset;
  -ms-grid-row-align: unset;
  align-self: unset;
}

/*
▄▀█ █░░ █ █▀▀ █▄░█   █▀▀ █▀█ █▄░█ ▀█▀ █▀▀ █▄░█ ▀█▀
█▀█ █▄▄ █ █▄█ █░▀█   █▄▄ █▄█ █░▀█ ░█░ ██▄ █░▀█ ░█░
  */

.tablet-a-c-a {
  -ms-flex-line-pack: auto;
  align-content: auto;
}

.tablet-a-c-inh {
  -ms-flex-line-pack: inherit;
  align-content: inherit;
}

.tablet-a-c-ini {
  -ms-flex-line-pack: initial;
  align-content: initial;
}

.tablet-a-c-b {
  -ms-flex-line-pack: baseline;
  align-content: baseline;
}

.tablet-a-c-c {
  -ms-flex-line-pack: center;
  align-content: center;
}

.tablet-a-c-f-s {
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.tablet-a-c-f-e {
  -ms-flex-line-pack: end;
  align-content: flex-end;
}

.tablet-a-c-s {
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

.tablet-a-c-u {
  -ms-flex-line-pack: unset;
  align-content: unset;
}

/*

▄▀█ █░░ █ █▀▀ █▄░█   ▀█▀ █▀▀ ▀▄▀ ▀█▀
█▀█ █▄▄ █ █▄█ █░▀█   ░█░ ██▄ █░█ ░█░
  */
.tablet-t-a-s {
  text-align: start;
}

/* TEXT ALIGN CENTER */
.tablet-t-a-c {
  text-align: center;
}

/* TEXT ALIGN LAST CENTER */
.tablet-t-a-r-c {
  text-align-last: center;
}

/* TEXT ALIGN LEFT */
.tablet-t-a-l {
  text-align: left;
}

/* TEXT ALIGN RIGHT*/
.tablet-t-a-r {
  text-align: right;
}

/*
▄▀█ █░░ █ █▀▀ █▄░█   █░█ █▀▀ █▀█ ▀█▀ █ █▀▀ ▄▀█ █░░
█▀█ █▄▄ █ █▄█ █░▀█   ▀▄▀ ██▄ █▀▄ ░█░ █ █▄▄ █▀█ █▄▄
  */

.tablet-v-a-b {
  vertical-align: bottom !important;
}

.tablet-v-a-m {
  vertical-align: middle !important;
}

.tablet-v-a-t {
  vertical-align: top !important;
}

/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██░▄▄▄░██░▄▄▀█████░██░▄▄▄██░▄▄▀█▄▄░▄▄████░▄▄▄█▄░▄█▄▄░▄▄██
██░███░██░▄▄▀█████░██░▄▄▄██░██████░██████░▄▄███░████░████
██░▀▀▀░██░▀▀░██░▀▀░██░▀▀▀██░▀▀▄███░██████░████▀░▀███░████
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀

  */
.tablet-o-f-c {
  -o-object-fit: content;
  object-fit: content;
}

.tablet-o-f-c {
  -o-object-fit: cover;
  object-fit: cover;
}

.tablet-o-f-a {
  -o-object-fit: auto;
  object-fit: auto;
}

/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ▄▀▄ █ ▄▄▀█▄▀█▀▄█████ ████▄ ▄██ ▀██ ██ ▄▄▄██ ▄▄▄ ██
██ █ █ █ ▀▀ ███ ███▄▄██ █████ ███ █ █ ██ ▄▄▄██▄▄▄▀▀██
██ ███ █ ██ █▀▄█▄▀█████ ▀▀ █▀ ▀██ ██▄ ██ ▀▀▀██ ▀▀▀ ██
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
*/
.tablet-max-lines-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.tablet-max-lines-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.tablet-max-lines-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.tablet-max-lines-4 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.tablet-max-lines-5 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.tablet-max-lines-6 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}

.tablet-max-lines-7 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
}

.tablet-max-lines-8 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
}

.tablet-max-lines-9 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 9;
}

.tablet-max-lines-10 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
}

/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ▄▀▄ █ ▄▄▀█▄▀█▀▄█████ █████ ▄▄▄██ ▀██ ██ ▄▄ ██ ██ █▄▄ ▄▄██
██ █ █ █ ▀▀ ███ ███▄▄██ █████ ▄▄▄██ █ █ ██ █▀▀██ ▄▄ ███ ████
██ ███ █ ██ █▀▄█▄▀█████ ▀▀ ██ ▀▀▀██ ██▄ ██ ▀▀▄██ ██ ███ ████
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
*/
.tablet-max-r-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.tablet-max-r-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.tablet-max-r-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.tablet-max-r-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.tablet-max-r-5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.tablet-max-r-6 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}

.tablet-max-r-7 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
}

.tablet-max-r-8 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
}

.tablet-max-r-9 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 9;
}

.tablet-max-r-10 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
}

/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
█ ▄▄▀██ ▄▄▄ ██ ▄▄ ██ ▄▄▄██ ▄▄▀█▄▄ ▄▄████ ▄▄▀█ ▄▄▀█▄▄ ▄▄█▄ ▄██ ▄▄▄ ██
█ ▀▀ ██▄▄▄▀▀██ ▀▀ ██ ▄▄▄██ ██████ ██████ ▀▀▄█ ▀▀ ███ ████ ███ ███ ██
█ ██ ██ ▀▀▀ ██ █████ ▀▀▀██ ▀▀▄███ ██████ ██ █ ██ ███ ███▀ ▀██ ▀▀▀ ██
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀

  */
/* 
  
█▀ █▀█ █▀▀ █▀▀ █ ▄▀█ █░░   █▀█ ▄▀█ ▀█▀ █ █▀█ █▀
▄█ █▀▀ ██▄ █▄▄ █ █▀█ █▄▄   █▀▄ █▀█ ░█░ █ █▄█ ▄█
  */
.tablet-a-r-1-1,
.tablet-a-r-square {
  padding-top: 100%;
}

.tablet-a-r-golden {
  padding-top: 61.80469716%;
}

.tablet-a-r-golden-v {
  padding-top: 161.8%;
}

.tablet-a-r-1-1 {
  width: 100%;
  content: '';
  display: inline-table;
  padding-top: 100%;
}

.tablet-a-r-3-2 {
  width: 100%;
  content: '';
  display: inline-table;
  padding-top: 66.66%;
}

.tablet-a-r-4-3 {
  width: 100%;
  content: '';
  display: inline-table;
  padding-top: 75%;
}

.tablet-a-r-8-3 {
  width: 100%;
  content: '';
  display: inline-table;
  padding-top: 62.5%;
}

.tablet-a-r-16-9 {
  width: 100%;
  content: '';
  display: inline-table;
  padding-top: 56.25%;
}

.tablet-a-r {
  height: 0;
  overflow: hidden;
  position: relative;
}

.tablet-a-r-content {
  display: table;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.tablet-a-r-content>.tablet-a-r-center {
  display: table-cell;
  vertical-align: middle;
}

.tablet-a-r-2-1 {
  padding-top: 50%;
}

.tablet-a-r-1-2 {
  padding-top: 200%;
}

.tablet-a-r-3-2 {
  padding-top: 66.66666667%;
}

.tablet-a-r-2-3 {
  padding-top: 150%;
}

.tablet-a-r-4-3 {
  padding-top: 75%;
}

.tablet-a-r-3-4 {
  padding-top: 133.33333333%;
}

.tablet-a-r-5-3 {
  padding-top: 60%;
}

.tablet-a-r-3-5 {
  padding-top: 166.66666667%;
}

.tablet-a-r-5-4 {
  padding-top: 80%;
}

.tablet-a-r-4-5 {
  padding-top: 125%;
}

.tablet-a-r-16-9 {
  padding-top: 56.25%;
}

.tablet-a-r-9-16 {
  padding-top: 177.77777778%;
}

.tablet-a-r-16-10 {
  padding-top: 62.5%;
}

.tablet-a-r-10-16 {
  padding-top: 160%;
}

.tablet-a-r-21-9 {
  padding-top: 42.85714286%;
}

.tablet-a-r-9-21 {
  padding-top: 233.33333333%;
}



/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
█▄▄ ▄▄██ ▄▄▄█▄▀█▀▄█▄▄ ▄▄██
███ ████ ▄▄▄███ █████ ████
███ ████ ▀▀▀█▀▄█▄▀███ ████
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀

  */

/*
  
▀█▀ █▀▀ ▀▄▀ ▀█▀   █▀█ █▀▀ █▄░█ █▀▄ █▀▀ █▀█ █ █▄░█ █▀▀
░█░ ██▄ █░█ ░█░   █▀▄ ██▄ █░▀█ █▄▀ ██▄ █▀▄ █ █░▀█ █▄█
*/
.tablet-t-r-o-l {
  text-rendering: optimizeLegibility;
}

.tablet-t-r-o-s {
  text-rendering: optimizeSpeed;
}

.tablet-t-r-g-p {
  text-rendering: geometricPrecision;
}

/*
  
▀█▀ █▀▀ ▀▄▀ ▀█▀   █▀█ █░█ █▀▀ █▀█ █▀▀ █░░ █▀█ █░█░█
░█░ ██▄ █░█ ░█░   █▄█ ▀▄▀ ██▄ █▀▄ █▀░ █▄▄ █▄█ ▀▄▀▄▀
*/
.tablet-t-of-e {
  display: block;
  height: 0 auto;
  width: 0 auto;
  margin: 0 auto;
  line-height: 1.tablet-5;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  word-break: break-all;
}

.tablet-t-of-c {
  text-overflow: clip;
}

/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ▄▄▀██ ▄▄▄ ██ █████ ▄▄▄ ██ ▄▄▀██ ▄▄▄ ██
██ █████ ███ ██ █████ ███ ██ ▀▀▄██▄▄▄▀▀██
██ ▀▀▄██ ▀▀▀ ██ ▀▀ ██ ▀▀▀ ██ ██ ██ ▀▀▀ ██
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀

  */
/*
█▀▀ █▀█ █░░ █▀█ █▀█ ▄▄ █▀ █▀█ █▀▀ █▀▀ █ ▄▀█ █░░ █▀
█▄▄ █▄█ █▄▄ █▄█ █▀▄ ░░ ▄█ █▀▀ ██▄ █▄▄ █ █▀█ █▄▄ ▄█
*/
.tablet-c-none,
.tablet-c-n {
  color: none;
}

.tablet-c-inh,
.tablet-c-inherit {
  color: inherit;
}

/*

█▀█ █░█ █▀█ █▀▀   █▀▀ █▀█ █░░ █▀█ █▀█ █▀
█▀▀ █▄█ █▀▄ ██▄   █▄▄ █▄█ █▄▄ █▄█ █▀▄ ▄█
  */

.tablet-red {
  color: red;
}

.tablet-blue {
  color: blue;
}

.tablet-skyblue {
  color: skyblue;
}

.tablet-green {
  color: green;
}

.tablet-yellow {
  color: yellow;
}

.tablet-orange {
  color: orange;
}

.tablet-purple {
  color: purple;
}

.tablet-fuchsia {
  color: fuchsia;
}

.tablet-pink {
  color: pink;
}

.tablet-gray {
  color: gray;
}

.tablet-black {
  color: black;
}

.tablet-white {
  color: white;
}


/*
█▀▀ █▀█ █░░ █▀█ █▀█
█▄▄ █▄█ █▄▄ █▄█ █▀▄
*/
.tablet-c-white {
  color: white;
}

.tablet-c-yellow {
  color: yellow;
}

.tablet-c-blue {
  color: blue;
}

.tablet-c-skyblue {
  color: skyblue;
}

.tablet-c-red {
  color: red;
}

.tablet-c-orange {
  color: orange;
}

.tablet-c-green {
  color: green;
}

.tablet-c-fuchsia {
  color: fuchsia;
}



/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ▄▄▀█ ▄▄▀██ ▄▄▀██ █▀▄██ ▄▄ ██ ▄▄▀██ ▄▄▄ ██ ██ ██ ▀██ ██ ▄▄▀██
██ ▄▄▀█ ▀▀ ██ █████ ▄▀███ █▀▀██ ▀▀▄██ ███ ██ ██ ██ █ █ ██ ██ ██
██ ▀▀ █ ██ ██ ▀▀▄██ ██ ██ ▀▀▄██ ██ ██ ▀▀▀ ██▄▀▀▄██ ██▄ ██ ▀▀ ██
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀

  */
/*
█▀█ █░█ █▀█ █▀▀ ▄▄ █▄▄ █▀▀ ▄▄ █▀▀ █▀█ █░░ █▀█ █▀█ █▀
█▀▀ █▄█ █▀▄ ██▄ ░░ █▄█ █▄█ ░░ █▄▄ █▄█ █▄▄ █▄█ █▀▄ ▄█
*/
.tablet-bg-red {
  background-color: red;
}

.tablet-bg-blue {
  background-color: blue;
}

.tablet-bg-skyblue {
  background-color: skyblue;
}

.tablet-bg-green {
  background-color: green;
}

.tablet-bg-yellow {
  background-color: yellow;
}

.tablet-bg-orange {
  background-color: orange;
}

.tablet-bg-purple {
  background-color: purple;
}

.tablet-bg-fuchsia {
  background-color: fuchsia;
}

.tablet-bg-pink {
  background-color: pink;
}

.tablet-bg-gray {
  background-color: gray;
}

.tablet-bg-black {
  background-color: black;
}

.tablet-bg-white {
  background-color: white;
}

/*
  
█▄▄ █▀▀ ▄▄ █▀ █▀█ █▀▀ █▀▀ █ ▄▀█ █░░ █▀
█▄█ █▄█ ░░ ▄█ █▀▀ ██▄ █▄▄ █ █▀█ █▄▄ ▄█
*/

.tablet-bg-inh,
.tablet-bg-inherit {
  background: inherit;
}

.tablet-bg-none,
.tablet-bg-n {
  background: none;
}

/*
█▄▄ █▀▀ ▄▄ █▀ █ ▀█ █▀▀
█▄█ █▄█ ░░ ▄█ █ █▄ ██▄
*/

.tablet-bg-s-a {
  background-size: auto;
}

.tablet-bg-s-cov {
  background-size: cover;
}

.tablet-bg-s-con {
  background-size: contain;
}

.tablet-bg-s-ini {
  background-size: initial;
}

.tablet-bg-s-inh {
  background-size: inherit;
}


/*
█▄▄ █▀▀ ▄▄ █▀█ █▀█ █▀ █ ▀█▀ █ █▀█ █▄░█
█▄█ █▄█ ░░ █▀▀ █▄█ ▄█ █ ░█░ █ █▄█ █░▀█
*/
.tablet-bg-p-c {
  background-position: center;
  background-origin: unset;
}

.tablet-bg-p-b {
  background-position: bottom;
}

.tablet-bg-p-l {
  background-position: left;
}

.tablet-bg-p-r {
  background-position: right;
}

.tablet-bg-p-t {
  background-position: top;
}

/*
█▄▄ █▀▀ ▄▄ █▀ █▀█ █▀▀ █ ▄▀█ █░░ █▀
█▄█ █▄█ ░░ ▄█ █▄█ █▄▄ █ █▀█ █▄▄ ▄█
*/

.tablet-bg-facebook {
  background: #3b5998;
}

.tablet-bg-twitter {
  background: #00ACEE;
}

.tablet-bg-instagram {
  background: #DD2A7B;
}

.tablet-bg-gitlab {
  background: #fc6d26;
}

.tablet-bg-patreon {
  background: #FF424D;
}

.tablet-bg-youtube {
  background: #c4302b;
}

.tablet-bg-github {
  background: #24292e;
}

.tablet-bg-linkedin {
  background: #283e4a;
}

/*

█▄░█ █▀ ▄▄ █▄▄ ▄▀█ █▀▀ █▄▀ █▀▀ █▀█ █▀█ █░█ █▄░█ █▀▄ █▀
█░▀█ ▄█ ░░ █▄█ █▀█ █▄▄ █░█ █▄█ █▀▄ █▄█ █▄█ █░▀█ █▄▀ ▄█

  */
.tablet-ns-bg-white {
  background-color: #fefefe;
}

.tablet-ns-bg-black {
  background-color: #121212;
}

.tablet-ns-bg-yellow {
  background-color: #fccb18;
}

.tablet-ns-bg-blue {
  background-color: #104a8e;
}

.tablet-ns-bg-skyblue {
  background-color: #0096ff;
}

.tablet-ns-bg-red {
  background-color: #ed293e;
}

.tablet-ns-bg-pink {
  background-color: #fd528e;
}

.tablet-ns-bg-orange {
  background-color: #ff781f;
}

.tablet-ns-bg-dark-orange {
  background-color: #f43a09;
}

.tablet-ns-bg-light-pink {
  background-color: #fbe3e8;
}

.tablet-ns-bg-light-green {
  background-color: #00e645;
}

.tablet-ns-bg-fuchsia {
  background-color: #ff0096;
}

.tablet-ns-bg-violet {
  background-color: #8232f2;
}

.tablet-ns-bg-gold {
  background-color: #faa03f;
}

.tablet-ns-bg-olive {
  background-color: #ffb766;
}

/*
  

█▄░█ █▀ ▄▄ █▄▄ █▀▀ ▄▄ █▀▀ █▀█ ▄▀█ █▄█
█░▀█ ▄█ ░░ █▄█ █▄█ ░░ █▄█ █▀▄ █▀█ ░█░
  */



.tablet-ns-bg-gray-1 {
  background-color: #FFFFFF;
}

.tablet-ns-bg-gray-2 {
  background-color: #eeeeee;
}

.tablet-ns-bg-gray-3 {
  background-color: #dedede;
}

.tablet-ns-bg-gray-4 {
  background-color: #c2c2c2;
}

.tablet-ns-bg-gray-5 {
  background-color: #7e7e7e;
}

.tablet-ns-bg-gray-6 {
  background-color: #666666;
}

.tablet-ns-bg-gray-7 {
  background-color: #4a4a4a;
}

.tablet-ns-bg-gray-8 {
  background-color: #202020;
}

.tablet-ns-bg-gray-9 {
  background-color: #181818;
}

.tablet-ns-bg-gray-10 {
  background-color: #121212;
}

/*


█▄░█ █▀ ▄▄ █▄▄ █▀▀ ▄▄ █▄▄ █░░ ▄▀█ █▀▀ █▄▀
█░▀█ ▄█ ░░ █▄█ █▄█ ░░ █▄█ █▄▄ █▀█ █▄▄ █░█
  */
.tablet-bg-black-node-o-10 {
  background-color: rgba(18, 18, 18, 0.1);
}

.tablet-bg-black-node-o-20 {
  background-color: rgba(18, 18, 18, 0.2);
}

.tablet-bg-black-node-o-30 {
  background-color: rgba(18, 18, 18, 0.3);
}

.tablet-bg-black-node-o-40 {
  background-color: rgba(18, 18, 18, 0.4);
}

.tablet-bg-black-node-o-50 {
  background-color: rgba(18, 18, 18, 0.5);
}

.tablet-bg-black-node-o-60 {
  background-color: rgba(18, 18, 18, 0.6);
}

.tablet-bg-black-node-o-70 {
  background-color: rgba(18, 18, 18, 0.7);
}

.tablet-bg-black-node-o-80 {
  background-color: rgba(18, 18, 18, 0.8);
}

.tablet-bg-black-node-o-90 {
  background-color: rgba(18, 18, 18, 0.9);
}

.tablet-bg-black-node-o-100 {
  background-color: rgba(18, 18, 18, 1);
}

/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ▄▄▄█▄ ▄██ █████ █████
██ ▄▄███ ███ █████ █████
██ ████▀ ▀██ ▀▀ ██ ▀▀ ██
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀

*/

/*
█▀█ █░█ █▀█ █▀▀ ▄▄ █▀▀ █ █░░ █░░ ▄▄ █▀▀ █▀█ █░░ █▀█ █▀█ █▀
█▀▀ █▄█ █▀▄ ██▄ ░░ █▀░ █ █▄▄ █▄▄ ░░ █▄▄ █▄█ █▄▄ █▄█ █▀▄ ▄█
*/
.tablet-fill-red {
  fill: red;
}

.tablet-fill-blue {
  fill: blue;
}

.tablet-fill-skyblue {
  fill: skyblue;
}

.tablet-fill-green {
  fill: green;
}

.tablet-fill-yellow {
  fill: yellow;
}

.tablet-fill-orange {
  fill: orange;
}

.tablet-fill-purple {
  fill: purple;
}

.tablet-fill-fuchsia {
  fill: fuchsia;
}

.tablet-fill-pink {
  fill: pink;
}

.tablet-fill-gray {
  fill: gray;
}

.tablet-fill-black {
  fill: black;
}

.tablet-fill-white {
  fill: white;
}

/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ▄▄▄ ██ ▄▄ █ ▄▄▀██ ▄▄▀█▄ ▄█▄▄ ▄▄██ ███ ██
██ ███ ██ ▀▀ █ ▀▀ ██ █████ ████ ████▄▀▀▀▄██
██ ▀▀▀ ██ ████ ██ ██ ▀▀▄█▀ ▀███ ██████ ████
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀

  */
/*

█▀█ █▀█ ▄▄ █▀ █▀█ █▀▀ █▀▀ █ ▄▀█ █░░ █▀
█▄█ █▀▀ ░░ ▄█ █▀▀ ██▄ █▄▄ █ █▀█ █▄▄ ▄█
*/

.tablet-o-inh,
.tablet-o-i {
  padding: inherit;
}

.tablet-o-none,
.tablet-o-n {
  opacity: 0;
}

/*
█▀█ █▀█ ▄▀█ █▀▀ █ ▀█▀ █▄█
█▄█ █▀▀ █▀█ █▄▄ █ ░█░ ░█░
*/

.tablet-o-0 {
  opacity: 0%;
}

.tablet-o-5 {
  opacity: 5%;
}

.tablet-o-10 {
  opacity: 10%;
}

.tablet-o-15 {
  opacity: 15%;
}

.tablet-o-20 {
  opacity: 20%;
}

.tablet-o-25 {
  opacity: 25%;
}

.tablet-o-30 {
  opacity: 30%;
}

.tablet-o-35 {
  opacity: 35%;
}

.tablet-o-40 {
  opacity: 40%;
}

.tablet-o-45 {
  opacity: 45%;
}

.tablet-o-50 {
  opacity: 50%;
}

.tablet-o-55 {
  opacity: 55%;
}

.tablet-o-60 {
  opacity: 60%;
}

.tablet-o-65 {
  opacity: 65%;
}

.tablet-o-70 {
  opacity: 70%;
}

.tablet-o-75 {
  opacity: 75%;
}

.tablet-o-80 {
  opacity: 80%;
}

.tablet-o-85 {
  opacity: 85%;
}

.tablet-o-90 {
  opacity: 90%;
}

.tablet-o-95 {
  opacity: 95%;
}

.tablet-o-100 {
  opacity: 100%;
}

/*
  
█▄▄ █▀▀ ▄▄ █▀█ █▀█ ▄▀█ █▀▀ █ ▀█▀ █▄█
█▄█ █▄█ ░░ █▄█ █▀▀ █▀█ █▄▄ █ ░█░ ░█░
  */


.tablet-bg-o-0 {
  background-color: rgba(0, 0, 0, 0);
}

.tablet-bg-o-5 {
  background-color: rgba(0, 0, 0, 0.05);
}

.tablet-bg-o-10 {
  background-color: rgba(0, 0, 0, 0.1);
}

.tablet-bg-o-15 {
  background-color: rgba(0, 0, 0, 0.15);
}

.tablet-bg-o-20 {
  background-color: rgba(0, 0, 0, 0.2);
}

.tablet-bg-o-25 {
  background-color: rgba(0, 0, 0, 0.25);
}

.tablet-bg-o-30 {
  background-color: rgba(0, 0, 0, 0.3);
}

.tablet-bg-o-35 {
  background-color: rgba(0, 0, 0, 0.35);
}

.tablet-bg-o-40 {
  background-color: rgba(0, 0, 0, 0.4);
}

.tablet-bg-o-45 {
  background-color: rgba(0, 0, 0, 0.45);
}

.tablet-bg-o-50 {
  background-color: rgba(0, 0, 0, 0.5);
}

.tablet-bg-o-55 {
  background-color: rgba(0, 0, 0, 0.55);
}

.tablet-bg-o-60 {
  background-color: rgba(0, 0, 0, 0.6);
}

.tablet-bg-o-65 {
  background-color: rgba(0, 0, 0, 0.65);
}

.tablet-bg-o-70 {
  background-color: rgba(0, 0, 0, 0.7);
}

.tablet-bg-o-75 {
  background-color: rgba(0, 0, 0, 0.75);
}

.tablet-bg-o-80 {
  background-color: rgba(0, 0, 0, 0.8);
}

.tablet-bg-o-85 {
  background-color: rgba(0, 0, 0, 0.85);
}

.tablet-bg-o-90 {
  background-color: rgba(0, 0, 0, 0.9);
}

.tablet-bg-o-95 {
  background-color: rgba(0, 0, 0, 0.95);
}

.tablet-bg-o-100 {
  background-color: black;
}

/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ▄▄▄ ████▄ ▄██ ▀██ ██ ▄▄▀██ ▄▄▄█▄▀█▀▄██
██▀▀▀▄▄█▄▄██ ███ █ █ ██ ██ ██ ▄▄▄███ ████
██ ▀▀▀ ████▀ ▀██ ██▄ ██ ▀▀ ██ ▀▀▀█▀▄█▄▀██
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀

*/
/*

▀█ ▄▄ █ █▄░█ █▀▄ █▀▀ ▀▄▀ ▄▄ █▀ █▀█ █▀▀ █▀▀ █ ▄▀█ █░░ █▀
█▄ ░░ █ █░▀█ █▄▀ ██▄ █░█ ░░ ▄█ █▀▀ ██▄ █▄▄ █ █▀█ █▄▄ ▄█
*/
.tablet-z-i-a,
.tablet-z-index-auto,
.tablet-z-i-auto {
  z-index: auto;
}

.tablet-z-i-i,
.tablet-z-index-inherit,
.tablet-z-i-inh {
  z-index: inherit;
}

/*
  
▀█ ▄▄ █ █▄░█ █▀▄ █▀▀ ▀▄▀
█▄ ░░ █ █░▀█ █▄▀ ██▄ █░█
  */


.tablet-z-i--5 {
  z-index: -5;
}

.tablet-z-i--4 {
  z-index: -4;
}

.tablet-z-i--3 {
  z-index: -3;
}

.tablet-z-i--2 {
  z-index: -2;
}

.tablet-z-i--1 {
  z-index: -1;
}

.tablet-z-i-0 {
  z-index: 0;
}

.tablet-z-i-1 {
  z-index: 1;
}

.tablet-z-i-2 {
  z-index: 2;
}

.tablet-z-i-3 {
  z-index: 3;
}

.tablet-z-i-4 {
  z-index: 4;
}

.tablet-z-i-5 {
  z-index: 5;
}

.tablet-z-i-99 {
  z-index: 99;
}

/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ▄▄▀█▄ ▄██ ▄▄▄ ██ ▄▄ ██ ████ ▄▄▀██ ███ ██
██ ██ ██ ███▄▄▄▀▀██ ▀▀ ██ ████ ▀▀ ██▄▀▀▀▄██
██ ▀▀ █▀ ▀██ ▀▀▀ ██ █████ ▀▀ █ ██ ████ ████
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
  
  */
/*
  
█▀▄ █ █▀ █▀█ █░░ ▄▀█ █▄█ ▄▄ █▀ █▀█ █▀▀ █▀▀ █ ▄▀█ █░░ █▀
█▄▀ █ ▄█ █▀▀ █▄▄ █▀█ ░█░ ░░ ▄█ █▀▀ ██▄ █▄▄ █ █▀█ █▄▄ ▄█
*/
.tablet-d-inh,
.tablet-d-inherit {
  display: inherit;
}

.tablet-d-n,
.tablet-d-none {
  display: none !important;
}

.tablet-d-h,
.tablet-d-hidden {
  display: hidden;
}

.tablet-d-i-flow {
  display: flow-root;
}

.tablet-d-v-h:hover {
  display: visible;
}

/* 
  
█▀▄ █ █▀ █▀█ █░░ ▄▀█ █▄█ ▄▄ █▄▄ █░░ █▀█ █▀▀ █▄▀
█▄▀ █ ▄█ █▀▀ █▄▄ █▀█ ░█░ ░░ █▄█ █▄▄ █▄█ █▄▄ █░█
  */
.tablet-d-b {
  display: block;
}

.tablet-d-i-b {
  display: inline-block;
}

/* 
  
█▀▄ █ █▀ █▀█ █░░ ▄▀█ █▄█ ▄▄ █▀▀ █▀█ █ █▀▄
█▄▀ █ ▄█ █▀▀ █▄▄ █▀█ ░█░ ░░ █▄█ █▀▄ █ █▄▀
  */
.tablet-d-g {
  display: -ms-grid;
  display: grid;
}

/*
  
█▀▄ █ █▀ █▀█ █░░ ▄▀█ █▄█ ▄▄ █▀▀ █░░ █▀▀ ▀▄▀
█▄▀ █ ▄█ █▀▀ █▄▄ █▀█ ░█░ ░░ █▀░ █▄▄ ██▄ █░█
  */
.tablet-d-f {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.tablet-d-i-f {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  clear: both;
}

/* 
  
█▀▄ █ █▀ █▀█ █░░ ▄▀█ █▄█ ▄▄ ▀█▀ ▄▀█ █▄▄ █░░ █▀▀
█▄▀ █ ▄█ █▀▀ █▄▄ █▀█ ░█░ ░░ ░█░ █▀█ █▄█ █▄▄ ██▄
  */
.tablet-d-t {
  display: table;
}

.tablet-d-i-t {
  display: inline-table;
}

.tablet-d-t-c {
  display: table-cell;
}

.tablet-d-t-col {
  display: table-column;
}

/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ▄▄ ██ ▄▄▄ ██ ▄▄▄ █▄ ▄█▄▄ ▄▄█▄ ▄██ ▄▄▄ ██ ▀██ ██
██ ▀▀ ██ ███ ██▄▄▄▀▀██ ████ ████ ███ ███ ██ █ █ ██
██ █████ ▀▀▀ ██ ▀▀▀ █▀ ▀███ ███▀ ▀██ ▀▀▀ ██ ██▄ ██
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀

*/
.tablet-pr {
  position: relative;
}

.tablet-pa {
  position: absolute;
}

.tablet-pf {
  position: fixed;
}

.tablet-ps {
  position: static;
}

.tablet-pst {
  position: -webkit-sticky;
  position: sticky;
}

/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ▄▄▄ ██ ███ ██ ▄▄▄██ ▄▄▀██ ▄▄▄██ █████ ▄▄▄ ██ ███ ██
██ ███ ███ █ ███ ▄▄▄██ ▀▀▄██ ▄▄███ █████ ███ ██ █ █ ██
██ ▀▀▀ ███▄▀▄███ ▀▀▀██ ██ ██ █████ ▀▀ ██ ▀▀▀ ██▄▀▄▀▄██
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀

  */
/*
  
█▀█ █░█ █▀▀ █▀█ █▀▀ █░░ █▀█ █░█░█ ▄▄ █▀ █▀█ █▀▀ █▀▀ █ ▄▀█ █░░ █▀
█▄█ ▀▄▀ ██▄ █▀▄ █▀░ █▄▄ █▄█ ▀▄▀▄▀ ░░ ▄█ █▀▀ ██▄ █▄▄ █ █▀█ █▄▄ ▄█
*/

.tablet-of-i,
.tablet-of-inherit {
  overflow: inherit;
}

.tablet-of-a,
.tablet-of-auto {
  overflow: auto;
}

.tablet-of-s,
.tablet-of-scroll {
  overflow: scroll;
}

.tablet-of-h,
.tablet-of-hidden {
  overflow: hidden;
}

.tablet-of-n,
.tablet-of-none {
  overflow: none;
}

.tablet-of-v,
.tablet-of-visible {
  overflow: visible;
}

/*

█▀█ █░█ █▀▀ █▀█ █▀▀ █░░ █▀█ █░█░█ ▄▄ █▄█ ▄▄ █░█ █▀▀ █▀█ ▀█▀ █ █▀▀ ▄▀█ █░░
█▄█ ▀▄▀ ██▄ █▀▄ █▀░ █▄▄ █▄█ ▀▄▀▄▀ ░░ ░█░ ░░ ▀▄▀ ██▄ █▀▄ ░█░ █ █▄▄ █▀█ █▄▄
*/

.tablet-of-y-i,
.tablet-of-y-inherit {
  overflow-y: inherit;
}

.tablet-of-y-a,
.tablet-of-y-auto {
  overflow-y: auto;
}

.tablet-of-y-s,
.tablet-of-y-scroll {
  overflow-y: scroll;
}

.tablet-of-y-h,
.tablet-of-y-hidden {
  overflow-y: hidden;
}

.tablet-of-y-n,
.tablet-of-y-none {
  overflow-y: none;
}

.tablet-of-y-v,
.tablet-of-y-visible {
  overflow-y: visible;
}

/*

█▀█ █░█ █▀▀ █▀█ █▀▀ █░░ █▀█ █░█░█ ▄▄ ▀▄▀ ▄▄ █░█ █▀█ █▀█ █ ▀█ █▀█ █▄░█ ▄▀█ █░░
█▄█ ▀▄▀ ██▄ █▀▄ █▀░ █▄▄ █▄█ ▀▄▀▄▀ ░░ █░█ ░░ █▀█ █▄█ █▀▄ █ █▄ █▄█ █░▀█ █▀█ █▄▄

  */


.tablet-of-x-i,
.tablet-of-x-inherit {
  overflow-x: inherit;
}

.tablet-of-x-a,
.tablet-of-x-auto {
  overflow-x: auto;
}

.tablet-of-x-s,
.tablet-of-x-scroll {
  overflow-x: scroll;
}

.tablet-of-x-h,
.tablet-of-x-hidden {
  overflow-x: hidden;
}

.tablet-of-x-n,
.tablet-of-x-none {
  overflow-x: none;
}

.tablet-of-x-v,
.tablet-of-x-visible {
  overflow-x: visible;
}

/*

█▀█ █░█ █▀▀ █▀█ █▀▀ █░░ █▀█ █░█░█ ▄▄ ▀▄▀ ▄▄ █▀ █▀█ █▀▀ █▀▀ █ ▄▀█ █░░ █▀
█▄█ ▀▄▀ ██▄ █▀▄ █▀░ █▄▄ █▄█ ▀▄▀▄▀ ░░ █░█ ░░ ▄█ █▀▀ ██▄ █▄▄ █ █▀█ █▄▄ ▄█
*/
.tablet-of-x-hover {
  overflow-x: hidden;
}

.tablet-of-x-hover:hover {
  overflow-x: scroll;
  cursor: pointer;
}

/*

█▀█ █░█ █▀▀ █▀█ █▀▀ █░░ █▀█ █░█░█ ▄▄ █▄█ ▄▄ █▀ █▀█ █▀▀ █▀▀ █ ▄▀█ █░░ █▀
█▄█ ▀▄▀ ██▄ █▀▄ █▀░ █▄▄ █▄█ ▀▄▀▄▀ ░░ ░█░ ░░ ▄█ █▀▀ ██▄ █▄▄ █ █▀█ █▄▄ ▄█
*/
.tablet-of-x-auto-hover {
  overflow-x: hidden;
}

.tablet-of-x-auto-hover:hover {
  overflow-x: scroll;
}

/*

▀█▀ █▀▀ ▀▄▀ ▀█▀ ▄▄ █▀█ █░█ █▀▀ █▀█ █▀▀ █░░ █▀█ █░█░█
░█░ ██▄ █░█ ░█░ ░░ █▄█ ▀▄▀ ██▄ █▀▄ █▀░ █▄▄ █▄█ ▀▄▀▄▀
*/


.tablet-t-of-e,
.tablet-t-of-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tablet-t-of-c,
.tablet-t.tablet-of-clip {
  text-overflow: clip;
  white-space: nowrap;
}

/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ▄▄ ██ ▄▄▄ ██ ▄▄▄ █▄ ▄█▄▄ ▄▄█▄ ▄██ ▄▄▄ ██ ▀██ ██ ▄▄▄ ██
██ ▀▀ ██ ███ ██▄▄▄▀▀██ ████ ████ ███ ███ ██ █ █ ██▄▄▄▀▀██
██ █████ ▀▀▀ ██ ▀▀▀ █▀ ▀███ ███▀ ▀██ ▀▀▀ ██ ██▄ ██ ▀▀▀ ██
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀  
  */

/*
  
█▀█ █▀█ █▀ █ ▀█▀ █ █▀█ █▄░█ █▀ ▄▄ █▀ █▀█ █▀▀ █▀▀ █ ▄▀█ █░░ █▀
█▀▀ █▄█ ▄█ █ ░█░ █ █▄█ █░▀█ ▄█ ░░ ▄█ █▀▀ ██▄ █▄▄ █ █▀█ █▄▄ ▄█
*/
.tablet-p-inh,
.tablet-p-i {
  position: inherit;
}

.tablet-p-n,
.tablet-p-none {
  padding: 0;
}

.tablet-t-a,
.tablet-top-a,
.tablet-top-auto {
  top: auto;
}

.tablet-r-a,
.tablet-right-a,
.tablet-right-auto {
  right: auto;
}

.tablet-b-a,
.tablet-bottom-a,
.tablet-bottom-auto {
  bottom: auto;
}

.tablet-r-a,
.tablet-left-a,
.tablet-left-auto {
  left: auto;
}

/*
█▀█ █▀█ █▀ █ ▀█▀ █ █▀█ █▄░█ ▄▄ ▀█▀ █▀█ █▀█
█▀▀ █▄█ ▄█ █ ░█░ █ █▄█ █░▀█ ░░ ░█░ █▄█ █▀▀
*/


.tablet-t-0px {
  top: 0px;
}

.tablet-t-1px {
  top: 1px;
}

.tablet-t-2px {
  top: 2px;
}

.tablet-t-3px {
  top: 3px;
}

.tablet-t-4px {
  top: 4px;
}

.tablet-t-5px {
  top: 5px;
}

.tablet-t-6px {
  top: 6px;
}

.tablet-t-7px {
  top: 7px;
}

.tablet-t-8px {
  top: 8px;
}

.tablet-t-9px {
  top: 9px;
}

.tablet-t-10px {
  top: 10px;
}

.tablet-t-20px {
  top: 20px;
}

.tablet-t-30px {
  top: 30px;
}

.tablet-t-40px {
  top: 40px;
}

.tablet-t-50px {
  top: 50px;
}

.tablet-t-60px {
  top: 60px;
}

.tablet-t-70px {
  top: 70px;
}

.tablet-t-80px {
  top: 80px;
}

.tablet-t-90px {
  top: 90px;
}

.tablet-t-100px {
  top: 100px;
}

/*
  
█▀█ █▀█ █▀ █ ▀█▀ █ █▀█ █▄░█ ▄▄ █░░ █▀▀ █▀▀ ▀█▀
█▀▀ █▄█ ▄█ █ ░█░ █ █▄█ █░▀█ ░░ █▄▄ ██▄ █▀░ ░█░
*/

.tablet-l-0px {
  left: 0px;
}

.tablet-l-1px {
  left: 1px;
}

.tablet-l-2px {
  left: 2px;
}

.tablet-l-3px {
  left: 3px;
}

.tablet-l-4px {
  left: 4px;
}

.tablet-l-5px {
  left: 5px;
}

.tablet-l-6px {
  left: 6px;
}

.tablet-l-7px {
  left: 7px;
}

.tablet-l-8px {
  left: 8px;
}

.tablet-l-9px {
  left: 9px;
}

.tablet-l-10px {
  left: 10px;
}

.tablet-l-20px {
  left: 20px;
}

.tablet-l-30px {
  left: 30px;
}

.tablet-l-40px {
  left: 40px;
}

.tablet-l-50px {
  left: 50px;
}

.tablet-l-60px {
  left: 60px;
}

.tablet-l-70px {
  left: 70px;
}

.tablet-l-80px {
  left: 80px;
}

.tablet-l-90px {
  left: 90px;
}

.tablet-l-100px {
  left: 100px;
}


/*
█▀█ █▀█ █▀ █ ▀█▀ █ █▀█ █▄░█ ▄▄ █▀█ █ █▀▀ █░█ ▀█▀
█▀▀ █▄█ ▄█ █ ░█░ █ █▄█ █░▀█ ░░ █▀▄ █ █▄█ █▀█ ░█░
*/


.tablet-r-0px {
  right: 0px;
}

.tablet-r-1px {
  right: 1px;
}

.tablet-r-2px {
  right: 2px;
}

.tablet-r-3px {
  right: 3px;
}

.tablet-r-4px {
  right: 4px;
}

.tablet-r-5px {
  right: 5px;
}

.tablet-r-6px {
  right: 6px;
}

.tablet-r-7px {
  right: 7px;
}

.tablet-r-8px {
  right: 8px;
}

.tablet-r-9px {
  right: 9px;
}

.tablet-r-10px {
  right: 10px;
}

.tablet-r-20px {
  right: 20px;
}

.tablet-r-30px {
  right: 30px;
}

.tablet-r-40px {
  right: 40px;
}

.tablet-r-50px {
  right: 50px;
}

.tablet-r-60px {
  right: 60px;
}

.tablet-r-70px {
  right: 70px;
}

.tablet-r-80px {
  right: 80px;
}

.tablet-r-90px {
  right: 90px;
}

.tablet-r-100px {
  right: 100px;
}

/*  
█▀█ █▀█ █▀ █ ▀█▀ █ █▀█ █▄░█ ▄▄ █▄▄ █▀█ ▀█▀ ▀█▀ █▀█ █▀▄▀█
█▀▀ █▄█ ▄█ █ ░█░ █ █▄█ █░▀█ ░░ █▄█ █▄█ ░█░ ░█░ █▄█ █░▀░█
*/

.tablet-b-0px {
  bottom: 0px;
}

.tablet-b-1px {
  bottom: 1px;
}

.tablet-b-2px {
  bottom: 2px;
}

.tablet-b-3px {
  bottom: 3px;
}

.tablet-b-4px {
  bottom: 4px;
}

.tablet-b-5px {
  bottom: 5px;
}

.tablet-b-6px {
  bottom: 6px;
}

.tablet-b-7px {
  bottom: 7px;
}

.tablet-b-8px {
  bottom: 8px;
}

.tablet-b-9px {
  bottom: 9px;
}

.tablet-b-10px {
  bottom: 10px;
}

.tablet-b-20px {
  bottom: 20px;
}

.tablet-b-30px {
  bottom: 30px;
}

.tablet-b-40px {
  bottom: 40px;
}

.tablet-b-50px {
  bottom: 50px;
}

.tablet-b-60px {
  bottom: 60px;
}

.tablet-b-70px {
  bottom: 70px;
}

.tablet-b-80px {
  bottom: 80px;
}

.tablet-b-90px {
  bottom: 90px;
}

.tablet-b-100px {
  bottom: 100px;
}

/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ▄▀▄ █ ▄▄▀██ ▄▄▀██ ▄▄ █▄ ▄██ ▀██ ██
██ █ █ █ ▀▀ ██ ▀▀▄██ █▀▀██ ███ █ █ ██
██ ███ █ ██ ██ ██ ██ ▀▀▄█▀ ▀██ ██▄ ██
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀

  */
/*
  
█▀▄▀█ ▄▀█ █▀█ █▀▀ █ █▄░█ ▄▄ █▀ █▀█ █▀▀ █▀▀ █ ▄▀█ █░░ █▀
█░▀░█ █▀█ █▀▄ █▄█ █ █░▀█ ░░ ▄█ █▀▀ ██▄ █▄▄ █ █▀█ █▄▄ ▄█
*/

.tablet-m-a,
.tablet-m-auto {
  margin: auto;
}

.tablet-m-inh,
.tablet-m-inherit {
  margin: inherit;
}

/*
█▀▄▀█ ▄▀█ █▀█ █▀▀ █ █▄░█
█░▀░█ █▀█ █▀▄ █▄█ █ █░▀█
*/
.tablet-m-0px {
  margin: 0px;
}

.tablet-m-1px {
  margin: 1px;
}

.tablet-m-2px {
  margin: 2px;
}

.tablet-m-3px {
  margin: 3px;
}

.tablet-m-4px {
  margin: 4px;
}

.tablet-m-5px {
  margin: 5px;
}

.tablet-m-6px {
  margin: 6px;
}

.tablet-m-7px {
  margin: 7px;
}

.tablet-m-8px {
  margin: 8px;
}

.tablet-m-9px {
  margin: 9px;
}

.tablet-m-10px {
  margin: 10px;
}

.tablet-m-20px {
  margin: 20px;
}

.tablet-m-30px {
  margin: 30px;
}

.tablet-m-40px {
  margin: 40px;
}

.tablet-m-50px {
  margin: 50px;
}

.tablet-m-60px {
  margin: 60px;
}

.tablet-m-70px {
  margin: 70px;
}

.tablet-m-80px {
  margin: 80px;
}

.tablet-m-90px {
  margin: 90px;
}

.tablet-m-100px {
  margin: 100px;
}

/*
  
█▀▄▀█ ▄▀█ █▀█ █▀▀ █ █▄░█ ▄▄ ▀█▀ █▀█ █▀█
█░▀░█ █▀█ █▀▄ █▄█ █ █░▀█ ░░ ░█░ █▄█ █▀▀
*/
.tablet-m-t-0px {
  margin-top: 0px;
}

.tablet-m-t-1px {
  margin-top: 1px;
}

.tablet-m-t-2px {
  margin-top: 2px;
}

.tablet-m-t-3px {
  margin-top: 3px;
}

.tablet-m-t-4px {
  margin-top: 4px;
}

.tablet-m-t-5px {
  margin-top: 5px;
}

.tablet-m-t-6px {
  margin-top: 6px;
}

.tablet-m-t-7px {
  margin-top: 7px;
}

.tablet-m-t-8px {
  margin-top: 8px;
}

.tablet-m-t-9px {
  margin-top: 9px;
}

.tablet-m-t-10px {
  margin-top: 10px;
}

.tablet-m-t-20px {
  margin-top: 20px;
}

.tablet-m-t-30px {
  margin-top: 30px;
}

.tablet-m-t-40px {
  margin-top: 40px;
}

.tablet-m-t-50px {
  margin-top: 50px;
}

.tablet-m-t-60px {
  margin-top: 60px;
}

.tablet-m-t-70px {
  margin-top: 70px;
}

.tablet-m-t-80px {
  margin-top: 80px;
}

.tablet-m-t-90px {
  margin-top: 90px;
}

.tablet-m-t-100px {
  margin-top: 100px;
}

/*
█▀▄▀█ ▄▀█ █▀█ █▀▀ █ █▄░█ ▄▄ █▀█ █ █▀▀ █░█ ▀█▀
█░▀░█ █▀█ █▀▄ █▄█ █ █░▀█ ░░ █▀▄ █ █▄█ █▀█ ░█░
  */

.tablet-m-r-0px {
  margin-right: 0px;
}

.tablet-m-r-1px {
  margin-right: 1px;
}

.tablet-m-r-2px {
  margin-right: 2px;
}

.tablet-m-r-3px {
  margin-right: 3px;
}

.tablet-m-r-4px {
  margin-right: 4px;
}

.tablet-m-r-5px {
  margin-right: 5px;
}

.tablet-m-r-6px {
  margin-right: 6px;
}

.tablet-m-r-7px {
  margin-right: 7px;
}

.tablet-m-r-8px {
  margin-right: 8px;
}

.tablet-m-r-9px {
  margin-right: 9px;
}

.tablet-m-r-10px {
  margin-right: 10px;
}

.tablet-m-r-20px {
  margin-right: 20px;
}

.tablet-m-r-30px {
  margin-right: 30px;
}

.tablet-m-r-40px {
  margin-right: 40px;
}

.tablet-m-r-50px {
  margin-right: 50px;
}

.tablet-m-r-60px {
  margin-right: 60px;
}

.tablet-m-r-70px {
  margin-right: 70px;
}

.tablet-m-r-80px {
  margin-right: 80px;
}

.tablet-m-r-90px {
  margin-right: 90px;
}

.tablet-m-r-100px {
  margin-right: 100px;
}

/*
█▀▄▀█ ▄▀█ █▀█ █▀▀ █ █▄░█ ▄▄ █▄▄ █▀█ ▀█▀ ▀█▀ █▀█ █▀▄▀█
█░▀░█ █▀█ █▀▄ █▄█ █ █░▀█ ░░ █▄█ █▄█ ░█░ ░█░ █▄█ █░▀░█
*/
.tablet-m-b-0px {
  margin-bottom: 0px;
}

.tablet-m-b-1px {
  margin-bottom: 1px;
}

.tablet-m-b-2px {
  margin-bottom: 2px;
}

.tablet-m-b-3px {
  margin-bottom: 3px;
}

.tablet-m-b-4px {
  margin-bottom: 4px;
}

.tablet-m-b-5px {
  margin-bottom: 5px;
}

.tablet-m-b-6px {
  margin-bottom: 6px;
}

.tablet-m-b-7px {
  margin-bottom: 7px;
}

.tablet-m-b-8px {
  margin-bottom: 8px;
}

.tablet-m-b-9px {
  margin-bottom: 9px;
}

.tablet-m-b-10px {
  margin-bottom: 10px;
}

.tablet-m-b-20px {
  margin-bottom: 20px;
}

.tablet-m-b-30px {
  margin-bottom: 30px;
}

.tablet-m-b-40px {
  margin-bottom: 40px;
}

.tablet-m-b-50px {
  margin-bottom: 50px;
}

.tablet-m-b-60px {
  margin-bottom: 60px;
}

.tablet-m-b-70px {
  margin-bottom: 70px;
}

.tablet-m-b-80px {
  margin-bottom: 80px;
}

.tablet-m-b-90px {
  margin-bottom: 90px;
}

.tablet-m-b-100px {
  margin-bottom: 100px;
}

/*
█▀▄▀█ ▄▀█ █▀█ █▀▀ █ █▄░█ ▄▄ █░░ █▀▀ █▀▀ ▀█▀
█░▀░█ █▀█ █▀▄ █▄█ █ █░▀█ ░░ █▄▄ ██▄ █▀░ ░█░
  */
.tablet-m-l-0px {
  margin-left: 0px;
}

.tablet-m-l-1px {
  margin-left: 1px;
}

.tablet-m-l-2px {
  margin-left: 2px;
}

.tablet-m-l-3px {
  margin-left: 3px;
}

.tablet-m-l-4px {
  margin-left: 4px;
}

.tablet-m-l-5px {
  margin-left: 5px;
}

.tablet-m-l-6px {
  margin-left: 6px;
}

.tablet-m-l-7px {
  margin-left: 7px;
}

.tablet-m-l-8px {
  margin-left: 8px;
}

.tablet-m-l-9px {
  margin-left: 9px;
}

.tablet-m-l-10px {
  margin-left: 10px;
}

.tablet-m-l-20px {
  margin-left: 20px;
}

.tablet-m-l-30px {
  margin-left: 30px;
}

.tablet-m-l-40px {
  margin-left: 40px;
}

.tablet-m-l-50px {
  margin-left: 50px;
}

.tablet-m-l-60px {
  margin-left: 60px;
}

.tablet-m-l-70px {
  margin-left: 70px;
}

.tablet-m-l-80px {
  margin-left: 80px;
}

.tablet-m-l-90px {
  margin-left: 90px;
}

.tablet-m-l-100px {
  margin-left: 100px;
}

/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ▄▄▄██ ▄▄▄ ██ ▀██ █▄▄ ▄▄██
██ ▄▄███ ███ ██ █ █ ███ ████
██ █████ ▀▀▀ ██ ██▄ ███ ████
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀

  */

/*  
█▀▀ █▀█ █▄░█ ▀█▀ ▄▄ █▀ █▀█ █▀▀ █▀▀ █ ▄▀█ █░░ █▀
█▀░ █▄█ █░▀█ ░█░ ░░ ▄█ █▀▀ ██▄ █▄▄ █ █▀█ █▄▄ ▄█
  */
.tablet-f-s-inh {
  font-size: inherit;
}

.tablet-f-w-inh {
  font-weight: inherit;
}

.tablet-f-f-inh {
  font-family: inherit;
}

/*
█▀▀ █▀█ █▄░█ ▀█▀ ▄▄ █▀ ▀█▀ █▄█ █░░ █▀▀
█▀░ █▄█ █░▀█ ░█░ ░░ ▄█ ░█░ ░█░ █▄▄ ██▄
  */
.tablet-f-s-n {
  font-style: normal;
}

.tablet-f-s-i {
  font-style: italic;
}

.tablet-f-s-o {
  font-style: oblique;
}

.tablet-f-s-ini {
  font-style: initial;
}

.tablet-f-s-inh {
  font-style: inherit;
}

/*
█▀▀ █▀█ █▄░█ ▀█▀ ▄▄ █░█░█ █▀▀ █ █▀▀ █░█ ▀█▀
█▀░ █▄█ █░▀█ ░█░ ░░ ▀▄▀▄▀ ██▄ █ █▄█ █▀█ ░█░
*/
.tablet-f-w-n {
  font-weight: normal;
}

.tablet-f-w-m {
  font-weight: medium;
}

.tablet-f-w-bo {
  font-weight: bold;
}

.tablet-f-w-bl {
  font-weight: 900;
}

/*  
█▀▀ █▀█ █▄░█ ▀█▀ ▄▄ █▀ █ ▀█ █▀▀ ▄▄ █▀█ ▀▄▀
█▀░ █▄█ █░▀█ ░█░ ░░ ▄█ █ █▄ ██▄ ░░ █▀▀ █░█
*/

.tablet-f-s-5px {
  font-size: 5px;
}

.tablet-f-s-6px {
  font-size: 6px;
}

.tablet-f-s-7px {
  font-size: 7px;
}

.tablet-f-s-8px {
  font-size: 8px;
}

.tablet-f-s-9px {
  font-size: 9px;
}

.tablet-f-s-10px {
  font-size: 10px;
}

.tablet-f-s-11px {
  font-size: 11px;
}

.tablet-f-s-12px {
  font-size: 12px;
}

.tablet-f-s-13px {
  font-size: 13px;
}

.tablet-f-s-14px {
  font-size: 14px;
}

.tablet-f-s-15px {
  font-size: 15px;
}

.tablet-f-s-16px {
  font-size: 16px;
}

.tablet-f-s-17px {
  font-size: 17px;
}

.tablet-f-s-18px {
  font-size: 18px;
}

.tablet-f-s-19px {
  font-size: 19px;
}

.tablet-f-s-20px {
  font-size: 20px;
}

.tablet-f-s-21px {
  font-size: 21px;
}

.tablet-f-s-22px {
  font-size: 22px;
}

.tablet-f-s-23px {
  font-size: 23px;
}

.tablet-f-s-24px {
  font-size: 24px;
}

.tablet-f-s-25px {
  font-size: 25px;
}

.tablet-f-s-30px {
  font-size: 30px;
}

.tablet-f-s-35px {
  font-size: 35px;
}

.tablet-f-s-40px {
  font-size: 40px;
}

.tablet-f-s-45px {
  font-size: 45px;
}

.tablet-f-s-50px {
  font-size: 50px;
}

/*
█▀▀ █▀█ █▄░█ ▀█▀ ▄▄ █▀ █ ▀█ █▀▀ ▄▄ █▀▀ █▀▄▀█
█▀░ █▄█ █░▀█ ░█░ ░░ ▄█ █ █▄ ██▄ ░░ ██▄ █░▀░█
  */

.tablet-f-s-0-5em {
  font-size: 0.5em;
}

.tablet-f-s-0-6em {
  font-size: 0.6em;
}

.tablet-f-s-0-7em {
  font-size: 0.7em;
}

.tablet-f-s-0-8em {
  font-size: 0.8em;
}

.tablet-f-s-0-9em {
  font-size: 0.9em;
}

.tablet-f-s-1em {
  font-size: 1em;
}

.tablet-f-s-1-1em {
  font-size: 1.1em;
}

.tablet-f-s-1-2em {
  font-size: 1.2em;
}

.tablet-f-s-1-3em {
  font-size: 1.3em;
}

.tablet-f-s-1-3em {
  font-size: 1.4em;
}

.tablet-f-s-1-5em {
  font-size: 1.5em;
}

.tablet-f-s-1-6em {
  font-size: 1.6em;
}

.tablet-f-s-1-7em {
  font-size: 1.7em;
}

.tablet-f-s-1-8em {
  font-size: 1.8em;
}

.tablet-f-s-1-9em {
  font-size: 1.9em;
}

.tablet-f-s-2em {
  font-size: 2em;
}

.tablet-f-s-2-5em {
  font-size: 2.5em;
}

.tablet-f-s-3em {
  font-size: 3em;
}

.tablet-f-s-3-5em {
  font-size: 3.5em;
}

.tablet-f-s-4em {
  font-size: 4em;
}

.tablet-f-s-4-5em {
  font-size: 4.5em;
}

.tablet-f-s-5em {
  font-size: 5em;
}

.tablet-f-s-5-5em {
  font-size: 5.5em;
}

.tablet-f-s-6em {
  font-size: 6em;
}

/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ████▄ ▄██ ▀██ ██ ▄▄▄████ ██ ██ ▄▄▄█▄ ▄██ ▄▄ ██ ██ █▄▄ ▄▄██
██ █████ ███ █ █ ██ ▄▄▄████ ▄▄ ██ ▄▄▄██ ███ █▀▀██ ▄▄ ███ ████
██ ▀▀ █▀ ▀██ ██▄ ██ ▀▀▀████ ██ ██ ▀▀▀█▀ ▀██ ▀▀▄██ ██ ███ ████
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀

*/
/*

█░░ █ █▄░█ █▀▀ ▄▄ █░█ █▀▀ █ █▀▀ █░█ ▀█▀ ▄▄ █▀ █▀█ █▀▀ █▀▀ █ ▄▀█ █░░ █▀
█▄▄ █ █░▀█ ██▄ ░░ █▀█ ██▄ █ █▄█ █▀█ ░█░ ░░ ▄█ █▀▀ ██▄ █▄▄ █ █▀█ █▄▄ ▄█
*/
.tablet-l-h-inh {
  line-height: inherit;
}

/*
█░░ █ █▄░█ █▀▀ ▄▄ █░█ █▀▀ █ █▀▀ █░█ ▀█▀ ▄▄ █▀█ ▀▄▀
█▄▄ █ █░▀█ ██▄ ░░ █▀█ ██▄ █ █▄█ █▀█ ░█░ ░░ █▀▀ █░█
*/
.tablet-l-h-0px {
  line-height: 0px;
}

.tablet-l-h-1px {
  line-height: 1px;
}

.tablet-l-h-2px {
  line-height: 2px;
}

.tablet-l-h-3px {
  line-height: 3px;
}

.tablet-l-h-4px {
  line-height: 4px;
}

.tablet-l-h-5px {
  line-height: 5px;
}

.tablet-l-h-10px {
  line-height: 10px;
}

.tablet-l-h-15px {
  line-height: 15px;
}

.tablet-l-h-20px {
  line-height: 20px;
}

.tablet-l-h-25px {
  line-height: 25px;
}

.tablet-l-h-30px {
  line-height: 30px;
}

.tablet-l-h-35px {
  line-height: 35px;
}

.tablet-l-h-40px {
  line-height: 40px;
}

.tablet-l-h-45px {
  line-height: 45px;
}

.tablet-l-h-50px {
  line-height: 50px;
}

.tablet-l-h-55px {
  line-height: 55px;
}

.tablet-l-h-60px {
  line-height: 60px;
}

.tablet-l-h-65px {
  line-height: 65px;
}

.tablet-l-h-70px {
  line-height: 70px;
}

.tablet-l-h-75px {
  line-height: 75px;
}

.tablet-l-h-80px {
  line-height: 80px;
}

.tablet-l-h-85px {
  line-height: 85px;
}

.tablet-l-h-90px {
  line-height: 90px;
}

.tablet-l-h-95px {
  line-height: 95px;
}

.tablet-l-h-100px {
  line-height: 100px;
}

/*
  
█░░ █ █▄░█ █▀▀ ▄▄ █░█ █▀▀ █ █▀▀ █░█ ▀█▀ ▄▄ █▀▀ █▀▄▀█
█▄▄ █ █░▀█ ██▄ ░░ █▀█ ██▄ █ █▄█ █▀█ ░█░ ░░ ██▄ █░▀░█
*/

.tablet-l-h-0-1em {
  line-height: 0.1em;
}

.tablet-l-h-0-2em {
  line-height: 0.2em;
}

.tablet-l-h-0-3em {
  line-height: 0.3em;
}

.tablet-l-h-0-4em {
  line-height: 0.4em;
}

.tablet-l-h-0-5em {
  line-height: 0.5em;
}

.tablet-l-h-0-6em {
  line-height: 0.6em;
}

.tablet-l-h-0-7em {
  line-height: 0.7em;
}

.tablet-l-h-0-8em {
  line-height: 0.8em;
}

.tablet-l-h-0-9em {
  line-height: 0.9em;
}

.tablet-l-h-1em {
  line-height: 1em;
}

.tablet-l-h-2em {
  line-height: 2em;
}

.tablet-l-h-3em {
  line-height: 3em;
}

.tablet-l-h-4em {
  line-height: 4em;
}

.tablet-l-h-5em {
  line-height: 5em;
}

.tablet-l-h-6em {
  line-height: 6em;
}

.tablet-l-h-7em {
  line-height: 7em;
}

.tablet-l-h-8em {
  line-height: 8em;
}

.tablet-l-h-9em {
  line-height: 9em;
}

.tablet-l-h-10em {
  line-height: 10em;
}

/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ▄▄▀██ ██ ██ ▄▄▀██ ▄▄▄ ██ ▄▄▄ ██ ▄▄▀██
██ █████ ██ ██ ▀▀▄██▄▄▄▀▀██ ███ ██ ▀▀▄██
██ ▀▀▄██▄▀▀▄██ ██ ██ ▀▀▀ ██ ▀▀▀ ██ ██ ██
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
*/

.tablet-c-a {
  cursor: auto;
}

.tablet-c-p {
  cursor: pointer;
}

.tablet-c-w {
  cursor: wait;
}

.tablet-c-ch {
  cursor: crosshair;
}

.tablet-c-g {
  cursor: -webkit-grab;
  cursor: grab;
}

.tablet-c-gb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.tablet-c-h {
  cursor: help;
}

.tablet-c-z-i {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.tablet-c-z-o {
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ▄▄▀██ ▄▄▄ ██ ▄▄▀██ ▄▄▀██ ▄▄▄██ ▄▄▀██
██ ▄▄▀██ ███ ██ ▀▀▄██ ██ ██ ▄▄▄██ ▀▀▄██
██ ▀▀ ██ ▀▀▀ ██ ██ ██ ▀▀ ██ ▀▀▀██ ██ ██
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
  */
/*  
█▄▄ █▀█ █▀█ █▀▄ █▀▀ █▀█ ▄▄ █▀ █▀█ █▀▀ █▀▀ █ ▄▀█ █░░ █▀
█▄█ █▄█ █▀▄ █▄▀ ██▄ █▀▄ ░░ ▄█ █▀▀ ██▄ █▄▄ █ █▀█ █▄▄ ▄█
  */
/* general */
.tablet-b-none,
.tablet-b-n {
  border: none;
  border-color: initial;


}

.tablet-b-t-none,
.tablet-b-t-n {
  border-top: none;

}

.tablet-b-b-none,
.tablet-b-b-n {
  border-bottom: none;
  border-color: inherit;
}

.tablet-b-r-none,
.tablet-b-r-n {
  border-right: none;

}

.tablet-b-r-none,
.tablet-b-r-n {
  border-left: none;

}

/* radius */
.tablet-br-none,
.tablet-br-n {
  border-radius: 0px !important;

}

.tablet-br-b-l-none,
.tablet-br-b-l-n {
  border-bottom-left-radius: 0px !important;

}

.tablet-br-t-r-none,
.tablet-br-t-r-n {
  border-top-right-radius: 0px !important;

}

.tablet-br-b-r-none,
.tablet-br-b-r-n {
  border-bottom-right-radius: 0px !important;

}

.tablet-br-t-l-none,
.tablet-br-t-l-n {
  border-top-left-radius: 0px !important;

}

.tablet-b-c-none,
.tablet-b-c-n {
  border-color: none;
}

.tablet-b-t-c-none,
.tablet-b-t-c-n {
  border-top-color: none;
}

.tablet-b-r-c-none,
.tablet-b-r-c-n {
  border-right-color: none;
}

.tablet-b-b-c-none,
.tablet-b-b-c-n {
  border-bottom-color: none;
}

.tablet-b-r-c-none,
.tablet-b-r-c-n {
  border-left-color: none;
}

/*

▒█▀▄░█▒█▒█▀▄▒██▀░▒░░██▄░▒░░▄▀▀░▄▀▄░█▒░░▄▀▄▒█▀▄░░
░█▀▒░▀▄█░█▀▄░█▄▄░▀▀▒█▄█░▀▀░▀▄▄░▀▄▀▒█▄▄░▀▄▀░█▀▄▒░

*/

.tablet-b-c-red {
  border-color: red;
}

.tablet-b-c-blue {
  border-color: blue;
}

.tablet-b-c-skyblue {
  border-color: skyblue;
}

.tablet-b-c-green {
  border-color: green;
}

.tablet-b-c-yellow {
  border-color: yellow;
}

.tablet-b-c-orange {
  border-color: orange;
}

.tablet-b-c-purple {
  border-color: purple;
}

.tablet-b-c-fuchsia {
  border-color: fuchsia;
}

.tablet-b-c-pink {
  border-color: pink;
}

.tablet-b-c-gray {
  border-color: gray;
}

.tablet-b-c-black {
  color: black;
}

.tablet-white {
  color: white;
}


/*
█▄▄ █▀█ █▀█ █▀▄ █▀▀ █▀█ ▄▄ █░█ ▄▀█ █░░ █▀▀
█▄█ █▄█ █▀▄ █▄▀ ██▄ █▀▄ ░░ █▀█ █▀█ █▄▄ █▀░
  */
.tablet-b-left {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tablet-b-right {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.tablet-b-top {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.tablet-b-bottom {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/*
█▄▄ █▀█ █▀█ █▀▄ █▀▀ █▀█ ▄▄ █▀ ▀█▀ █▄█ █░░ █▀▀
█▄█ █▄█ █▀▄ █▄▀ ██▄ █▀▄ ░░ ▄█ ░█░ ░█░ █▄▄ ██▄
*/
.tablet-b-s-solid {
  border-style: solid;
}

.tablet-b-t-s-solid {
  border-top-style: solid;
}

.tablet-br-s-solid {
  border-right-style: solid;
}

.tablet-b-b-s-solid {
  border-bottom-style: solid;
}

.tablet-b-r-s-solid {
  border-left-style: solid;
}

.tablet-b-s-dashed {
  border-style: dashed;
}

.tablet-b-t-s-dashed {
  border-top-style: dashed;
}

.tablet-b-r-s-dashed {
  border-right-style: dashed;
}

.tablet-b-b-s-dashed {
  border-bottom-style: dashed;
}

.tablet-b-r-s-dashed {
  border-left-style: dashed;
}

.tablet-b-s-dotted {
  border-style: dotted;
}

.tablet-b-t-s-dotted {
  border-top-style: dotted;
}

.tablet-b-r-s-dotted {
  border-right-style: dotted;
}

.tablet-b-b-s-dotted {
  border-bottom-style: dotted;
}

.tablet-b-r-s-dotted {
  border-left-style: dotted;
}

.tablet-b-s-double {
  border-style: double;
}

.tablet-b-t-s-double {
  border-top-style: double;
}

.tablet-b-r-s-double {
  border-right-style: double;
}

.tablet-b-b-s-double {
  border-bottom-style: double;
}

.tablet-b-r-s-double {
  border-left-style: double;
}

.tablet-b-s-groove {
  border-style: groove;
}

.tablet-b-t-s-groove {
  border-top-style: groove;
}

.tablet-b-r-s-groove {
  border-right-style: groove;
}

.tablet-b-b-s-groove {
  border-bottom-style: groove;
}

.tablet-b-r-s-groove {
  border-left-style: groove;
}

.tablet-b-s-inset {
  border-style: inset;
  border: inset;

}

.tablet-b-t-s-inset {
  border-top-style: inset;

}

.tablet-b-r-s-inset {
  border-right-style: inset;

}

.tablet-b-b-s-inset {
  border-bottom-style: inset;

}

.tablet-b-r-s-inset {
  border-left-style: inset;

}


.tablet-b-s-outset {
  border-style: outset;
}

.tablet-b-t-s-outset {
  border-top-style: outset;
}

.tablet-b-r-s-outset {
  border-right-style: outset;
}

.tablet-b-b-s-outset {
  border-bottom-style: outset;
}

.tablet-b-r-s-outset {
  border-left-style: outset;
}

.tablet-b-s-none {
  border-style: none;
}

.tablet-b-t-s-none {
  border-top-style: none;
}

.tablet-b-r-s-none {
  border-right-style: none;
}

.tablet-b-b-s-none {
  border-bottom-style: none;
}

.tablet-b-r-s-none {
  border-left-style: none;
}

.tablet-b-s-hidden {
  border-style: hidden;
}

.tablet-b-t-s-hidden {
  border-top-style: hidden;
}

.tablet-b-r-s-hidden {
  border-right-style: hidden;
}

.tablet-b-b-s-hidden {
  border-bottom-style: hidden;
}

.tablet-b-r-s-hidden {
  border-left-style: hidden;
}

.tablet-b-s-mix {
  border-style: dotted dashed solid double;
}

.tablet-b-t-s-mix {
  border-top-style: dotted dashed solid double;
}

.tablet-b-r-s-mix {
  border-right-style: dotted dashed solid double;
}

.tablet-b-b-s-mix {
  border-bottom-style: dotted dashed solid double;
}

.tablet-b-r-s-mix {
  border-left-style: dotted dashed solid double;
}

/*  
█▄▄ █▀█ █▀█ █▀▄ █▀▀ █▀█ ▄▄ █░█░█ █ █▀▄ ▀█▀ █░█
█▄█ █▄█ █▀▄ █▄▀ ██▄ █▀▄ ░░ ▀▄▀▄▀ █ █▄▀ ░█░ █▀█
  */
.tablet-b-w-0px {
  border-width: 0px;
}

.tablet-b-w-1px {
  border-width: 1px;
}

.tablet-b-w-2px {
  border-width: 2px;
}

.tablet-b-w-3px {
  border-width: 3px;
}

.tablet-b-w-4px {
  border-width: 4px;
}

.tablet-b-w-5px {
  border-width: 5px;
}

.tablet-b-w-10px {
  border-width: 10px;
}

.tablet-b-w-15px {
  border-width: 15px;
}

.tablet-b-w-20px {
  border-width: 20px;
}

.tablet-b-w-25px {
  border-width: 25px;
}

.tablet-b-w-30px {
  border-width: 30px;
}

.tablet-b-w-35px {
  border-width: 35px;
}

.tablet-b-w-40px {
  border-width: 40px;
}

.tablet-b-w-45px {
  border-width: 45px;
}

.tablet-b-w-50px {
  border-width: 50px;
}

/*
█▄▄ █▀█ █▀█ █▀▄ █▀▀ █▀█ ▄▄ ▀█▀ █▀█ █▀█ ▄▄ █░█░█ █ █▀▄ ▀█▀ █░█
█▄█ █▄█ █▀▄ █▄▀ ██▄ █▀▄ ░░ ░█░ █▄█ █▀▀ ░░ ▀▄▀▄▀ █ █▄▀ ░█░ █▀█
*/


.tablet-b-t-w-0px {
  border-top-width: 0px;
}

.tablet-b-t-w-1px {
  border-top-width: 1px;
}

.tablet-b-t-w-2px {
  border-top-width: 2px;
}

.tablet-b-t-w-3px {
  border-top-width: 3px;
}

.tablet-b-t-w-4px {
  border-top-width: 4px;
}

.tablet-b-t-w-5px {
  border-top-width: 5px;
}

.tablet-b-t-w-10px {
  border-top-width: 10px;
}

.tablet-b-t-w-15px {
  border-top-width: 15px;
}

.tablet-b-t-w-20px {
  border-top-width: 20px;
}

.tablet-b-t-w-25px {
  border-top-width: 25px;
}

.tablet-b-t-w-30px {
  border-top-width: 30px;
}

.tablet-b-t-w-35px {
  border-top-width: 35px;
}

.tablet-b-t-w-40px {
  border-top-width: 40px;
}

.tablet-b-t-w-45px {
  border-top-width: 45px;
}

.tablet-b-t-w-50px {
  border-top-width: 50px;
}

/*
█▄▄ █▀█ █▀█ █▀▄ █▀▀ █▀█ ▄▄ █▀█ █ █▀▀ █░█ ▀█▀ ▄▄ █░█░█ █ █▀▄ ▀█▀ █░█
█▄█ █▄█ █▀▄ █▄▀ ██▄ █▀▄ ░░ █▀▄ █ █▄█ █▀█ ░█░ ░░ ▀▄▀▄▀ █ █▄▀ ░█░ █▀█
*/


.tablet-br-w-0px {
  border-right-width: 0px;
}

.tablet-br-w-1px {
  border-right-width: 1px;
}

.tablet-br-w-2px {
  border-right-width: 2px;
}

.tablet-br-w-3px {
  border-right-width: 3px;
}

.tablet-br-w-4px {
  border-right-width: 4px;
}

.tablet-br-w-5px {
  border-right-width: 5px;
}

.tablet-br-w-10px {
  border-right-width: 10px;
}

.tablet-br-w-15px {
  border-right-width: 15px;
}

.tablet-br-w-20px {
  border-right-width: 20px;
}

.tablet-br-w-25px {
  border-right-width: 25px;
}

.tablet-br-w-30px {
  border-right-width: 30px;
}

.tablet-br-w-35px {
  border-right-width: 35px;
}

.tablet-br-w-40px {
  border-right-width: 40px;
}

.tablet-br-w-45px {
  border-right-width: 45px;
}

.tablet-br-w-50px {
  border-right-width: 50px;
}

/*
█▄▄ █▀█ █▀█ █▀▄ █▀▀ █▀█ ▄▄ █▄▄ █▀█ ▀█▀ ▀█▀ █▀█ █▀▄▀█ ▄▄ █░█░█ █ █▀▄ ▀█▀ █░█
█▄█ █▄█ █▀▄ █▄▀ ██▄ █▀▄ ░░ █▄█ █▄█ ░█░ ░█░ █▄█ █░▀░█ ░░ ▀▄▀▄▀ █ █▄▀ ░█░ █▀█
*/


.tablet-b-b-w-0px {
  border-bottom-width: 0px;
}

.tablet-b-b-w-1px {
  border-bottom-width: 1px;
}

.tablet-b-b-w-2px {
  border-bottom-width: 2px;
}

.tablet-b-b-w-3px {
  border-bottom-width: 3px;
}

.tablet-b-b-w-4px {
  border-bottom-width: 4px;
}

.tablet-b-b-w-5px {
  border-bottom-width: 5px;
}

.tablet-b-b-w-10px {
  border-bottom-width: 10px;
}

.tablet-b-b-w-15px {
  border-bottom-width: 15px;
}

.tablet-b-b-w-20px {
  border-bottom-width: 20px;
}

.tablet-b-b-w-25px {
  border-bottom-width: 25px;
}

.tablet-b-b-w-30px {
  border-bottom-width: 30px;
}

.tablet-b-b-w-35px {
  border-bottom-width: 35px;
}

.tablet-b-b-w-40px {
  border-bottom-width: 40px;
}

.tablet-b-b-w-45px {
  border-bottom-width: 45px;
}

.tablet-b-b-w-50px {
  border-bottom-width: 50px;
}

/*
█▄▄ █▀█ █▀█ █▀▄ █▀▀ █▀█ ▄▄ █░░ █▀▀ █▀▀ ▀█▀ ▄▄ █░█░█ █ █▀▄ ▀█▀ █░█
█▄█ █▄█ █▀▄ █▄▀ ██▄ █▀▄ ░░ █▄▄ ██▄ █▀░ ░█░ ░░ ▀▄▀▄▀ █ █▄▀ ░█░ █▀█
*/

.tablet-b-r-w-0px {
  border-left-width: 0px;
}

.tablet-b-r-w-1px {
  border-left-width: 1px;
}

.tablet-b-r-w-2px {
  border-left-width: 2px;
}

.tablet-b-r-w-3px {
  border-left-width: 3px;
}

.tablet-b-r-w-4px {
  border-left-width: 4px;
}

.tablet-b-r-w-5px {
  border-left-width: 5px;
}

.tablet-b-r-w-10px {
  border-left-width: 10px;
}

.tablet-b-r-w-15px {
  border-left-width: 15px;
}

.tablet-b-r-w-20px {
  border-left-width: 20px;
}

.tablet-b-r-w-25px {
  border-left-width: 25px;
}

.tablet-b-r-w-30px {
  border-left-width: 30px;
}

.tablet-b-r-w-35px {
  border-left-width: 35px;
}

.tablet-b-r-w-40px {
  border-left-width: 40px;
}

.tablet-b-r-w-45px {
  border-left-width: 45px;
}

.tablet-b-r-w-50px {
  border-left-width: 50px;
}

/*
█▄▄ █▀█ █▀█ █▀▄ █▀▀ █▀█ ▄▄ █▀█ ▄▀█ █▀▄ █ █░█ █▀
█▄█ █▄█ █▀▄ █▄▀ ██▄ █▀▄ ░░ █▀▄ █▀█ █▄▀ █ █▄█ ▄█
*/
.tablet-br-0px {
  border-radius: 0px;
}

.tablet-br-1px {
  border-radius: 1px;
}

.tablet-br-2px {
  border-radius: 2px;
}

.tablet-br-3px {
  border-radius: 3px;
}

.tablet-br-4px {
  border-radius: 4px;
}

.tablet-br-5px {
  border-radius: 5px;
}

.tablet-br-10px {
  border-radius: 10px;
}

.tablet-br-15px {
  border-radius: 15px;
}

.tablet-br-20px {
  border-radius: 20px;
}

.tablet-br-25px {
  border-radius: 25px;
}

.tablet-br-30px {
  border-radius: 30px;
}

.tablet-br-35px {
  border-radius: 35px;
}

.tablet-br-40px {
  border-radius: 40px;
}

.tablet-br-45px {
  border-radius: 45px;
}

.tablet-br-50px {
  border-radius: 50px;
}

/*
█▄▄ █▀█ ▄▄ ▀█▀ █▀█ █▀█ ▄▄ █░░ █▀▀ █▀▀ ▀█▀
█▄█ █▀▄ ░░ ░█░ █▄█ █▀▀ ░░ █▄▄ ██▄ █▀░ ░█░
*/

.tablet-br-t-r-0px {
  border-top-left-radius: 0px;
}

.tablet-br-t-r-1px {
  border-top-left-radius: 1px;
}

.tablet-br-t-r-2px {
  border-top-left-radius: 2px;
}

.tablet-br-t-r-3px {
  border-top-left-radius: 3px;
}

.tablet-br-t-r-4px {
  border-top-left-radius: 4px;
}

.tablet-br-t-r-5px {
  border-top-left-radius: 5px;
}

.tablet-br-t-r-10px {
  border-top-left-radius: 10px;
}

.tablet-br-t-r-15px {
  border-top-left-radius: 15px;
}

.tablet-br-t-r-20px {
  border-top-left-radius: 20px;
}

.tablet-br-t-r-25px {
  border-top-left-radius: 25px;
}

.tablet-br-t-r-30px {
  border-top-left-radius: 30px;
}

.tablet-br-t-r-35px {
  border-top-left-radius: 35px;
}

.tablet-br-t-r-40px {
  border-top-left-radius: 40px;
}

.tablet-br-t-r-45px {
  border-top-left-radius: 45px;
}

.tablet-br-t-r-50px {
  border-top-left-radius: 50px;
}

/*
█▄▄ █▀█ ▄▄ ▀█▀ █▀█ █▀█ ▄▄ █▀█ █ █▀▀ █░█ ▀█▀
█▄█ █▀▄ ░░ ░█░ █▄█ █▀▀ ░░ █▀▄ █ █▄█ █▀█ ░█░
*/


.tablet-br-t-r-0px {
  border-top-right-radius: 0px;
}

.tablet-br-t-r-1px {
  border-top-right-radius: 1px;
}

.tablet-br-t-r-2px {
  border-top-right-radius: 2px;
}

.tablet-br-t-r-3px {
  border-top-right-radius: 3px;
}

.tablet-br-t-r-4px {
  border-top-right-radius: 4px;
}

.tablet-br-t-r-5px {
  border-top-right-radius: 5px;
}

.tablet-br-t-r-10px {
  border-top-right-radius: 10px;
}

.tablet-br-t-r-15px {
  border-top-right-radius: 15px;
}

.tablet-br-t-r-20px {
  border-top-right-radius: 20px;
}

.tablet-br-t-r-25px {
  border-top-right-radius: 25px;
}

.tablet-br-t-r-30px {
  border-top-right-radius: 30px;
}

.tablet-br-t-r-35px {
  border-top-right-radius: 35px;
}

.tablet-br-t-r-40px {
  border-top-right-radius: 40px;
}

.tablet-br-t-r-45px {
  border-top-right-radius: 45px;
}

.tablet-br-t-r-50px {
  border-top-right-radius: 50px;
}

/*
  
█▄▄ █▀█ ▄▄ █▄▄ █▀█ ▀█▀ ▀█▀ █▀█ █▀▄▀█ ▄▄ █░░ █▀▀ █▀▀ ▀█▀
█▄█ █▀▄ ░░ █▄█ █▄█ ░█░ ░█░ █▄█ █░▀░█ ░░ █▄▄ ██▄ █▀░ ░█░
  */
.tablet-br-b-r-0px {
  border-bottom-left-radius: 0px;
}

.tablet-br-b-r-1px {
  border-bottom-left-radius: 1px;
}

.tablet-br-b-r-2px {
  border-bottom-left-radius: 2px;
}

.tablet-br-b-r-3px {
  border-bottom-left-radius: 3px;
}

.tablet-br-b-r-4px {
  border-bottom-left-radius: 4px;
}

.tablet-br-b-r-5px {
  border-bottom-left-radius: 5px;
}

.tablet-br-b-r-10px {
  border-bottom-left-radius: 10px;
}

.tablet-br-b-r-15px {
  border-bottom-left-radius: 15px;
}

.tablet-br-b-r-20px {
  border-bottom-left-radius: 20px;
}

.tablet-br-b-r-25px {
  border-bottom-left-radius: 25px;
}

.tablet-br-b-r-30px {
  border-bottom-left-radius: 30px;
}

.tablet-br-b-r-35px {
  border-bottom-left-radius: 35px;
}

.tablet-br-b-r-40px {
  border-bottom-left-radius: 40px;
}

.tablet-br-b-r-45px {
  border-bottom-left-radius: 45px;
}

.tablet-br-b-r-50px {
  border-bottom-left-radius: 50px;
}

/*
█▄▄ █▀█ ▄▄ █▄▄ █▀█ ▀█▀ ▀█▀ █▀█ █▀▄▀█ ▄▄ █▀█ █ █▀▀ █░█ ▀█▀
█▄█ █▀▄ ░░ █▄█ █▄█ ░█░ ░█░ █▄█ █░▀░█ ░░ █▀▄ █ █▄█ █▀█ ░█░
  */

.tablet-br-b-r-0px {
  border-bottom-right-radius: 0px;
}

.tablet-br-b-r-1px {
  border-bottom-right-radius: 1px;
}

.tablet-br-b-r-2px {
  border-bottom-right-radius: 2px;
}

.tablet-br-b-r-3px {
  border-bottom-right-radius: 3px;
}

.tablet-br-b-r-4px {
  border-bottom-right-radius: 4px;
}

.tablet-br-b-r-5px {
  border-bottom-right-radius: 5px;
}

.tablet-br-b-r-10px {
  border-bottom-right-radius: 10px;
}

.tablet-br-b-r-15px {
  border-bottom-right-radius: 15px;
}

.tablet-br-b-r-20px {
  border-bottom-right-radius: 20px;
}

.tablet-br-b-r-25px {
  border-bottom-right-radius: 25px;
}

.tablet-br-b-r-30px {
  border-bottom-right-radius: 30px;
}

.tablet-br-b-r-35px {
  border-bottom-right-radius: 35px;
}

.tablet-br-b-r-40px {
  border-bottom-right-radius: 40px;
}

.tablet-br-b-r-45px {
  border-bottom-right-radius: 45px;
}

.tablet-br-b-r-50px {
  border-bottom-right-radius: 50px;
}

/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ▄▄▀██ ▄▄▄ █▄▀█▀▄██
██ ▄▄▀██ ███ ███ ████
██ ▀▀ ██ ▀▀▀ █▀▄█▄▀██
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀

*/

/*
█▄▄ █▀█ ▀▄▀ ▄▄ █▀ █ ▀█ █ █▄░█ █▀▀
█▄█ █▄█ █░█ ░░ ▄█ █ █▄ █ █░▀█ █▄█
*/

.tablet-b-s-b-b {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.tablet-b-s-c-b {
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

/*  
█▄▄ █▀█ ▀▄▀ ▄▄ █▀ █░█ ▄▀█ █▀▄ █▀█ █░█░█
█▄█ █▄█ █░█ ░░ ▄█ █▀█ █▀█ █▄▀ █▄█ ▀▄▀▄▀
  */
.tablet-b-s-i {
  -webkit-box-shadow: inset;
  box-shadow: inset;
}

.tablet-b-s-n {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.tablet-b-s-1 {
  -webkit-box-shadow: -11px 11px 2px -4px rgba(48, 48, 48, 0.18);
  box-shadow: -11px 11px 2px -4px rgba(48, 48, 48, 0.tablet-18);
}

.tablet-b-s-1-h:hover {
  -webkit-box-shadow: -11px 11px 2px -4px rgba(48, 48, 48, 0.18);
  box-shadow: -11px 11px 2px -4px rgba(48, 48, 48, 0.18);
}

.tablet-b-s-2 {
  -webkit-box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.33);
  box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.33);
}

.tablet-b-s-2-h:hover {
  -webkit-box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.33);
  box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.33);
}



/*
▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
██ ▄▄ █ ▄▄▀██ ▄▄▀██ ▄▄▀█▄ ▄██ ▀██ ██ ▄▄ ██
██ ▀▀ █ ▀▀ ██ ██ ██ ██ ██ ███ █ █ ██ █▀▀██
██ ████ ██ ██ ▀▀ ██ ▀▀ █▀ ▀██ ██▄ ██ ▀▀▄██
▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
*/


/*

█▀█ ▄▀█ █▀▄ █▀▄ █ █▄░█ █▀▀ ▄▄ █▀ █▀█ █▀▀ █▀▀ █ ▄▀█ █░░ █▀
█▀▀ █▀█ █▄▀ █▄▀ █ █░▀█ █▄█ ░░ ▄█ █▀▀ ██▄ █▄▄ █ █▀█ █▄▄ ▄█
*/

.tablet-p-a,
.tablet-p-auto {
  margin: auto;
}

.tablet-p-inh,
.tablet-p-inherit {
  margin: inherit;
}


/*
 █▀▄ ▄▀▄ █▀▄ █▀▄ █ █▄ █ ▄▀       █▀▄ ▀▄▀
 █▀  █▀█ █▄▀ █▄▀ █ █ ▀█ ▀▄█ ▀▀   █▀  █ █
*/

.tablet-p-0px {
  padding: 0px;
}

.tablet-p-1px {
  padding: 1px;
}

.tablet-p-2px {
  padding: 2px;
}

.tablet-p-3px {
  padding: 3px;
}

.tablet-p-4px {
  padding: 4px;
}

.tablet-p-5px {
  padding: 5px;
}

.tablet-p-6px {
  padding: 6px;
}

.tablet-p-7px {
  padding: 7px;
}

.tablet-p-8px {
  padding: 8px;
}

.tablet-p-9px {
  padding: 9px;
}

.tablet-p-10px {
  padding: 10px;
}

.tablet-p-20px {
  padding: 20px;
}

.tablet-p-30px {
  padding: 30px;
}

.tablet-p-40px {
  padding: 40px;
}

.tablet-p-50px {
  padding: 50px;
}

.tablet-p-60px {
  padding: 60px;
}

.tablet-p-70px {
  padding: 70px;
}

.tablet-p-80px {
  padding: 80px;
}

.tablet-p-90px {
  padding: 90px;
}

.tablet-p-100px {
  padding: 100px;
}

/*
█▀█ ▄▀█ █▀▄ █▀▄ █ █▄░█ █▀▀ ▄▄ ▀█▀ █▀█ █▀█
█▀▀ █▀█ █▄▀ █▄▀ █ █░▀█ █▄█ ░░ ░█░ █▄█ █▀▀
*/
.tablet-p-t-0px {
  padding-top: 0px;
}

.tablet-p-t-1px {
  padding-top: 1px;
}

.tablet-p-t-2px {
  padding-top: 2px;
}

.tablet-p-t-3px {
  padding-top: 3px;
}

.tablet-p-t-4px {
  padding-top: 4px;
}

.tablet-p-t-5px {
  padding-top: 5px;
}

.tablet-p-t-6px {
  padding-top: 6px;
}

.tablet-p-t-7px {
  padding-top: 7px;
}

.tablet-p-t-8px {
  padding-top: 8px;
}

.tablet-p-t-9px {
  padding-top: 9px;
}

.tablet-p-t-10px {
  padding-top: 10px;
}

.tablet-p-t-20px {
  padding-top: 20px;
}

.tablet-p-t-30px {
  padding-top: 30px;
}

.tablet-p-t-40px {
  padding-top: 40px;
}

.tablet-p-t-50px {
  padding-top: 50px;
}

.tablet-p-t-60px {
  padding-top: 60px;
}

.tablet-p-t-70px {
  padding-top: 70px;
}

.tablet-p-t-80px {
  padding-top: 80px;
}

.tablet-p-t-90px {
  padding-top: 90px;
}

.tablet-p-t-100px {
  padding-top: 100px;
}

/*

█▀█ ▄▀█ █▀▄ █▀▄ █ █▄░█ █▀▀ ▄▄ █▀█ █ █▀▀ █░█ ▀█▀
█▀▀ █▀█ █▄▀ █▄▀ █ █░▀█ █▄█ ░░ █▀▄ █ █▄█ █▀█ ░█░
*/

.tablet-p-r-0px {
  padding-right: 0px;
}

.tablet-p-r-1px {
  padding-right: 1px;
}

.tablet-p-r-2px {
  padding-right: 2px;
}

.tablet-p-r-3px {
  padding-right: 3px;
}

.tablet-p-r-4px {
  padding-right: 4px;
}

.tablet-p-r-5px {
  padding-right: 5px;
}

.tablet-p-r-6px {
  padding-right: 6px;
}

.tablet-p-r-7px {
  padding-right: 7px;
}

.tablet-p-r-8px {
  padding-right: 8px;
}

.tablet-p-r-9px {
  padding-right: 9px;
}

.tablet-p-r-10px {
  padding-right: 10px;
}

.tablet-p-r-20px {
  padding-right: 20px;
}

.tablet-p-r-30px {
  padding-right: 30px;
}

.tablet-p-r-40px {
  padding-right: 40px;
}

.tablet-p-r-50px {
  padding-right: 50px;
}

.tablet-p-r-60px {
  padding-right: 60px;
}

.tablet-p-r-70px {
  padding-right: 70px;
}

.tablet-p-r-80px {
  padding-right: 80px;
}

.tablet-p-r-90px {
  padding-right: 90px;
}

.tablet-p-r-100px {
  padding-right: 100px;
}

/*

█▀█ ▄▀█ █▀▄ █▀▄ █ █▄░█ █▀▀ ▄▄ █▄▄ █▀█ ▀█▀ ▀█▀ █▀█ █▀▄▀█
█▀▀ █▀█ █▄▀ █▄▀ █ █░▀█ █▄█ ░░ █▄█ █▄█ ░█░ ░█░ █▄█ █░▀░█
*/
.tablet-p-b-0px {
  padding-bottom: 0px;
}

.tablet-p-b-1px {
  padding-bottom: 1px;
}

.tablet-p-b-2px {
  padding-bottom: 2px;
}

.tablet-p-b-3px {
  padding-bottom: 3px;
}

.tablet-p-b-4px {
  padding-bottom: 4px;
}

.tablet-p-b-5px {
  padding-bottom: 5px;
}

.tablet-p-b-6px {
  padding-bottom: 6px;
}

.tablet-p-b-7px {
  padding-bottom: 7px;
}

.tablet-p-b-8px {
  padding-bottom: 8px;
}

.tablet-p-b-9px {
  padding-bottom: 9px;
}

.tablet-p-b-10px {
  padding-bottom: 10px;
}

.tablet-p-b-20px {
  padding-bottom: 20px;
}

.tablet-p-b-30px {
  padding-bottom: 30px;
}

.tablet-p-b-40px {
  padding-bottom: 40px;
}

.tablet-p-b-50px {
  padding-bottom: 50px;
}

.tablet-p-b-60px {
  padding-bottom: 60px;
}

.tablet-p-b-70px {
  padding-bottom: 70px;
}

.tablet-p-b-80px {
  padding-bottom: 80px;
}

.tablet-p-b-90px {
  padding-bottom: 90px;
}

.tablet-p-b-100px {
  padding-bottom: 100px;
}

/*
█▀█ ▄▀█ █▀▄ █▀▄ █ █▄░█ █▀▀ ▄▄ █░░ █▀▀ █▀▀ ▀█▀
█▀▀ █▀█ █▄▀ █▄▀ █ █░▀█ █▄█ ░░ █▄▄ ██▄ █▀░ ░█░
*/

.tablet-p-l-0px {
  padding-left: 0px;
}

.tablet-p-l-1px {
  padding-left: 1px;
}

.tablet-p-l-2px {
  padding-left: 2px;
}

.tablet-p-l-3px {
  padding-left: 3px;
}

.tablet-p-l-4px {
  padding-left: 4px;
}

.tablet-p-l-5px {
  padding-left: 5px;
}

.tablet-p-l-6px {
  padding-left: 6px;
}

.tablet-p-l-7px {
  padding-left: 7px;
}

.tablet-p-l-8px {
  padding-left: 8px;
}

.tablet-p-l-9px {
  padding-left: 9px;
}

.tablet-p-l-10px {
  padding-left: 10px;
}

.tablet-p-l-20px {
  padding-left: 20px;
}

.tablet-p-l-30px {
  padding-left: 30px;
}

.tablet-p-l-40px {
  padding-left: 40px;
}

.tablet-p-l-50px {
  padding-left: 50px;
}

.tablet-p-l-60px {
  padding-left: 60px;
}

.tablet-p-l-70px {
  padding-left: 70px;
}

.tablet-p-l-80px {
  padding-left: 80px;
}

.tablet-p-l-90px {
  padding-left: 90px;
}

.tablet-p-l-100px {
  padding-left: 100px;
}



/*
█▀█ ▄▀█ █▀▄ █▀▄ █ █▄░█ █▀▀ ▄▄ ▀░▄▀
█▀▀ █▀█ █▄▀ █▄▀ █ █░▀█ █▄█ ░░ ▄▀░▄
*/

.tablet-p-0 {
  padding: 0%;
}

.tablet-p-1 {
  padding: 1%;
}

.tablet-p-2 {
  padding: 2%;
}

.tablet-p-3 {
  padding: 3%;
}

.tablet-p-4 {
  padding: 4%;
}

.tablet-p-5 {
  padding: 5%;
}

.tablet-p-6 {
  padding: 6%;
}

.tablet-p-7 {
  padding: 7%;
}

.tablet-p-8 {
  padding: 8%;
}

.tablet-p-9 {
  padding: 9%;
}

.tablet-p-10 {
  padding: 10%;
}

.tablet-p-15 {
  padding: 15%;
}

.tablet-p-20 {
  padding: 20%;
}

.tablet-p-25 {
  padding: 25%;
}

.tablet-p-30 {
  padding: 30%;
}

.tablet-p-35 {
  padding: 35%;
}

.tablet-p-40 {
  padding: 40%;
}

.tablet-p-45 {
  padding: 45%;
}

.tablet-p-50 {
  padding: 50%;
}

.tablet-p-55 {
  padding: 55%;
}

.tablet-p-60 {
  padding: 60%;
}

.tablet-p-65 {
  padding: 65%;
}

.tablet-p-70 {
  padding: 70%;
}

.tablet-p-75 {
  padding: 75%;
}

.tablet-p-80 {
  padding: 80%;
}

.tablet-p-85 {
  padding: 85%;
}

.tablet-p-90 {
  padding: 90%;
}

.tablet-p-95 {
  padding: 95%;
}

.tablet-p-100 {
  padding: 100%;
}

/*
█▀█ ▄▀█ █▀▄ █▀▄ █ █▄░█ █▀▀ ▄▄ ▀░▄▀ ▄▄ █░░ █▀▀ █▀▀ ▀█▀
█▀▀ █▀█ █▄▀ █▄▀ █ █░▀█ █▄█ ░░ ▄▀░▄ ░░ █▄▄ ██▄ █▀░ ░█░
*/

.tablet-p-r-0 {
  padding-left: 0%;
}

.tablet-p-r-1 {
  padding-left: 1%;
}

.tablet-p-r-2 {
  padding-left: 2%;
}

.tablet-p-r-3 {
  padding-left: 3%;
}

.tablet-p-r-4 {
  padding-left: 4%;
}

.tablet-p-r-5 {
  padding-left: 5%;
}

.tablet-p-r-6 {
  padding-left: 6%;
}

.tablet-p-r-7 {
  padding-left: 7%;
}

.tablet-p-r-8 {
  padding-left: 8%;
}

.tablet-p-r-9 {
  padding-left: 9%;
}

.tablet-p-r-10 {
  padding-left: 10%;
}

.tablet-p-r-15 {
  padding-left: 15%;
}

.tablet-p-r-20 {
  padding-left: 20%;
}

.tablet-p-r-25 {
  padding-left: 25%;
}

.tablet-p-r-30 {
  padding-left: 30%;
}

.tablet-p-r-35 {
  padding-left: 35%;
}

.tablet-p-r-40 {
  padding-left: 40%;
}

.tablet-p-r-45 {
  padding-left: 45%;
}

.tablet-p-r-50 {
  padding-left: 50%;
}

.tablet-p-r-55 {
  padding-left: 55%;
}

.tablet-p-r-60 {
  padding-left: 60%;
}

.tablet-p-r-65 {
  padding-left: 65%;
}

.tablet-p-r-70 {
  padding-left: 70%;
}

.tablet-p-r-75 {
  padding-left: 75%;
}

.tablet-p-r-80 {
  padding-left: 80%;
}

.tablet-p-r-85 {
  padding-left: 85%;
}

.tablet-p-r-90 {
  padding-left: 90%;
}

.tablet-p-r-95 {
  padding-left: 95%;
}

.tablet-p-r-100 {
  padding-left: 100%;
}

/*
█▀█ ▄▀█ █▀▄ █▀▄ █ █▄░█ █▀▀ ▄▄ ▀░▄▀ ▄▄ █▀█ █ █▀▀ █░█ ▀█▀
█▀▀ █▀█ █▄▀ █▄▀ █ █░▀█ █▄█ ░░ ▄▀░▄ ░░ █▀▄ █ █▄█ █▀█ ░█░
*/
.tablet-p-r-0 {
  padding-right: 0%;
}

.tablet-p-r-1 {
  padding-right: 1%;
}

.tablet-p-r-2 {
  padding-right: 2%;
}

.tablet-p-r-3 {
  padding-right: 3%;
}

.tablet-p-r-4 {
  padding-right: 4%;
}

.tablet-p-r-5 {
  padding-right: 5%;
}

.tablet-p-r-6 {
  padding-right: 6%;
}

.tablet-p-r-7 {
  padding-right: 7%;
}

.tablet-p-r-8 {
  padding-right: 8%;
}

.tablet-p-r-9 {
  padding-right: 9%;
}

.tablet-p-r-10 {
  padding-right: 10%;
}

.tablet-p-r-15 {
  padding-right: 15%;
}

.tablet-p-r-20 {
  padding-right: 20%;
}

.tablet-p-r-25 {
  padding-right: 25%;
}

.tablet-p-r-30 {
  padding-right: 30%;
}

.tablet-p-r-35 {
  padding-right: 35%;
}

.tablet-p-r-40 {
  padding-right: 40%;
}

.tablet-p-r-45 {
  padding-right: 45%;
}

.tablet-p-r-50 {
  padding-right: 50%;
}

.tablet-p-r-55 {
  padding-right: 55%;
}

.tablet-p-r-60 {
  padding-right: 60%;
}

.tablet-p-r-65 {
  padding-right: 65%;
}

.tablet-p-r-70 {
  padding-right: 70%;
}

.tablet-p-r-75 {
  padding-right: 75%;
}

.tablet-p-r-80 {
  padding-right: 80%;
}

.tablet-p-r-85 {
  padding-right: 85%;
}

.tablet-p-r-90 {
  padding-right: 90%;
}

.tablet-p-r-95 {
  padding-right: 95%;
}

.tablet-p-r-100 {
  padding-right: 100%;
}

/*
█▀█ ▄▀█ █▀▄ █▀▄ █ █▄░█ █▀▀ ▄▄ ▀░▄▀ ▄▄ ▀█▀ █▀█ █▀█
█▀▀ █▀█ █▄▀ █▄▀ █ █░▀█ █▄█ ░░ ▄▀░▄ ░░ ░█░ █▄█ █▀▀
*/
.tablet-p-t-0 {
  padding-top: 0%;
}

.tablet-p-t-1 {
  padding-top: 1%;
}

.tablet-p-t-2 {
  padding-top: 2%;
}

.tablet-p-t-3 {
  padding-top: 3%;
}

.tablet-p-t-4 {
  padding-top: 4%;
}

.tablet-p-t-5 {
  padding-top: 5%;
}

.tablet-p-t-6 {
  padding-top: 6%;
}

.tablet-p-t-7 {
  padding-top: 7%;
}

.tablet-p-t-8 {
  padding-top: 8%;
}

.tablet-p-t-9 {
  padding-top: 9%;
}

.tablet-p-t-10 {
  padding-top: 10%;
}

.tablet-p-t-15 {
  padding-top: 15%;
}

.tablet-p-t-20 {
  padding-top: 20%;
}

.tablet-p-t-25 {
  padding-top: 25%;
}

.tablet-p-t-30 {
  padding-top: 30%;
}

.tablet-p-t-35 {
  padding-top: 35%;
}

.tablet-p-t-40 {
  padding-top: 40%;
}

.tablet-p-t-45 {
  padding-top: 45%;
}

.tablet-p-t-50 {
  padding-top: 50%;
}

.tablet-p-t-55 {
  padding-top: 55%;
}

.tablet-p-t-60 {
  padding-top: 60%;
}

.tablet-p-t-65 {
  padding-top: 65%;
}

.tablet-p-t-70 {
  padding-top: 70%;
}

.tablet-p-t-75 {
  padding-top: 75%;
}

.tablet-p-t-80 {
  padding-top: 80%;
}

.tablet-p-t-85 {
  padding-top: 85%;
}

.tablet-p-t-90 {
  padding-top: 90%;
}

.tablet-p-t-95 {
  padding-top: 95%;
}

.tablet-p-t-100 {
  padding-top: 100%;
}

/*
█▀█ ▄▀█ █▀▄ █▀▄ █ █▄░█ █▀▀ ▄▄ ▀░▄▀ ▄▄ █▄▄ █▀█ ▀█▀ ▀█▀ █▀█ █▀▄▀█
█▀▀ █▀█ █▄▀ █▄▀ █ █░▀█ █▄█ ░░ ▄▀░▄ ░░ █▄█ █▄█ ░█░ ░█░ █▄█ █░▀░█
*/
.tablet-p-b-0 {
  padding-bottom: 0%;
}

.tablet-p-b-1 {
  padding-bottom: 1%;
}

.tablet-p-b-2 {
  padding-bottom: 2%;
}

.tablet-p-b-3 {
  padding-bottom: 3%;
}

.tablet-p-b-4 {
  padding-bottom: 4%;
}

.tablet-p-b-5 {
  padding-bottom: 5%;
}

.tablet-p-b-6 {
  padding-bottom: 6%;
}

.tablet-p-b-7 {
  padding-bottom: 7%;
}

.tablet-p-b-8 {
  padding-bottom: 8%;
}

.tablet-p-b-9 {
  padding-bottom: 9%;
}

.tablet-p-b-10 {
  padding-bottom: 10%;
}

.tablet-p-b-15 {
  padding-bottom: 15%;
}

.tablet-p-b-20 {
  padding-bottom: 20%;
}

.tablet-p-b-25 {
  padding-bottom: 25%;
}

.tablet-p-b-30 {
  padding-bottom: 30%;
}

.tablet-p-b-35 {
  padding-bottom: 35%;
}

.tablet-p-b-40 {
  padding-bottom: 40%;
}

.tablet-p-b-45 {
  padding-bottom: 45%;
}

.tablet-p-b-50 {
  padding-bottom: 50%;
}

.tablet-p-b-55 {
  padding-bottom: 55%;
}

.tablet-p-b-60 {
  padding-bottom: 60%;
}

.tablet-p-b-65 {
  padding-bottom: 65%;
}

.tablet-p-b-70 {
  padding-bottom: 70%;
}

.tablet-p-b-75 {
  padding-bottom: 75%;
}

.tablet-p-b-80 {
  padding-bottom: 80%;
}

.tablet-p-b-85 {
  padding-bottom: 85%;
}

.tablet-p-b-90 {
  padding-bottom: 90%;
}

.tablet-p-b-95 {
  padding-bottom: 95%;
}

.tablet-p-b-100 {
  padding-bottom: 100%;
}

}