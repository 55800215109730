@charset "UTF-8";
@import url("./seed-habits.css");
@import url("./seed-components.css");
@import url("./seed-desktop.css");
@import url("./seed-tablet.css");
@import url("./seed-mobile.css");
/* Definición de fuentes web */
@font-face {
    font-family: "Poppins";
    src: url("../../assets/fonts/Poppins-Regular.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Poppins-Black";
    src: url("../../assets/fonts/Poppins-Black.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Poppins-BlackItalic";
    src: url("../../assets/fonts/Poppins-BlackItalic.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Poppins-Bold";
    src: url("../../assets/fonts/Poppins-Bold.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Poppins-BoldItalic";
    src: url("../../assets/fonts/Poppins-BoldItalic.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Poppins-ExtraBold";
    src: url("../../assets/fonts/Poppins-ExtraBold.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Poppins-ExtraBoldItalic";
    src: url("../../assets/fonts/Poppins-ExtraBoldItalic.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Poppins-ExtraLight";
    src: url("../../assets/fonts/Poppins-ExtraLight.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Poppins-ExtraLightItalic";
    src: url("../../assets/fonts/Poppins-ExtraLightItalic.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Poppins-Italic";
    src: url("../../assets/fonts/Poppins-Italic.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Poppins-Light";
    src: url("../../assets/fonts/Poppins-Light.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Poppins-LightItalic";
    src: url("../../assets/fonts/Poppins-LightItalic.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Poppins-Medium";
    src: url("../../assets/fonts/Poppins-Medium.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Poppins-MediumItalic";
    src: url("../../assets/fonts/Poppins-MediumItalic.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Poppins-Regular";
    src: url("../../assets/fonts/Poppins-Regular.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Poppins-SemiBold";
    src: url("../../assets/fonts/Poppins-SemiBold.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Poppins-SemiBoldItalic";
    src: url("../../assets/fonts/Poppins-SemiBoldItalic.ttf") format("truetype");
  }
  