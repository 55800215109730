
@charset "UTF-8";

/***
 *    ███    ██  ██████  ██████  ███████ ███████ ██    ██ ███████ ████████ ███████ ███    ███ ███████ ██    ██ ██    ██ 
 *    ████   ██ ██    ██ ██   ██ ██      ██       ██  ██  ██         ██    ██      ████  ████ ██      ██    ██  ██  ██  
 *    ██ ██  ██ ██    ██ ██   ██ █████   ███████   ████   ███████    ██    █████   ██ ████ ██ ███████ ██    ██   ████   
 *    ██  ██ ██ ██    ██ ██   ██ ██           ██    ██         ██    ██    ██      ██  ██  ██      ██ ██    ██    ██    
 *    ██   ████  ██████  ██████  ███████ ███████    ██    ███████    ██    ███████ ██      ██ ███████  ██████     ██    
 *                                                                                                                      
 *    SEED | Habits | Es un producto de NodeSystemsUy | Todos los derechos propiedad de Nickolás Machado - Uruguay | Está prohibida su cópia, clonación, comercialización, distribución o cualquier simil.mobile-mobile-                                                                                                
 */

.h-pr-fl-ma{
    position: relative;
    float: left;
    margin: auto;
}
.h-pr-fr-ma{
    position: relative;
    float: right;
    margin: auto;
}

.p-15px{
    padding: 15px;
}
